---
en:
  time: time
  next: Next
  now: now
  currently: currently
  previous: Previous
  unique_visitor: unique visitor
  active: Active
  agents: agents
  agent: agent
  ago: ago
  are_you_sure: Are you sure?
  delete: Delete
  language: Language
  locales:
    en: English
    it: Italian
    es: Spanish
    ja: Japanese
    pt-BR: Portuguese
  second:
    zero: 0 seconds
    one: 1 second
    other: "%{count} seconds"
  minute:
    zero: 0 minutes
    one: 1 minute
    other: "%{count} minutes"
  hour:
    zero: 0 hours
    one: 1 hour
    other: "%{count} hours"
  hours: hours
  in_the_last_few_minutes: In the last few minutes
  inactive: Inactive
  log_in: Log in
  log_out: Log out
  min: min
  day:
    zero: 0 days
    one: 1 day
    other: "%{count} days"
  month:
    zero: 0 months
    one: 1 month
    other: "%{count} months"
  year:
    zero: 0 years
    one: 1 year
    other: "%{count} years"
  mo: mo
  never: Never
  false: 'No'
  is: is
  are: are
  today: today
  not_found: Not found
  not_used: Not used
  optional: Optional
  per: per
  used: used
  true: 'on'
  or: or
  us: us
  and: and
  upgrade: upgrade
  there: there
  e_g: e.g.
  try_again: Try again
  click_here: Click here
  using: Using
  here: here
  load_more: Load more
  vat: VAT
  total: Total
  with: with
  visitor: Visitor
  that's approximately %{time_per_session} per screenshare: that's approximately %{time_per_session}
    per screenshare
  Your agents made a total of <b>%{call_time} of calls.</b>: Your agents made a total
    of <b>%{call_time} of calls.</b>
  footer:
    help_center: Help center
    contact_support: Contact support
    system_status: System status
  utils:
    few_seconds: a few seconds
    last_few_minutes: in the last few minutes
    next_few_minutes: in the next few minutes
  license:
    zero: 0 licenses
    one: 1 license
    other: "%{count} licenses"
  logs:
    admin_sign_up: Created team
    admin_status_change: Admin status changed
    api_co_browse_link_generate: 'API: Generated co-browse link for visitor %{visitor}'
    api_co_browse_stop: 'API: Stopped session for visitor %{visitor}'
    api_data_visitor_delete: 'API: Deleted visitor data for visitor %{visitor}'
    api_onpremise_token_generate: 'API: Generated token to authorize on-premise session
      for agent %{agent_id} with visitor %{visitor_id}'
    api_proxy_link_generate: 'API: Generated proxy start link'
    aut_leave_team: Left team
    auth_change_email: Changed email from %{old_email} to %{email}
    auth_change_email_request: Request to change email from %{old_email} to %{email}
    auth_create_team: Created team
    auth_integration_log_in: Authorized Upscope for %{client_type}
    auth_join_team: Joined team
    auth_log_in: Logged in by email
    auth_log_in_password: Logged in by password
    auth_log_out_remote: Signed out of %{device}
    auth_mfa_disabled: MFA disabled
    auth_mfa_enabled: MFA enabled
    auth_password_disabled: Password disabled
    auth_password_enabled: Password enabled
    auth_password_reset: Removed password after too many failed log in attempts
    auth_saml_join_team: Joined team through SAML SSO
    auth_saml_log_in: Logged in through SAML SSO
    auth_sign_up: Signed up
    auth_team_delete: The team was deleted
    billing_call_credit_added: Added %{amount} hours of included call credit
    billing_call_credit_purchase: Purchased %{hours} hours of call credit
    billing_cancel_subscription: The subscription was cancelled
    billing_card_added: Added payment card ending %{stripe_source_ending}
    billing_card_removed: Removed payment card
    billing_card_updated: Payment card updated
    billing_failed_payment: A charge failed
    billing_info_update: Team billing settings updated
    billing_invoice_paid: Paid overdue invoice
    billing_licenses_auto_update: Number of licenses automatically updated from %{previous_active_licenses}
      to %{active_licenses}
    billing_licenses_update: Updated licenses from %{original_active_licenses} to
      %{active_licenses}
    billing_link_from_other_team: Linked billing of team %{other_team_domain} (%{other_team_id})
    billing_link_from_other_team_remove: Removed billing link of team %{other_team_domain}
      (%{other_team_id})
    billing_link_to_other_team: Linked billing to team %{other_team_domain} (%{other_team_id})
    billing_link_to_other_team_remove: Removed billing link to team %{team_domain}
      (%{team_id})
    billing_new_invoice: Invoice %{id} was created
    billing_plan_change: The subscription was changed to a %{plan}
    billing_plan_changed: Your plan has changed to %{plan}
    billing_refund_charge: Charge %{id} was refunded
    billing_reset: The billing was reset
    billing_resume_subscription: The subscription was resumed
    billing_subscription_end: The subscription ended
    billing_successful_payment: Invoice %{id} was paid
    billing_trial_converted: The trial ended and the %{plan} started.
    billing_trial_started: Started a 14-day free trial
    billing_vat_added: The VAT number was set to %{vat_number}
    billing_vat_removed: The VAT information was deleted
    data_delete_visitor: Deleted visitor data for visitor %{visitor}
    data_deleted_all: Deleted all visitors data
    data_visitor_delete_all: 'API: Deleted all visitor data'
    info_update: Team billing settings updated
    members_change_permission: Updated the permissions of %{email} (%{id})
    members_change_permission_automatic: Gained manage_billing permission by adding
      a card
    members_invite_change_permission: Updated the permissions of invite to %{email}
    members_log_out_user: Logged out %{email} (%{id})
    members_mfa_disable: Disabled %{email} (%{id})'s Multi Factor Authentication
    members_new_invite: Sent an invite to %{email}
    members_new_invite_request: Sent an invite to %{email} (after an invite request)
    members_password_disable: Disabled %{email} (%{id})'s password
    members_remove_user: Removed %{email} (%{id}) from the team
    members_resend_invite: Resent an invite to %{email}
    members_revoke_invite: Revoked an invite sent to %{email}
    rest_key_created: Created new REST key %{id}
    rest_key_deleted: Deactivated REST key %{id}
    settings_domain_change: Domain was changed from %{old_domain} to %{team_domain}
    settings_integration_remove: Removed %{integration_name} integration
    settings_integration_setup: Set up integration %{integration_name}
    settings_integration_update_settings: Integration %{integration_name} settings
      updated
    settings_on_premise_team_update: Team on-premise settings updated
    settings_personal_update: Updated personal information
    settings_team_beta_off: Turned beta features off
    settings_team_beta_on: Turned beta features on
    settings_team_update: Team settings updated
    settings_team_update_phone_calls: Team phone calls settings updated
    team_session_sharing_not_available: Attempt to co-browse blocked due multi agent
      not being enabled
    too_many_monthly_agents: Attempt to co-browse blocked due to too many monthly
      agents
    too_many_seats_used: Attempt to co-browse blocked due to too many seats used
    too_many_sessions: Attempt to co-browse blocked due to too many active sessions
    transfer_ownership: Transferred ownership of the team to %{email} (%{id})
    usage_on_premise_co_browse_new: Started co-browsing with on-premise visitor %{visitor_id}
    usage_co_browsing_attempt: Blocked session due to usage limits (Error %{error_type})
    usage_co_browsing_join: Joined co-browsing session with visitor %{visitor}
    usage_co_browsing_new: Started co-browsing session with visitor %{visitor}
  models:
    concerns:
      integration_concerns:
        salesforce:
          field_mapping:
            errors:
              must_be_object_with_single_key: must be an object with a single key
              must_contain_an_object: must contain an object
              must_be_a_flat_object: must be a flat object
              must_contain_one_reference_field: must contain at least one reference
                field (starts with =sfdc)
              does_not_recognize_field: does not recognize field %{field}
              invalid_json: is not valid JSON
      quote_concerns:
        signature:
          errors:
            cannot_ask_for_signature: Cannot ask for signature
            cannot_change_after_document_signed: cannot change after the document
              has been signed
        validations:
          errors:
            past_start_date: needs to be in the future
            cannot_change_from_paid: cannot change from paid
            cannot_change_from_completed: cannot change from completed
            cannot_change_from_signed: cannot change from signed to anything other
              than completed
            must_be_set_when_there_is_a_contract: must be set when there is a contract
            cannot_be_set_when_there_is_no_contract: cannot be set when there is no
              contract
            plan_details_are_not_valid: are not valid
      session_concerns:
        summarize:
          session_summary:
            the_user: the user
            went_live: "%{agents} cobrowsed with %{name} for %{formatted_length}."
            session_authorized: "%{agents} tried to cobrowse with %{name} for %{formatted_length},
              but the connection dropped."
            session_not_authorized: "%{agents} tried to cobrowse with %{name}, but
              they never authorized the session."
            session_video_available: 'A video of the session is available here: %{public_video_url}'
            session_audio_call_time: The session included %{formatted_call_length}
              of audio call time.
            user_and_agent_rating: The user rated the quality of the session %{user_session_rating}/5
              and the quality of the agent's help %{user_agent_rating}/5.
            user_agent_feedback: |-
              They've given the following feedback about the agent's help:
              %{user_agent_feedback}
            user_agent_rating: The user rated the quality of the agent's help %{user_agent_rating}/5.
            user_session_rating: The user rated the quality of the session %{user_session_rating}/5.
            session_participation_agent_feedback: |-
              %{agent_name} rated the quality of the session %{agent_session_rating}/5, and left the following feedback:
              %{agent_session_feedback}
            session_participation_agent_session_rating: "%{agent_name} rated the quality
              of the session %{agent_session_rating}/5."
            session_participation_agent_notes: |-
              %{agent_name} left the following note:
              %{agent_notes}
      team_concerns:
        call_credit:
          errors:
            must_be_positive_or_zero: must be a positive number or zero
        customization:
          errors:
            cannot_contain_important: cannot contain "!important". This will be added
              by Upscope.
            can_only_contain_left_top_bottom_right: can only contain attributes left,
              top, bottom, and right
            can_only_contain_left_right: cannot contain both left and right positions
            can_only_contain_top_bottom: cannot contain both top and bottom positions
            need_a_value: needs a value for all arguments
            require_authorization_from_visitor_if_proxy: needs to be turned on if
              proxy is enabled
            cannot_be_enabled_if_not_requesting_authorization: cannot be enabled if
              you are not requesting authorization from the user before a session
        on_premise:
          errors:
            needs_to_start_with_http: needs to start with http:// or https://
            needs_to_be_set: needs to be set
        plans:
          errors:
            must_be_present: must be present
            must_be_currency: must be %{currency}
            requires_next_plan: requires a next plan to be present
            must_be_off_when_contract_is_present: must be off when a contract is present
            cannot_make_plan_changes: cannot be set if the user can make plan changes
            must_be_set_when_there_is_a_contract: must be set when there is a contract
            must_be_set_if_contract_auto_renews: must be set if the contract auto
              renews
        saml:
          errors:
            cannot_enable_if_sign_up_from_email_is_enabled: cannot be enabled if sign
              up from email is enabled
            cannot_be_enabled_without_valid_idp_metadata: cannot be enabled without
              valid idp metadata
            invalid_idp_metadata: doesn't appear to be valid metadata
            single_idp_metadata: and setting_saml_idp_metadata cannot both be set
        sub_accounts:
          errors:
            team_has_subscription: cannot be set as the team has a subscription
            cannot_be_the_same_team: cannot be the same team
            cannot_be_a_subaccount: cannot be a subaccount
        password:
          errors:
            cannot_be_set_by_oauth_users: cannot be set by oauth users
            is_not_allowed_by_your_team: is not allowed by your team
            needs_to_be_8_characters_long: needs to be at least 8 character long
            needs_to_contain_letters_and_non_letters: needs to contains letters and
              non-letters
            password_not_strong: is a commonly used password and not strong enough
        validations:
          errors:
            cannot_look_like_an_email: cannot look like an email
            cannot_look_like_a_domain_or_url: cannot look like a domain or url
    activity_log:
      changes_desc: set %{attribute} to %{value} (previously %{previous})
    auth_session:
      unkown_device: Unknown device
      device: "%{browser} on %{platform} (%{last_ip})"
    auth_tokens:
      change_email:
        token_response: Thank you for verifying the change!
      delete_all_visitors:
        token_response: All your visitors were deleted successfully!
      delete_team:
        token_response: The team was deleted successfully!
      disable_mfa:
        token_response: Your MFA device has been reset!
      enable_password:
        token_response: Your password was successfully enabled!
      invite:
        token_response: "%{name} has been sent an invite"
      join_team:
        token_response: You have successfully joined %{domain}!
      login:
        token_response: Welcome back, %{name}
      sign_up:
        token_response: Welcome to Upscope %{full_name}
      verify_email:
        token_response: Your email was successfully changed!
    integration_authentication_token:
      external_integration_device: External integration (Pending)
      device: Upscope for %{client_type}
    page_view:
      time_ago: "%{time} ago"
    page_view_events:
      leave_to:
        title: Left for %{url}
      enter_from:
        title: Came from %{url}
      click:
        title: Clicked on %{label}
      forms:
        bool:
          title: Selected %{value} from %{label} field
        file:
          title: Added a file %{label} field
        multiple:
          title: Selected %{value} from %{label} dropdown
          redacted_text: Selected an option from %{label} dropdown
        password:
          title: Entered a password in %{label} field
        text:
          title: Typed %{value} in %{label} field
          redacted_text: Typed in %{label} field
    plan:
      month: month
      paid_billing_period: paid %{billing_period}
      to_s_per_license_license_present: "%{cost} plan%{paid_every}, with %{licenses}"
      to_s_per_license: "%{cost} plan%{paid_every}"
      to_s: "%{price} per month plan%{paid_every}"
      cost_s_base_price_included_licenses: "%{base_price} for first %{included_licenses}
        licenses, then"
      cost_s_included_licenses: "%{included_licenses} free licenses, then"
      cost_s_base_cost: "%{base_price} plus"
      cost_s_base_price_negative: minus %{base_price}
      cost_s_base_price_per_license_negative: "%{base_price}%{price_per_license} per
        license per month%{negative_base_price}"
      cost_s: "%{price} per month"
      formatted_period:
        year: year
        month: month
        quarter: quarter
        six_month: six months
        two_month: two months
      billing_period:
        year: yearly
        month: monthly
        quarter: quarterly
        six_month: every six months
        two_month: every two months
    plan_details:
      errors:
        missing_attribute: must be present
        team_currency: must be %{currency}
        invalid_currency: must be one of %{currencies}
        invalid_period: must be one of %{periods}
    rest_key:
      errors:
        never_used: Never used
      formatted_last_used_at: "%{time} ago"
    session_participation:
      errors:
        agent_missing: Agent needed
    team:
      errors:
        cannot_delete_team_with_quotes: Team cannot be deleted because there is a
          quote to be applied
  validators:
    business_email:
      invalid: must be a business email
    language:
      invalid: "%{language} is not a valid language"
    multiple_email:
      invalid: must be a valid email
    saml_url_validator:
      parsing_error: parsing error
      invalid_saml_url: doesn't appear to be a valid URL
      socket_error: is not reachable by our server. Please retry if you believe it
        is correct.
      http_error: does not appear to be a valid IDP metadata URL
    team_domain:
      invalid: needs to be checked!
    url:
      invalid: is an invalid URL
  astronaut_messages:
    upscope_versary_one_year_name: Happy one year Upscope-versary, %{name}!
    upscope_versary_one_year: Happy one year Upscope-versary!
    upscope_versary_two_year_name: Happy two year Upscope-versary, %{name}!
    upscope_versary_two_year: Happy two year Upscope-versary!
    upscope_versary_three_year_name: Happy three year Upscope-versary, %{name}!
    upscope_versary_three_year: Happy three year Upscope-versary!
    upscope_versary_four_year_name: We've known each other for four years, %{name}!
    upscope_versary_four_year: Happy four year Upscope-versary!
    happy_holidays_name: Happy holidays, %{name}!
    happy_holidays: Happy holidays!
    happy_new_year_name: Happy New Year, %{name}!
    happy_new_year: Happy New Year!
    happy_first_day_of_month_name: Happy first day of %{month}, %{name}!
    happy_first_day_of_month: Happy first day of %{month}!
    have_a_great_week_name: Have a great week, %{name}!
    have_a_great_week: Have a great week!
    enjoy_your_sunday_name: Enjoy your Sunday, %{name}!
    enjoy_your_sunday: Enjoy your Sunday!
    hope_you_had_a_nice_weekend: Hope you had a nice weekend, %{name}!
    have_a_great_weekend_name: Have a great weekend, %{name}!
    have_a_great_weekend: Have a great weekend!
    one_more_day_to_the_weekend_name: One more day to the weekend, %{name}!
    one_more_day_to_the_weekend: One more day to the weekend!
    thank_you_for_being_top_user: Thank you for being one of our top users!
  helpers:
    authentication:
      join_title: Join %{domain} on Upscope
      create_upscope_account: Create your Upscope account
  presenters:
    team_stats:
      time:
        zero: 0 times
        one: 1 time
        other: "%{count} times"
      agent:
        zero: 0 agents
        one: 1 agent
        other: "%{count} agents"
      unique_visitor:
        zero: 0 unique visitors
        one: 1 unique visitor
        other: "%{count} unique visitors"
      usage_banner:
        part_1: "%{period}, your team has used Upscope"
        part_2: for a total of
        part_3: that's around %{time_per_session} per screenshare
        part_4: Your agents made a total of <b>%{call_time} of calls.</b>
        part_5: Upscope was used by
        part_6: who have helped
    to_equal_from:
      on_from: On %{from}
    to_equal_today:
      since_installing: Since installing it
      in_the_past_year: In the past year
      in_the_past_day: In the past %{date}
    between_to_and_from:
      display: Between %{from} and %{to}
    quote:
      license:
        zero: 0 licenses
        one: 1 license
        other: "%{count} licenses"
      usage_fees: Usage fees
      default_first_subscription_label: First %{period} subscription fees
      first_period_subscription_fees: 'First %{period} subscription fees, of which:'
      from_to: From %{start_date} to %{end_date}
    integration:
      activate: Activate
      remove: Remove
      enable_sso: Enable SSO
      disable_sso: Disable SSO
      customize: Customize
      disable_sso_confirm: Any user who signed up through this integration will be
        deleted immediately. Continue?
      front:
        functionality: Start co-browsing from conversations, and see screenshots.
      google:
        functionality: SSO provider.
      intercom:
        functionality: Start co-browsing from conversations, see screenshots, log
          sessions in conversations, SSO provider.
      salesforce:
        functionality: Start sessions from Salesforce, log sessions, SSO provider.
      zapier:
        functionality: Connect Upscope to everything else.
      zendesk:
        functionality: Start co-browsing from tickets and chats, and see screenshots.
      twilio_flex:
        functionality: Start co-browsing from conversations and see screenshots.
  mailers:
    best_regards: Best regards,
    hi_there: Hi there,
    hi_name: Hi %{name}
    take_care: Take care,
    thank_you: Thank you,
    call_credit:
      low_credit_notification:
        subject: 'Warning: low call credit'
        title: You only have %{call_credit} of call credit left!
        body_1: It looks like the we could not auto top up your account and you only
          have %{call_credit} of call credit left.
        body_2: You only have %{call_credit} of call credit left on your account.
        body_3: Please purchase more or set up automatic top up by clicking the button
          below.
        purchase_more_link: Purchase more call credit
        body_4: Do let us know if you need any help!
    call_waiting:
      call_waiting:
        subject: Someone is waiting for you on Upscope
        title: Your call on Upscope
        body_1: Someone is waiting for you on your personal Upscope link. You can
          find them on Upscope by following the link below.
        join_call_link: Join call
        body_2: You can customize these email notifications in your personal settings.
    invite:
      invite:
        subject: You've been invited to join %{owner_name}'s team on Upscope
        title: Join %{name} on Upscope
        body_1: You have been invited to join your team on Upscope. Upscope is the
          easiest way to co-browse with your users on %{domain}.
        body_2: Click below to join your team.
        join_now_link: Join %{name} now
        body_3: If you have any question about Upscope, please reply to this email.
      invite_reminder:
        subject: 'Reminder: Join %{owner_name} on Upscope'
        title: Remember to join %{owner_name} on Upscope
        body_1: This is a quick reminder to join your team on Upscope. Upscope is
          the easiest way to co-browse with your users on %{domain}. You can learn
          more about Upscope
        body_2: Click below to join your team.
        join_now_link: Join %{owner_name} now
        body_3: If you have any question about Upscope, please reply to this email.
    mfa_disabled:
      disabled_by_manager:
        subject: Your MFA has been disabled
        body_1: This is to confirm that your MFA has been disabled by %{name}.
        your_admin: your admin
        body_2: You can now log in without needing your MFA device, and can set up
          a new one after you have logged in.
    new_charge:
      charge_notification:
        subject: Upscope receipt for %{domain}
        title: Your Upscope receipt
        body_1: This is an email to inform you that your latest Upscope payment was
          successful. You can get a pdf of the invoice for your records
        body_2: If you'd like to discuss this with our team, feel free to reply to
          this email.
        body_3: Would you like to save %{yearly_saving} per year?
        pay_yearly: Pay yearly
        body_4: and save %{yearly_saving} every year.
      renewal_reminder:
        title: Renewal reminder
        body_1: This is a friendly email to remind you that your Upscope subscription
          will automatically renew on %{next_invoice}.
        body_2: You are currently subscribed to our %{plan}, and are paying with a
          %{stripe_source_brand} card ending in %{stripe_source_ending}.
        body_3: If you have any questions or would like to make changes, please get
          in touch with our team by replying to this email.
      yearly_renewal_reminder:
        subject: Your Upscope subscription will renew on %{next_invoice}
      trial_ending_reminder:
        subject: Your Upscope trial will convert on %{trial_ends_on}
        title: Subscription reminder
        body_1: This is a friendly email to remind you that your trial will convert
          on %{trial_ends_on}.
        body_2: You will be subscribed to our %{plan}, and will be paying with your
          %{stripe_source_brand} card ending in %{stripe_source_ending}.
        body_3: On %{trial_ends_on} we'll charge your card %{plan_price}.
        body_4: Please get in touch by replying to this email if you have any questions!
      invoice_notification:
        subject: Upscope invoice due on %{invoice_due_date}
        title: Upscope invoice due on %{invoice_due_date}
        body_1: This is an email to inform you that you have a new invoice. Please
          pay this invoice by %{invoice_due_date}. You can find our account details
          on the invoice itself.
        download_invoice: Download invoice
        body_2: If you'd like to discuss this with our team, feel free to reply to
          this email.
      invoice_due_notification:
        subject: 'REMINDER: Upscope invoice due tomorrow'
        title: Your invoice is due tomorrow
        body_1: This is a friendly reminder that your Upscope invoice is due tomorrow.
        body_2: If you have made a payment in the last 7 days, please disregard this
          email. If the payment was made before then, please let us know as it should
          have been reconciled by our accounting team by now.
        download_invoice: Download invoice
        body_3: If you'd like to discuss this with our team, feel free to reply to
          this email.
      invoice_overdue_notification:
        subject: 'URGENT: Upscope invoice overdue'
        title: 'Urgent: Your invoice is over one week overdue'
        body_1: This is a friendly reminder that your Upscope invoice is now overdue.
        body_2: If you have made a payment in the last 7 days, please disregard this
          email. If the payment was made before then, please let us know as it should
          have been reconciled by our accounting team by now.
        body_3: If you haven't already, please pay this invoice today and let our
          team know by replying to this email to ensure %{domain} does not lose access
          to Upscope.
        download_invoice: Download invoice
      invoice_payment_received:
        subject: Upscope payment received!
        title: We've received your payment
        body_1: This is an email to confirm that we have received payment for your
          invoice due on %{invoice_due_date}.
        thank_you_for_using_upscope: Thank you for using Upscope!
      refund_notification:
        subject: Your Upscope refund
        title: Your Upscope refund
        body_1: We are happy to let you know that your refund has been processed.
          You can get more details about it on your Upscope
        settings_page: settings page
        body_2: The refund will show up in your bank account within a few days.
        body_3: If you need a copy of the credit note for your records, you'll find
          it
        body_4: in the section "attached documents".
        body_5: You will also find the original invoice at that url.
        body_6: If you'd like to discuss this with our team, feel free to reply to
          this email.
      failed_payment_notification:
        subject: 'ACTION NEEDED: Failed payment for %{domain}'
        title: A payment to Upscope just failed
        body_1: We just tried to charge your %{stripe_source_brand} card, but it has
          expired.
        body_2: Please update your card by clicking the button below to avoid losing
          access to Upscope!
        update_my_card: Update my card
        body_3: No log in required!
        body_4: We just attempted to charge your %{stripe_source_brand} card (it ends
          in %{stripe_source_ending}), but the payment failed.
        body_5: Please update your card by clicking the button below to avoid losing
          access to your account.
        body_6: If you do nothing, we will try the charge again on %{next_charge_attempt_at}.
        body_7: If you do nothing, your subscription will be terminated soon.
        try_again_or_update_my_card: Try again or update my card
        body_8: No log in required!
        body_9: Your subscription has now been terminated! You will need to follow
          the link below to resubscribe.
        resubscribe: Resubscribe
        body_10: Do let us know if you need any help!
    password_disabled:
      disabled:
        subject: You have disabled your password
        body_1: This is to confirm that you have successfully turned off your Upscope
          password.
        body_2: If you didn't request this change, please let us know as someone likely
          has access to your account.
      disabled_by_manager:
        subject: Your password has been disabled
        your_admin: your admin
        body_1: This is to confirm that your password has been disabled by %{name}.
        body_2: You can now log in without needing your password, and can set up a
          new one after you have logged in.
      disabled_after_too_many_attempts:
        subject: Too many failed log in attempts
        body_1: After attempting to log in with the wrong password too many times,
          we have disabled your password for your security.
        body_2: You can now log in by requesting a log in link by email, and can create
          a new password after you have logged in.
        body_3: 'The unsuccessful log in attempts were made from this ip address:
          %{ip_address}.'
    quote:
      ask_for_signature:
        subject: "%{sales_exec_name} is asking for your signature (#%{public_key})"
        title: Sign Upscope Order Form %{public_key}
        body_1: Your signature is required for Upscope Order Form %{public_key}. The
          Order Form was prepared by %{sales_exec_name} for %{company_name}.
        sign_now: Sign now
        body_2: If you have any question or anything isn't correct, please reply to
          this email.
      document_signed:
        subject: Your finalized Upscope document (#%{public_key})
        title: Your finalized Upscope document %{public_key}
        body_1: 'Everyone has signed Upscope Order Form #%{public_key}. Thank you
          for choosing Upscope.'
        body_2: The finalized document is attached to this email.
    renewal:
      contract_renewal_reminder:
        subject: It's time to renew your Upscope contract
        title: It's time to renew your Upscope contract
        body_1: We hope you are doing well. Our records show that your contract is
          up for renewal in approximately two months, so we are reaching out to begin
          the renewal process.
        body_2: Over the next few days, our sales team will reach out to discuss your
          needs going forward. In the meantime, feel free to reply to indicate the
          best person to contact and whether your license needs have changed.
        body_3: We look forward to speaking with you soon!
      contract_ending:
        subject: Your Upscope subscription is ending soon
        title: Your Upscope subscription is ending soon
        body_1: This is to confirm that your subscription is now set to terminate
          on %{contract_terminates_on}.
        body_2: If you'd like to keep using Upscope, please reach out to our sales
          team by replying to this email, and they'll be happy to provide you with
          a renewal quote.
    upsell:
      too_many_agents:
        subject: Your team has run out of Upscope licenses
        title: Your team has run out of Upscope licenses
        body_1: We've noticed that some of your agents
        body_2: are trying to use Upscope, but are being blocked because you don't
          have enough licenses.
        body_3: You are able to purchase more licenses by clicking the button below.
        change_number_of_licenses: Change number of licenses
        body_4: You can get in touch with our sales team by replying to this email
          and they'll be happy to help you get enough licenses to cover your whole
          team.
      team_session_sharing_not_available:
        subject: Team session sharing feature?
        title: Your team is attempting to share their sessions
        body_1: We've noticed that some of your agents
        body_2: are trying to join sessions started by other team members, but your
          plan doesn't have our multi-agent features included. These would allow multiple
          agents to co-browse with the same user at the same time.
        body_3: You can change plan by clicking on the button below, or you can get
          in touch with us by replying to this email.
        see_available_plans: See available plans
        body_4: You can get in touch with our sales team by replying to this email
          and they'll be happy to help you explore your upgrade options.
  errors:
    access_denied: You do not have access to this page
    already_signed_in: You are already signed in
    please_login: Please log in
    not_admin: You are not an admin
    do_not_have_admin_role: You do not have the right admin role
    admin_impersonating: You cannot perform this action while impersonating another
      user
  admin_notes:
    input:
      placeholder: Private notes, will autosave
  activity_log:
    index:
      errors:
        team_not_found: Team not found
        ensure_valid_subscription: Please ensure you have a valid subscription
        cannot_access_activity_log: You cannot access this team's activity log
      title: Activity log
      global_activity_log_title: Global Activity Log
      include_all_users: Include all users
      include_all: Include all
      exclude_co_browsing_sessions: Exclude co-browsing sessions
      include_co_browsing_sessions: Include co-browsing sessions
      admin_team: Admin team
      admin_user: Admin user
  admin:
    debug:
      show:
        errors:
          baa_signed: This team has signed a BAA and debugging is not available
    impersonation:
      start:
        flashes:
          heads_up: Heads up! You are now acting as %{name}!
      stop:
        flashes:
          stop_impersonation: You are no longer acting as %{name}!
      errors:
        user_is_admin: "%{user_name} is an admin."
        team_has_baa: "%{user_name} is a member of a team with a BAA."
        not_impersonating: You are not impersonating anyone
    quote_items:
      create:
        success: Item added
      destroy:
        success: Item removed
      toggle_recurring:
        success: Item updated
    quotes:
      redirect:
        errors:
          quote_not_found: Could not find quote
      update:
        errors:
          cannot_change_signed_quote: Cannot change signed quote
        success: Quote updated
      publish:
        success: Quote published successfully
      ask_for_signature:
        success: Asked for signature
      delete:
        success: Quote deleted successfully
      mark_as_signed:
        success: Quote marked as signed
      errors:
        quote_deleted: This quote has been deleted
    sign_up:
      create:
        success: User created
        errors:
          record_invalid: Error creating account
      new:
        title: Create new account
        panel:
          form:
            label_name: First and last name
            label_email: Business email
            label_phone_number: Phone number
            phone_number_explain: Please include country code if outside of the United
              States.
            submit: Create account
    transfer_quotes:
      create:
        success: Quote transfer successful
      errors:
        cannot_be_transferred: You cannot transfer this quote
      new:
        title: Transfer quote - %{public_key}
    teams:
      billing:
        add_to_invoice:
          success: Added to next invoice
        remove_pending_charge:
          success: Removed invoice item
        invoice:
          errors:
            unable_to_create_invoice: Unable to create invoice!
          success: Invoiced!
        remove_card:
          errors:
            team_not_subscribed: Team is not currently subscribed
          success: Payment card removed
        cancel_subscription:
          errors:
            team_not_subscribed: Team is not currently subscribed
          success: Subscription cancelled
        resume_subscription:
          errors:
            team_not_cancelling: Team is not currently cancelling
          success: Subscription resumed
        pay_invoice:
          success: Invoice paid successfully
        add_vat_number:
          success: Vat changed
          errors:
            vat_service_not_available: The VAT validation service is currently not
              available.
            invalid_vat_number: The VAT number you entered appears invalid.
            vat_and_country_mismatch: The VAT number is from a different country.
              Please contact our staff.
        remove_vat_number:
          success: Vat removed
        subscribe_to_next_plan:
          errors:
            no_next_plan: Team does not have a next plan
            no_payment_card: Team does not have a payment card
            no_valid_country: Team does not have a valid country on file
          success: Subscribed to next plan
        make_quote:
          success: Created quote
        refund_charge:
          errors:
            ask_supervisor: Please ask supervisor to process refund.
            amount_blank: Please enter amount to refund.
            minimum_refund: You cannot refund less than $1.
          success: Charge refunded
        reset_billing:
          errors:
            already_customer: This team is already a customer
          success: Billing reset successfully
        billing_history:
          panel:
            previous_charges:
              title: Previous charges
              date: Date
              amount: Amount
              notes: Notes
              disputed: Disputed
              pending: Pending
              paid: Paid
              failed: Failed
              refunded: Refunded
              refund: Refund
              later_refunded: later refunded
              download_invoice: Download invoice
            previous_invoices:
              title: Previous invoices
              date: Date
              due_on: Due on
              amount: Amount
              notes: Notes
              paid: Paid
              forgiven: Forgiven
              overdue: Overdue!
              waiting_for_payment: Waiting for payment
              duration_payment: It can take up to 1 week for payments to show
              view: View
              generating_invoice: Generating invoice
        new_refund_charge:
          title: Refund charge
          form:
            label_amount: How much should we refund?
            amount_refunded_explain: "%{amount_refunded} out of the original %{amount}
              has already been refunded."
            submit: Refund
        pending_charges:
          panel:
            next_invoice:
              title: Next invoice
              create_new_line_item: Create new line item
              pending_line_items: Pending line items
              description: Description
              amount: Amount
              remove: Remove
              invoice_now: Invoice now
              form:
                label_description: Description
                label_amount: Amount
                submit: Add to next invoice
      merge:
        create:
          success: Teams merged successfully
        new:
          title: Merge %{domain} into another team
          merge: Merge
      companies:
        merge:
          new:
            title: Merge %{master_company_name} into another company
            merge: Merge foobar
          create:
            success: Company merged successfully
      index:
        index:
          title: Find team
      info:
        members:
          user: User
          last_seen: Last seen
          last_used: Last used
          role: Role
          owner: Owner
          attempted: Attempted
          go_to_user: Go to user
        show:
          title: Manage
          billing: Billing
          billing_description: Their plan, next plan, and payment details
          settings: Settings
          settings_description: Basic team information
          members: Members
          members_description: Team members who were last online
          nuclear_zone: Nuclear zone
          nuclear_zone_description: Deleting the team or their data
        top_health_table:
          panel:
            other_teams:
              title: Other teams
              merge_with: Merge with...
              domain: Domain
              score: Score
              usage_trend: Usage trend
              value: Value
              new_team: New team
              new_health_data: New (%{current_hrs} hrs)
              negative: Negative
              stable: Stable
              positive: Positive
        update:
          success: Team updated
        remove_next_plan:
          success: Next plan removed
        create_next_plan_from_plan:
          errors:
            missing_plan: Could not find plan
          success: Created next plan from plan
        add_plan_to_next_plan:
          errors:
            missing_plan: Team does not have a plan
          success: Added plan to next plan
        delete:
          deletable_success: Team deleted
          email_success: Email sent to team owner to delete team
        delete_all_visitors:
          success: Email sent to team owner to delete visitors data
        make_baa:
          success: The team now has BAA protections in place
        force_sync:
          success: The team is now being synced
        partials:
          alerts:
            paid_for: 'This team is paid for by:'
            paying_for: 'This team is paying for the following teams:'
          billing_current:
            panel:
              current_subscription:
                title: Current subscription
                team_current_plan: The team current plan is %{plan}.
                invoiced: invoiced and paying by bank transfer
                charged: charged %{price} every %{period}
                autosubscribe_to_next_plan: On %{next_plan_date}, the subscription
                  will change to a %{next_plan}.
                team_invoiced_or_charged: The team is currently %{description}.
              actions:
                title: Actions
                resume_subscription: Resume subscription
                copy_to_next_plan: Copy current plan to next plan
                cancel_at_end: Cancel at end
                cancel_now: Cancel now
          billing_info:
            payment_source_info: This team is currently paying with a %{stripe_source_brand}
              card ending in %{stripe_source_ending}.
            remove: Remove
            free_account_info: "%{domain} uses custom billing."
            plan_invoiced_past_due_error: The team has an unpaid overdue invoice.
              Payments can take up to 1 week to appear.
            past_due_charge_tried_error: We tried charging the team's card %{attempted_at}
              ago, but the charge failed.
            click_here: Click here
            try_again_invoice: to try again now, or ask owner to change card in their
              settings.
            past_due_error: We are unable to process the latest charge.
          billing_vat:
            panel:
              vat:
                title: VAT (Europe + UK)
                description: "%{domain} is currently charged a VAT rate of %{tax_rate}%."
              vat_details:
                title: Vat details
                vat_number: VAT number
                remove: Remove
                vat_name: VAT name
                vat_address: VAT address
                vat_country: VAT country
                form:
                  label_vat_number: Vat number
                  submit: Add vat number
          billings_limits_features:
            panel:
              delegation_trial_licenses_contract_features:
                title: Delegation, trial, licenses, contract, features
                form:
                  label_features_enabled: Features enabled
                  submit: Update
              billing_owner:
                title: Billing owner
                main_billing_team: Main billing team
                billing_delegated_to_id_explain: If set, this team will not be able
                  to purchase and be billed through the other team instead.
              call_credit:
                title: Call credit
                form:
                  label_call_hours_paid_for: Total call credit
                  call_hours_paid_for_explain: This team has used %{call_time} of
                    call time.
              custom_billing:
                title: Custom billing / free account
                form:
                  label_free_account: Uses free account?
                  free_account_explain_html: Setting this to <b>Yes</b> will
                    prevent person from purchasing in app.
                  label_is_subscription_active: Subscription status
              licenses:
                title: Licenses
                form:
                  label_active_licenses: Active licenses
                  label_auto_adjust_licenses: Automatically adjust?%{licenses}
                  label_auto_adjust_licenses_range: " (between %{min_licenses} and
                    %{max_licenses})"
                  select_increase_only: Increase only
                  select_increase_and_decrease: Increase and decrease
                  select_bill_at_end_of_month: Bill at the end of month
                  select_off: Off (do manually)
                  select_not_allowed: Not allowed
                  label_new_members_grace_period_days: New members do not need to
                    have a license for
                  select_no_grace_period: No grace period
                  select_24_hours: 24 hours
                  select_7_days: 7 days
                  select_31_days: 31 days
              trial:
                title: Trial / push or skip next invoice
                form:
                  label_billing_paused_until: Do not invoice until
                  label_trial_ends_on: Trial ends on
                  label_first_invoice_after: First invoice after
              contract:
                title: Contract
                form:
                  label_can_make_plan_changes: Can change plan / cancel?
                  select_no_require_contact: No, require contact
                  label_contract_terminates_on: When does the contract terminate?
                  contract_terminates_on_explain: Leave blank if it doesn't have a
                    set length
                  label_contract_auto_renews: Does it renew automatically?
                  contract_auto_renews_explain: If yes, it will renew by 1 year at
                    a time
          next_plan:
            panel:
              next_plan:
                title: Next plan
                next_plan_description: The team next plan is %{next_plan}.
                auto_subscribe_to_next_plan_description: The next plan will
                  be accepted automatically on %{next_plan_activates_on}.
                invoiced_and_paying: invoiced and paying by bank transfer
                active_licenses_description: With %{active_licenses} licenses, the
                  team would be %{invoiced_or_charged} %{price} every %{period}.
                no_next_plan_description_html: This team does not have a <strong>custom
                  offer</strong>. Create one from an existing plan here.
                form:
                  label_plan: Plan
                  copy_to_next_plan: Copy to next plan
              actions:
                title: Actions
                subscribe_to_next_plan: Subscribe to next plan
                remove_next_plan: Remove next plan
          invoice_details:
            panel:
              invoice_details:
                title: Invoice details
                description: These settings will only affect future invoices.
                form:
                  label_invoice_name: Invoice name
                  label_invoice_address: Invoice address, including city
                  label_invoice_country: Invoice country
                  label_invoice_email: Invoice email
                  invoice_email_explain: If left empty, invoices will be sent to %{email}
                    instead.
                  label_invoice_days_until_due: Days until invoices are due
                  submit: Update
          navigation:
            owner: Owner
            make_quote: Make quote
            current_quote: Current quote
            activity_log: Activity log
            session_history: Session history
            usage: Usage
          nuclear_zone:
            add_baa_protections: Add BAA protections
            add_baa_protections_confirm: Are your sure? Please only do this after
              signing a BAA.
            force_external_sync: Force external sync
            reset_billing: Reset Billing
            send_delete_visitors_email: Send delete visitors email
            delete_team_now: Delete team now
            send_team_delete_email: Send team delete email
      notes:
        errors:
          record_not_found: Team has been deleted!
        save_notes:
          errors:
            comments_changed: Someone has changed the comments. Please reload the
              page to avoid conflicts.
    users:
      errors:
        user_not_found: We could not find this user
        user_is_admin: 'Unauthorized: %{user_name} is an admin.'
        team_has_baa: 'Unauthorized: %{user_name} is a member of a team with a BAA.'
      update:
        success: User updated
      detach_license:
        success: Removed license
      create_team:
        errors:
          user_from_oauth: User is from Oauth
          record_invalid: Error creating team
        success: Team created
      send_log_in_email:
        errors:
          user_from_oauth: Oauth users cannot log in by email
        success: Log in email has been sent
      index:
        title: Find user
      show:
        title: Manage
        panel:
          user_details:
            title: User details
            form:
              input:
                label_name: Your first and last name
                label_email: Your business email
                label_phone_number: Your phone number
              submit: Save
          admin_status:
            title: Admin Status
            form:
              input:
                label_admin_roles: Admin roles
              submit: Save
          new_team:
            title: New team
            form:
              input:
                label_domain: The domain of the new team
              submit: Create team
          teams:
            title: Teams
            table:
              go_to_team: Go to team
              header:
                team: Team
                roles: Roles
              td:
                owner: Owner
        alerts:
          oauth_warning: This user was imported from %{integration_name}. As such,
            all changes will be overwritten every hour.
          login_token_info: You can send this link to the user on live chat for them
            to log in and set their password.
        buttons:
          impersonate_user: Impersonate user
          team: Team
          remove_license: Remove license
          send_login_email: Send Log in email
          activity_log: Activity log
        confirm:
          remove_license: The team will not pay for this agent until they screen share
            again. Continue?
  compare_plans:
    show:
      team_paying_title: Change your Upscope plan
      team_trial_title: Start your free trial
      choose_plan_title: Choose your plan
      your_upscope_subscription: Your Upscope subscription
      start_trial: Start your 14-day free trial!
      start_subscription: Start your subscription today
      click_here: Click here
      to_see_next_plan: to see your next plan!
      tax_info: Prices don't include VAT which will be calculated at checkout.
    errors:
      cannot_purchase: You cannot manage billing for this team
      free_account: Please ask your account manager about changing your subscription
      cannot_make_plan_changes: Please ask your account manager about changing your
        subscription
      subaccount: This team is subscribed through the %{domain} team
  demo_scheduler:
    errors:
      missing_email: Please provide your email
      missing_name: Please provide your name
      missing_phone number: Please provide your phone number
      missing_website: Please provide your website
  invoices:
    redirect:
      title: Preparing invoice
      central_message_title: We are preparing your invoice
      central_message_html: Please leave this page open—you'll be redirected to your
        invoice once it's ready <strong> in around %{next_created_at}!</strong>
    show_failed:
      errors:
        invalid_invoice: Invalid invoice
      invoice_paid: Invoice has been paid
      payment_needed: Payment needed
      send_this_page_to_your_accounting_team: Feel free to send this page to your
        accounting team. They won't need to log in to access it.
    report_paid:
      invoice_paid: Your invoice was paid!
      errors:
        invalid_invoice: Invalid invoice
        invoice_not_paid: Invoice not paid
    errors:
      charge_not_found: We could not find this charge.
  purchase:
    add_vat_number:
      errors:
        vat_service_unavailable: The VAT validation service is currently not available.
        invalid_vat_number: The VAT number you entered appears invalid.
        vat_and_country_mismatch: The VAT number is from a different country. Please
          contact our staff.
    do_purchase:
      success: Thank you for upgrading!
      errors:
        invalid: Invalid data
    errors:
      cannot_manage_billing: You cannot manage billing for this team
      free_account: Please ask your account manager about changing your subscription
      cannot_make_plan_changes: Please ask your account manager about changing your
        subscription
      subaccount: This team is subscribed through the %{domain} team
      plan_not_found: We could not find that plan
    show_plan:
      team_paying_title: Change your Upscope plan
      team_trial_title: Start your 14-day free trial
      team_subscription_title: Start your subscription today
      see_our_default_plans: See our default plans
  quotes:
    subscription_details:
      panel:
        title: Subscription details
        plan: Plan
        payment: Payment
        invoiced_net_30: Invoiced, NET-30 (first payment due %{real_start_date})
        by_credit_card: By credit card (first payment collected automatically on %{real_start_date})
        initial_licenses: Initial licenses
        auto_adjust_licenses_not_allowed: Please get in touch with %{created_by} if
          you need more than %{licenses} licenses.
        auto_adjust_licenses_increase: If you use more than %{licenses} licenses,
          your plan will adjust automatically, and you will be charged for the additional
          licenses for the rest of the %{plan_period}.
        auto_adjust_licenses_increase_and_decrease: Each month, we'll look at your
          usage and adjust your licenses count between %{min_licenses} and %{max_licenses},
          and charge or credit your account accordingly.
        auto_adjust_licenses_bill_monthly: If during any month you use more than %{licenses}
          licenses, we'll charge you %{plan_price} per extra license at the end of
          the month.
        cost: Cost
        license_type: License type
        seat: Seat
        seat_info: An agent occupies a seat until they log out.
        concurrent: Concurrent
        concurrent_info: A license is needed for each simultaneous session, regardless
          of the number of participants in each session.
        named_license: Named license
        named_license_info: Each individual agent needs their own license until they
          are removed from the team.
        start_date: Start date
        contract_duration: Contract duration
        unchanged: Unchanged
        contract_set_to_renew: Your contract is set to renew on %{contract_terminates_on}
        contract_set_to_terminate: Your contract is set to terminate on %{terminates_on}
        auto_renew: Unless canceled, the subscription will renew on %{real_start_date}
        subscription_will_terminate: Unless renewed, the subscription will terminate
          on %{real_start_date}
        subscription_will_keep: The subscription will keep renewing until canceled.
    total_cost:
      panel:
        title: Total %{upgrade}cost on %{real_start_date}
        unused_time: Unused time on your current plan
        original_billing_period: 'Original billing period: %{prorate_start_date} to
          %{prorate_end_date}'
        remaining_time_subscription_fees: Current %{plan_period} remaining time subscription
          fees
        from_to: From %{real_start_date} to %{prorate_end_date}
        charge: charge
        discount: discount
        one_off: One-off %{charge_or_discount}
        subtotal: Subtotal
        sales_tax_vat: Sales Tax / VAT
    payment_schedule:
      panel:
        title: Payment schedule
        subscription_will_renew_on: Subscription will renew on %{date}. Amounts assume
          no changes in subscription or number of licenses. Amounts include VAT and
          sales tax.
        subscription_terminates_on: Subscription terminates on %{date}. Amounts assume
          no changes in subscription or number of licenses. Amounts include VAT and
          sales tax.
    client_details:
      panel:
        title: Client details
        company_name: Company name
        po_number: PO Number
        vat_number: VAT number
        address: Address
        invoice_email: Invoice email
        contact: Contact
        team_owner_will_be_root_account: "%{team_owner_email} will be the root account."
        authorized_signature: Authorized Signature
        signer_will_sign_contract: "%{signer_name} will sign Upscope's contract."
        quote_signed_info: These details can be changed in the billing section of
          your account.
        get_in_touch: Please get in touch with %{link} to change these details.
    signature:
      panel:
        title: Signature
        copy_of_signed_document: A copy of the signed document will be emailed to
          you after your signature.
        signed_on_behalf_of: Signed on behalf of
        sales_executive: Sales Executive
        signature_1: By typing your name below,
        signature_2: in accordance with the Master Services Agreement in effect between
          Upscope and %{company_name}, you agree for %{company_name} to pay Upscope
        signature_3: you agree to the Master Services Agreement below and you agree
          for %{company_name} to pay Upscope
        signature_4: on %{real_start_date} and to change %{company_name}'s subscription
          plan according to the pricing described above.
        signature_5: on %{real_start_date} and
        signature_6: every following %{plan_period} as detailed in the schedule above.
        signature_7: You further agree that unless either Party notifies the other
          in writing at least 60 days before the end of the Subscription Term (i.e.
          by %{real_start_date}), the Subscription will automatically renew for periods
          of 12 months at a time.
        signature_8: You further agree that unless the Subscription is canceled before
          the end of the Subscription Term, the Subscription will automatically renew
          for periods of %{months} at a time.
        awaiting_signature_alert: Please make sure you follow the link sent by email
          to %{signer_email} to sign.
        sign_and_agree: Sign and agree
        enter_your_name_to_sign: Please enter your name to sign.
        signature_verified: "%{marked_as_signed_by_name} has verified the signature
          on %{signed_at}"
    signature_log:
      panel:
        title: Signature Log
        person: Person
        action: Action
        ip: IP
        timestamp: Timestamp
        signed: Signed
    msa:
      panel:
        title: Master Services Agreement
        description: Please review Upscope's Master Services Agreement before signing.
          The MSA will be attached to the final document for your convenience.
        download_msa: Download MSA
        legal_review: Legal review
        need_legal_team_redline_msa: Do you need your legal team to redline our MSA?
        download_msa_info: We are happy to review your changes. Please download the
          MSA in %{docx_link} and after your team has reviewed it, send it to %{legal_link}
          using reference %{public_key}.
        docx_format: ".docx format"
    show:
      title: Order form %{public_key}
      order_form_for: Order Form for
      quote_title: Quote %{public_key}
      quote_for: Quote for
      reference_number: Reference number %{public_key}
      click_here: click here
      draft_quote_alert: This quote hasn't been published yet! You are only previewing
        it.
      need_help: Need help?
      contact_us: Contact %{name}
      for_assistance: for assistance.
      quote_expired: Quote %{public_key} expired
      no_longer_can_be_accepted: and it can no longer be accepted.
      click_to_see_latest_quote: Please %{link} to see the latest quote.
      please_sign_this_order_form: Please sign this Order Form on or before %{expires_at}.
      previewing_the_order_form: You are previewing the Order Form for Quote %{public_key}.
      quote_expires_on: This quote expires on %{expires_at}.
      preview_the_order_form: To preview the Order Form associated with this quote,
        %{link}.
      order_accepted_by: This order was accepted by %{signer_name} on %{signed_at}.
      confirmation_message: You'll receive a confirmation by email. Feel free to print
        this page for your records.
      panel:
        remarks:
          title: "%{created_by_name}'s remarks"
    sign:
      success: Thank you for signing
    errors:
      not_found: Quote not found
  reporting:
    download:
      errors:
        report_empty: Nothing to export with the options selected
    errors:
      team_not_found: Team not found
      cannot_view_reporting: You cannot access this team's analytics page
    show:
      title: Usage
      feature_name: Usage breakdown
      user: User
      sessions: Sessions
      time: Time
      call_time: Call time
      screenshots_viewed: Screenshots viewed
      unique_visitors: Unique visitors
      download: Download
      too_many_agents_alert: There are too many agents to give you a breakdown of
        usage per agent.
      form:
        label_from: Show stats between
        submit: Go
  sessions_list:
    admin_index:
      title: Session history
      global_title: Global Session history
      activity_log: Activity log
      usage: Usage
      admin_team: Admin team
      admin_user: Admin user
    index:
      title: Session history
      feature_preview_name: Detailed session history
      tip: You can now record your sessions in video format, for training and compliance.
        %{link} to enable.
      show_everyone: Show everyone
      agent_session: "%{agent_nickname}'s sessions"
      show_all: Show all
    video_redirect:
      errors:
        video_url_blank: Session does not have video
    errors:
      team_not_found: Team not found
      invalid_subscription: Please ensure you have a valid subscription
      cannot_access_activity_log: You cannot access this team's activity log
      cannot_view_reporting: You cannot access this team's usage
      start_session: Start session
  manage_members_widget:
    last_used: Last used
    team_owner: Team owner
    this_is_you: This is you!
    click_to_disable: Click to disable
    click_to_enable: Click to enable
    last_attempted_to_co_browse: Last attempted to co-browse
    this_user_can: this user can
    this_user_cant: this user can't
    this_user_now_can: This user now can
    this_user_can_no_longer: This user can no longer
    you_do_not_have_this_permission: You do not have this permission
    log_out: Log out
    reset_password: Reset password
    reset_mfa: Reset MFA
    remove: Remove
    bounced: Bounced
    this_user_will_be_able: This user will be able to
    this_user_wont_be_able: This user won't be able to
    this_user_will_no_longer_be_able: This user will no longer be able to
    clear: Clear
    revoke: Revoke
    resend: Resend
    you_cannot_grant_permissions_you_dont_hold: You cannot grant permissions you do
      not hold yourself.
    on_remove:
      confirm: Are you sure you want to remove %{member_name} (%{member_email}) from
        your team?
      member_not_removed: Member not removed!
      success: Member removed from your team!
      error: There was a problem removing this user from the team.
    on_reset_password:
      success: Member's password reset!
      error: There was a problem resetting this member's password.
    on_disable_mfa:
      success: Member's MFA device reset!
      error: There was a problem resetting this member's MFA device.
    on_logout:
      success: Member logged out!
      error: There was a problem logging out the user.
    on_revoke_invite:
      success: Invite revoked!
      error: There was a problem removing this invite.
    on_resend_invite:
      success: Invite resent!
      error: There was a problem resending this invite.
    on_invite:
      success: "%{email} has been sent an invite!"
      error: There was a problem with the invite. Please try again!
    reached_max_number_of_agents: It looks like you’ve reached the maximum number
      of monthly agents. <click>Click here</click> to explore other plans.
    existing_members: Existing members
    name_and_email: Name & email
    source: Source
    last_seen: Last seen
    permissions: Permissions
    invitations:
      panel:
        title: Invitations
        invite_someone_new: Invite someone new
        invite: Invite!
    pending_invitations:
      panel:
        title: Pending invitations
        email: Email
        sent: Sent
        permissions: Permissions
    transfer_team_ownership:
      panel:
        title: Transfer team ownership
        body_1: If you'd like to transfer your team's ownership to another user, click
          the button below.
        body_2: The transfer will take place immediately and you'll be converted to
          a regular user.
        transfer_ownership: Transfer ownership
    grace_message:
      tooltip:
        text: This user is new, so they can use Upscope for free
    never_used: Never used
    seat_tooltip:
      text: This user currently holds a seat
    license_tooltip:
      text: This user currently holds a license
    permission_types:
      tooltip:
        can_access_visitors_list:
          title: Visitor list
          text: view a list of users connected to the website
        can_screenshare:
          title: Co-browsing
          text: watch all user's screens
        can_access_settings:
          title: General settings
          text: access the general settings
        can_manage_users:
          title: User management
          text: manage the team's users
        can_access_activity_log:
          title: Activity log and audit
          text: access the team's activity log
        can_manage_billing:
          title: Billing
          text: pay for the team and manage the billing settings
        can_use_console:
          title: Remote console
          text: remotely execute console commands (if enabled)
        can_delete_visitor_data:
          title: Data removal
          text: delete metadata of visitors
        can_view_reporting:
          title: Reporting
          text: view reporting and usage analytics
  pay_now_widget:
    failed_to_load_stripe: There was a problem loading Stripe. Please reload.
    card_choices:
      what_card_you_want_to_use: What card would you like to use?
      use_card: Use your %{brand} card ending in %{ending}
      update_my_payment_card: Update my payment card.
    thanks_for_paying_invoice: Thank you for paying your invoice!
    download_pdf: Download a PDF of your invoice
    card_expired_message: We tried charging your card %{amount}, but the card has
      expired. Please update your card below to continue using Upscope!
    we_will_try_again: If you don't do anything we'll try again
    we_tried_charging_your_card: We tried charging your card %{amount} around <time/>,
      but the payment failed. Please try again to continue using Upscope.
    try_again: Try again
    update_your_card: Update your card
    update_your_card_and_try_again: Update your card and try again
    attempt_charge_again: To attempt the charge again, please use the button below.
  sessions_list_widget:
    recording: Recording
    unsupported_browser: Sorry, your browser doesn't support embedded videos.
    ended_at: Ended <time/>, %{length} long
    started_at_on_premise: Started <time/>, on premise
    started_at: Started at <time />
    attempted_at_not_authorized: Attempted <time />, but not authorized
    attempted_not_started: Attempted <time />, but not started
    api: Api
    on_prem: On prem
    deleted: Deleted
    session_details: Session details
    agent_notes: "%{agent_nickname}'s notes"
    session_feedback: "%{visitor_nickname}'s feedback"
    agent_rating: Agent rating
    agent_feedback: Agent feedback
    session_quality_rating: Session quality rating
    session_webhook_logs:
      title: Session Webhook Logs
      webhook_url: Webhook URL
      status: Status
      requested_at: Requested at
      timed_out: Timed out
    details_item:
      name: Name
      email: Email
      unique_id: Unique Id
      ip_address: IP Address
      on_premise: On premise
      on_premise_text: This session happened on premise on your server and we do not
        have further details.
      error: Error
      error_text_session_not_authorized: The visitor did not authorize the session
      error_text_session_authorized: The visitor went offline before the session started
      length: Length
      audio_call_time: Audio call time
      features: Features
    tooltip:
      filter:
        title: Filter
        text: See only %{agent_nickname}'s sessions
      search:
        title: Search
        text: Search for visitor
    status_video_message:
      pending: The recording will be processed shortly.
      recording: The recording is currently being generated.
      not_available: There is no recording available for this session.
      expired: This recording has now expired. Please get in touch with our Sales
        team if you want recordings to be kept for longer.
      removed: A member of your team has removed this recording.
  usage_info:
    errors:
      session_not_found: Session not found
      wrong_auth_key: Wrong auth key
  leads:
    errors:
      missing_email: Please provide your Email
      missing_name: Please provide your Name
      invalid_business_email: Please provide a valid business email
  settings:
    personal_auth_tokens:
      destroy:
        success: You have successfully been logged out of %{device}
      new:
        conect_your_upscope_account: Connect your Upscope account
        return_to_live_chat: Return to LiveChat and enter this code into the Upscope
          widget.
        done: Done
    team:
      beta:
        show:
          title: Beta features
          beta_success_alert: Beta features are enabled for %{team_domain}.
          join_beta_title: Interested in joining our beta?
          join_beta_detail: Turn beta features on to gain early access to the features
            below. Your feedback will be used to make improvements before we release
            them to all our users!
          beta_feature_trouble: If you are having trouble with beta features, please
          turn_beta_on: Turn beta features on
          contact_us: Contact us
          or: or
          turn_beta_off: Turn beta features off
          panel:
            feature_details:
              title: BETA FEATURES YOU ARE USING
            feature:
              header: Feature
              rewrite_title: External link rewrite
              recording_title: Session recording
              no_feature_title: You are not currently testing any beta features.
            description:
              header: Description
              rewrite_description: Automatically rewrite external links to keep the
                user session active.
              recording_description: Record sessions to an mp4 video.
            feedback:
              link: Feedback
        on_success: Beta features turned on
        off_success: Beta features turned off
        errors:
          permission_denied: You cannot change settings for this team
      billing:
        base:
          errors:
            use_main_team: Please use the main team's billing page
            permission_denied: You cannot view billing details for this team
            not_subscribed: You are not currently subscribed
            contact_your_account_manager: Please contact your account manager
        call_credit:
          partials:
            alerts:
              please: Please
              link: update your payment method
              top_up: to make sure the auto top up function works.
              current_call_credit: You currently have
              credits_left: of call credits left
              no_credits_left: You have run out of call credit. Purchase more below.
              credit_auto_top_up_description: When your credit goes below %{auto_top_up_trigger}
                hours, we will add %{auto_top_up_amount} hours automatically.
          success: Settings updated successfully!
          purchase:
            success: Call credit purchased successfully!
            errors:
              validate_purchase: You need to buy at least one hour
          show:
            title: Your call credit
            feature_audio_calling: Audio calling
            panel:
              title: PURCHASE MORE CREDIT
              submit_tag: Buy credits
              hours: hours
              please: Please
              update_your_payment_method: update your payment method
              to_purchase_more_credits: to purchase more credits.
            panel_auto_top_up:
              title: Auto top up settings
              link: update your payment method
              error_text_1: Make sure to
              error_text_2: to make sure the auto top up function works.
            form:
              label: Auto top up rules
              auto_enabled: Auto top up enabled
              auto_disabled: Auto top up disabled
              text_1: When my call credit goes below
              text_2: hours, add
              text_3: hours to my account.
              price_message_1: Calls are priced
              price_message_2: per hour
              button: Save
        cancel:
          show:
            title: Cancel your subscription
            panel:
              title: We're sorry to see you go!
              text: If Upscope is not working for you, you can cancel below. We'd
                love to have the opportunity to fix things for you, so if you feel
                like giving us a second chance, please chat with us below.
              button: Chat with our team
              h2_trial: End trial
              h2_subscription: Cancel subscription
              text_2: If you still want to cancel, click the button below.
              text_on_trial: You'll continue to be able to use Upscope until your
                trial ends on
              text_past_due: You'll continue to be able to use Upscope until
            form:
              title: Why would you like to cancel Upscope?
              choose: Choose...
              technical_issues: Technical issues
              too_expensive: Too expensive
              competitor: Switching to another product
              shut_down: Shutting down the company / project
              dont_use: Agents don't use enough
              missing_featurest: Missing features I need
              other: Other
              reason_other: Can you provide more details?
              reason_competitor: Can you tell us which product you are moving to?
              reason_missing_features: Can you tell us which features you'd like implemented?
              reason_input_placeholder: I need to be able to...
              button_nevermind: Nevermind, I will keep Upscope
              button_cancel: Cancel subscription
          delete:
            subscription_cancelled_success: Your subscription was cancelled!
            logs:
              cancel_subscription: The %{target} was cancelled
          resume:
            success: Your subscription is active again!
        convert_yearly:
          update:
            errors:
              not_available: Yearly conversion not available
            success: Thank you for switching to a yearly plan!
          show:
            title: Thank you for paying yearly!
            save_var_per_year: Save %{var} per year
            yearly_plan_thank_you: Thank you for switching to a yearly plan!
            pay_annually_explain: A growing number of our customers are choosing to
              pay annually instead of monthly.
            you_are_already_paying_yearly: You are already paying yearly!
            click_here: Click here
            to_explore_our_other_plans: to explore our other plans.
            hey: Hey %{name}
            there: there
            you_can_save: You can save <b>%{var}</b> every year (that's two free months)
              by switching to annual billing, and will also have to deal with fewer
              invoices.
            switch_to_yearly: By switching to a yearly plan your card will be charged
              %{var} every year starting today until you cancel. The unused time on
              the current month will be prorated an applied towards your first year.
            switch_to_annual_suggestion: If you'd like to switch to an annual plan,
              you can click the button below.
            take_care: Take care,
            switch_to_a_yearly_plan_button: Switch to a yearly plan
        delegate:
          link_to_other_team:
            errors:
              cannot_link_team: You cannot link this team
            success: Linked billing
          unlink_other_team:
            errors:
              not_currently_linked: This team is not currently linked
            success: Unlinked billing
        info:
          failed_invoice_redirect:
            errors:
              no_invoices_to_pay: No invoices to pay!
          partials:
            alerts:
              invoice_past_due: Your latest invoice is currently past due. Please
                make sure it is paid to continue using Upscope. If you paid the invoice
                in the past week, please ignore this message.
              tried_charging_your_card_fail: We tried charging your card %{latest_charge_fail_amount_formatted}
                approximately %{time_ago_in_words} ago, but the charge failed.
              click_here: Click here
              update_your_payment_details: to update your payment details or try again.
              unable_to_process_latest_charge: We are unable to process the latest
                charge.
              to_update_your_payment_details_or_try_again: to update your payment
                details or try again.
              your_subscription_is_set_to_end: Your subscription is set to end in
                %{time_ago_in_words}.
              to_remain_subscribed: to remain subscribed.
              your_subscription_ended: Your subscription ended.
              please_contact_your_account_manager: Please contact your account manager
                to purchase a subscription!
              select_a_plan: Select a plan
              to_use_upscope_again: to use Upscope again.
              your_trial_has_ended: Your trial has ended.
              to_start_using_upscope: to start using Upscope.
              please_contact_your_account_manager_to_start_trial: Please contact your
                account manager to start your 14-day free trial!
              to_start_your_free_trial: to start your free trial.
              you_are_currently_on_trial_until: You are currently on trial until %{var}.
              after_your_trial: After your trial you will be on the %{var}.
              to_change_plan_or: to change plan, or
              here: here
              to_cancel: to cancel.
              you_currently_have: You currently have
              your_number_licenses: licenses, bringing your total to %{formatted_price}
                per %{formatted_period}.
              your_licenses_will_auto_adjust_based_on_usage: Your licenses will auto
                adjust based on usage.
              please_contact_your_account_manager_plan: Please contact your account
                manager to sign up for a plan.
              choose_a_plan: Choose a plan
              to_continue_using_upscope_after_your_trial: to continue using Upscope
                after your trial.
              thank_you_for_subscribing: Thank you for subscribing to Upscope! You
                are currently subscribed to our
              you_currently_have_active_licenses_licenses: You currently have <b>%{active_licenses}</b>
                licenses, bringing your total to %{formatted_price} per %{formatted_period}.
                Your licenses will auto adjust based on usage.
              subscription_price_will_change: On %{var}, your subscription price will
                change to a %{next_plan}.
              to_see_our_other_plans: to see our other plans.
              you_will_not_be_paying_for_upscope_until: You will not be paying for
                Upscope until %{var}.
              account_manager_billing: Please enquire with your account manager about
                your billing situation.
            delegate:
              link_to_other_team: Link to other team
              you_are_a_billing_manager_for_other_teams: You are a billing manager
                for other teams which can pay for this team. You can link this team
                to one of them by selecting one of the following teams.
              delegate_billing_to: Delegate billing to
              link_billing: Link billing
              other_teams: Other teams
              this_team_is_also_paying_for_the_following_teams: This team is also
                paying for the following teams. All their usage will be bundled together
                with the current team.
              teams: Teams
              team: Team
              licenses: Licenses
              actions: Actions
              needed: needed.
              currently_in_use: currently in use.
              unlink: Unlink
          history:
            previous_charges: Previous charges
            date: Date
            amount: Amount
            notes: Notes
            disputed: Disputed
            pending: Pending
            failed: Failed
            refunded: Refunded
            paid_amount_refunded_formatted_later_refunded: Paid (%{amount_refunded_formatted}
              later refunded)
            paid: Paid
            download_invoice: Download invoice
            generating_invoice: Generating invoice
            invoices: Invoices
            due_on: Due on
            forgiven: Forgiven
            overdue: Overdue!
            waiting_for_payment: Waiting for payment
            it_can_take_up_to_1_week: It can take up to 1 week for a payments to show
          show:
            your_billing_settings: Your billing settings
            your_licenses: Your licenses
            update_your_invoice_details: Update your invoice details
            please_note: Please note these changes will only affect future invoices.
            your_vat_number: Your VAT number is <strong>%{vat_number}</strong>.
            if_this_has_changed_please_get_in_touch: If this has changed, please get
              in touch with our team.
            invoice_name: Invoice name
            invoice_address_including_city: Invoice address, including city
            invoice_email: Invoice email
            if_left_empty: If left empty, invoices will be sent to %{email} instead.
              You can enter multiple emails separated by comma.
            update: Update
            end_your_trial: End your trial
            end_trial_explain: If you don't want to use Upscope for %{domain} in the
              future, you can click the button below to end your trial now.
            end_trial_now: End trial now
            cancel_your_subscription: Cancel your subscription
            cancel_subscription: Cancel subscription
            subscription_end_date: You'll continue to be able to use it until %{var}.
        invoice_details:
          update:
            success: Billing settings updated
        licenses:
          update:
            errors:
              cannot_change_licenses: Your plan is no longer available and licenses
                cannot be adjusted. Please change your plan.
      delete_team:
        success: You have successfully deleted %{domain}!
        errors:
          cannot_delete_team: You cannot delete this team
        show:
          title: Delete
          button: Delete
          delete_warning: Deleting the team is immediate, and there is no undo.
          delete_team_1: To delete the team
          delete_team_2: please click the button below.
          from_invite_1: Thank you for joining
          from_invite_2: To keep things tidy, do you want to delete the old team you
            created when you signed up?
          link_yes: Yes, delete my old team
          link_no: No, keep both
          button_confirm: Are you sure you want to delete
      delete_visitor_data:
        errors:
          cannot_delete_visitor: This visitor cannot be deleted right now. Retry later.
          bad_request: Please try again later
          permission_denied: You cannot delete visitor data for this team
        success: Visitor deleted successfully
        index:
          delete_visitor_data: Delete visitor data
          our_data_server_is_not_responding_please_try_again: Our data server is not
            responding. Please try again in a few minutes.
          check_our: Check our
          for_downtime_information: for downtime information.
          system_status: System status
          search: Search...
          search_submit: Search
          visitor_id: Visitor ID
          last_seen: Last seen
          identities: Identities
          ago: ago
          now: Now
          delete: Delete
          user_online: User online
          if_you_would_like_to_delete_all_your_visitors_data: If you would like to
            delete all your visitors' data, please get in touch with our team!
      general:
        success: Team settings updated successfully! Some settings might take a few
          minutes to propagate.
        errors:
          permission_denied: You cannot change settings for this team
        co_browsing:
          title: General settings / Co-browsing
          agent_experience:
            title: Agent experience
            description: How your agents start a session.
            panel:
              title: Customize
              confirm_label: Confirm before starting session?
              true: 'Yes'
              false: 'No'
              confirm_description: Prevents an agent from mistakenly clicking on a
                link and asking the visitor to co-browse by confirming the agent's
                intention.
              button: Update
              multiple_agent_label: Allow multi agent sessions?
              multiple_agent_description: If turned on, an agent is able to share
                the current session by sharing the link they are on with another logged
                in agent, or by initiating a session with the same visitor. This also
                affects the REST API.
              feature_name_1: Sharing the session with team members
              notes_label: Enable agent notes?
              notes_description: Agents can leave notes while cobrowsing. These will
                be available in the list of sessions and pushed to our integrations.
              feature_name_2: Agent notes
              rating_label: Ask agents for a quality rating at the end of each Session?
              rating_description: Asking agents for a rating helps us improve our
                service.
            how_your_agents_start_a_session: How your agents start a session.
          functionality:
            title: Co-browsing functionalities
            description: Server locations and what actions you can perform on behalf
              of the users
            panel:
              title: Connection settings
              connection_label: Connection regions
              default_option: Always choose fastest region
              us_east_connection: United States only (east coast)
              us_west_connection: United States only (west coast)
              eu_connection: EU only
              uk_connection: United Kingdom only
              ca_connection: Canada only
              singapore_connection: Singapore only
              connection_recommendation: We recommend using our global network to
                ensure faster connections.
              update_button: Update
              allowed_label: Allowed functionalities
              setting_1: Remote control access level
              scroll: Scroll
              scroll_click: Scroll and click
              scroll_click_type: Scroll, click and type
              setting_1_description: Allow your agents to remotely scroll, click and
                type for the visitor.
              feature_1: Remote control
              feature_2: Remote typing
              setting_2: Prevent clicking or typing on these elements
              setting_2_description: Prevent remote clicking or typing on certain
                fields or areas of the page by adding a comma-separated list of CSS
                selectors here. You can mask elements entirely
              elements_placeholder: e.g. .accept-terms-and-conditions
              link: here
              false: 'No'
              true: 'Yes'
              none: None
              view_only: View only
              view_control: View and control
              setting_3: Allow agent to redirect or reload the page for the user?
              setting_3_description: Allow your agents to change the URL for the user,
                like you would on a regular browser.
              feature_name_redirect: Agent redirect and reload
              feature_name_console_control: Remote console control
              feature_name_console_access: Console access
              feature_name_audio: Audio calling
              setting_4: Browser console access level
              setting_4_description: For remote control to work, your agents will
                need the "Remote console" permission enabled.
              setting_5: Enable audio calls?
              call_prompt: Audio call prompt text
              call_placeholder: Would you like to join the voice call?
          recording:
            title: Recording
            description: Video recording of the session.
            panel:
              title: Customize
              label: Record sessions?
              true: 'Yes'
              false: 'No'
              explanation: Record sessions in mp4 format to play back later.
              feature_name: Session recording
              button: Submit
          user_authorization:
            title: Visitor authorization
            description: Making sure your visitor is happy with the co-browsing session.
            panel:
              title: Pre-session authorization
              label_require_auth: Require authorization from the visitor?
              require_auth_explain: Require your end visitor to allow co-browsing
                to start before the agent can access their screen. The visitor will
                see a message asking for authorization.
              true: 'Yes'
              yes_2: 'Yes'
              false: 'No'
              no_2: 'No'
              okay: Okay
              yes_quotes: '"Yes" button text'
              no_quotes: '"No" button text'
              okay_quotes: '"Okay" button text'
            panel_section:
              title: Authorization prompt translations
              label_prompt: Request prompt title
              prompt_placeholder: Co-browsing request
              label_request: Request text
              request_placeholder: Would you like to let {%agentName%|our agent} help
                you navigate {%currentDomain%}?
              request_explain_1: You can use
              code: default here
              request_explain_2: as a placeholder for the full name of the agent,
                and
              request_explain_first_name: as a placeholder for the first name of the
                agent, and
              request_explain_3: as a placeholder for the domain the visitor is on.
              first_name_label: Use first name for agent name?
              full_name_select: Use full name
              first_name_select: Use first name only
              custom_select: Use custom text
              custom_label: Custom label for Agent's cursor
              custom_placeholder: Agent
              button: Update
            panel_terminating:
              title: Terminating the session
              label_message: Enable “Screen sharing by Upscope” message?
              explain: A small text will be shown to the user explaining co-browsing
                is active, with a link to Upscope.
              label_terminate_button: Enable terminate session button?
              terminate_explain: A button will be shown to the user in the lower left
                corner while the session is active allowing them to terminate it.
              subfield_button_text: "“Stop screen sharing” button text"
              button_text_placeholder: Stop session
              end_message: End of screen share message
              no_message: "(No message)"
              no_message_explain: If left blank, no message will be shown at the end
                of the co-browsing session.
        back_button:
          link_title: General settings
          alert:
            message_1: You might have installed an old version of Upscope that doesn't
              support making changes through your dashboard.
            message_2: To make changes, please replace your existing installation
              code with the one you find here.
            message_customize: We have detected that you are customizing Upscope with
              our Javascript SDK.
            message_customize_2: Javascript settings will always override these options
              to allow you to customize Upscope for each page or section of your website.
            link: Upgrade code
        advanced:
          title: General settings / Advanced
          masking_panel:
            title: Element masking
            description: By masking elements you ensure no sensitive information touches
              our servers or your agent's eyes.
            label: Masked elements
            explain: We always automatically mask password fields, and fields with
              a CSS class %{code}. You can set a comma-separated list CSS selectors
              of fields you'd like to hide here.
            button: Update
          browser_proxy_panel:
            title: Browser proxying
            description: When testing Upscope locally, our servers cannot access your
              website content.
            description_2: You can specify a list of paths here you would like the
              browser to proxy. We recommend using this only when testing as it will
              slow down the co-browsing experience.
            label: Proxy these paths from browser
            button: Update
          secret_key_panel:
            title: Proxy secret key
            explain: Our proxy system will get CSS and images from your servers. You
              can identify our server through the user agent %{code}.
            explain_2: You can optionally set a secret key here so that only our server
              can access your assets. It will be sent as header %{code}.
            label: Proxy secret authentication key
            placeholder: "(none set)"
            button: Update
          autoconnect_panel:
            title: Autoconnect options
            description: By default, all visitors will initiate a websocket connection
              to our servers and are searchable on the list of visitors on Upscope.
            description_2: If you have a lot of visitors, to avoid getting rate limited
              by Upscope, you can opt to connect to our servers only visitors who
              ask for a lookup code, follow a co-browsing link, or open a chat conversation
              (this works with most of our integrations).
            description_3: You can also use our API to connect visitors manually through
              %{code}. You only need to do it once per user.
            label: Connect all visitors
            select_yes: Yes, connect everybody
            select_no: No, only connect visitors that need help
          callback_panel:
            title: Session callback
            description: Upscope will make a POST request to the following URL after
              a session end. It will include Session information in the request body.
            label: Session webhook url
            placeholder: "(none set)"
            button: Update
        history_collection:
          title: General settings / History and Screenshots
          alert: Before you see screenshots, you'll need to follow the instructions
            you find below.
          description: History collection allows your agents to quickly see what the
            visitor was doing before starting a chat conversation. This is visible
            in the Upscope sidebar in most of our integrations.
          instructions: Read instructions for
          support: We currently support
          and: and
          panel:
            title: Customize
            label: Collect history and take screenshots?
            select_yes: 'Yes'
            select_no: 'No'
            feature_name: History collection
            button: Update
        agent_request_button:
          title: General settings / Agent request button
          feature_name: The agent request button
          description_1: Show a button on your pages that allows visitors to call
            an agent when they are available on Upscope.
          description_2: The agent will be notified with a sound and can help the
            user in seconds.
          description_3: about this feature.
          link: Learn more
          panel:
            title: When to show
            show: Show agent request button
            never: Never (disable feature)
            when_available: When agent is available
            always: Always show button
            button: Update
            explain: Show a button to request agent help. The agent will be notified
              by a sound if they are on the Upscope app.
            subfield_1:
              label: Only show on these pages
              placeholder: Show on all pages
              explain: Add a comma-separated list of pages to show the button on.
                You can use %{code} as a wildcard for the page url. Leave blank to
                show on all pages.
            subfield_2:
              label: Position of the request agent button
              explain: Use this to move the request agent button so that it does not
                conflict with other design elements.
          panel_behavior:
            title: Behavior
            panel_section_button:
              title: Button text
              description: These are the messages shown on the button to indicate
                the status of the request.
              label_1: Title, before the button is clicked
              placeholder_1: Request agent help
              label_2: Subtitle, before the button is clicked
              placeholder_2: An agent can screen share with you now
              label_3: Title, after the button is clicked
              placeholder_3: Looking for an available agent...
              label_4: Subtitle, after the button is clicked
              placeholder_4: Click to cancel request
            panel_section_messages:
              title: Messages
              description: These are alert-style messages to inform the visitor of
                the result of the request.
              label_1: Alert title
              placeholder_1: Agent request
              label_2: Accepted message
              placeholder_2: An agent will screen share with you shortly. Please stay
                on this page.
              label_3: Agent unavailable message
              placeholder_3: An agent is not currently available. Please try again
                later.
            panel_section_redirects:
              title: Redirects
              description: If the agent is not available, you can redirect the user
                to a different page (e.g. booking form).
              label: When unavailable, redirect to...
              placeholder: "(No redirect)"
              explain: Leave blank to not redirect the user anywhere.
        lookup_code:
          title: General settings / Lookup code
          description: Add a 4-digit code to your pages so that your agents can quickly
            identify visitors while talking with them on the phone.
          html_element: You can show the code in a pre-built button, select an HTML
            element to put the code in, or you can ask the user to press %{code} 5
            times on their keyboard to see the code.
          panel:
            title: Customize
            label: Show lookup code?
            select_widget: Yes, in the widget button
            select_html: Yes, in an HTML element
            select_keyboard: Yes, when the user presses Control 5 times
            select_disable: No, do not show
            explain_widget: A small button will show up on the right side of the page
              with a code that will enable you to quickly search for the visitor in
              Upscope.
            explain_html: We will replace the content of the element you choose below
              with the four digit code.
            explain_keyboard: An alert will be shown to the user with the lookup code
              when they press %{code} on the keyboard 5 times.
            explain_disable: Show the four digit code in a pre-built button or any
              HTML element on the page.
            subfield_1:
              label: Only show on these pages
              placeholder: Show on all pages
              explain: Add a comma-separated list of pages to show the button on.
                You can use %{code} as a wildcard for the page url. Leave blank to
                show on all pages.
            subfield_2:
              label: Position of the lookup code button
              explain: Use this to move the lookup code button so that it does not
                conflict with other design elements.
            subfield_3:
              label: CSS Selector of the target element
              explain: Add the CSS selector of the element you would like Upscope
                to replace the content of with the lookup code.
            subfield_4:
              label: Title of the alert dialog
              placeholder: Co-browsing access code
              explain: Use %{code} as a placeholder for the lookup code.
            subfield_5:
              label: Message of the alert dialog
              placeholder: 'Please give the agent this code: {%lookupCode%}'
              explain: Use %{code} as a placeholder for the lookup code.
            label_lookup_code: Force sessions to start with the lookup code?
            select_yes: 'Yes'
            select_no: 'No'
            explain: Setting this to yes will remove the list of visitors and force
              agents to enter a lookup code. Integrations will still work as normal.
            button: Update
        personal_link:
          title: General settings / Personal co-browsing link
          alert_error: Personal links require proxy to be enabled. Please
          link: enable it now.
          alert_warning: Personal links are not available for on-premise.
          explain: Agents can find their personal co-browsing links underneath the
            list of users on the homepage. They can use this link in calendar invites
            to jump right into a co-browsing session.
          learn_more_link: Learn more
          link_explain: about this feature.
          panel:
            title: Customize
            label_1: Calls start page
            explain_1: When a user follows an agent's link they will be redirected
              to this link to start the co-browsing session. If left empty, they'll
              be taken to a holding page until your agent shows up.
            label_2: Wait message
            placeholder: Please stay on this page. Our agent will call you here shortly.
            button: Update
        proxy:
          title: General settings / Proxy & third party websites
          alert_warning: Proxy settings are not available for on-premise.
          alert_error: Proxy is currently not yet available in your region. Please
          alert_error_link: allow all regions,
          alert_error_2: or try again later.
          link: Installing Upscope
          explain: Allow your agents to continue cobrowsing even outside of your own
            website by proxying third party sites.
          explain_2: directly on the pages you control is always preferable, as the
            user doesn't need to leave the website.
          panel:
            title: Customize
            label: Allow proxying third party websites?
            select_yes: 'Yes'
            select_no: 'No'
            proxy_enabled: The session will continue even if the user leaves the page
              Upscope is installed on.
            proxy_disabled: Upscope sessions will be limited to pages where you have
              installed our Javascript SDK.
            subfield:
              label: Automatically change external links?
              true: 'Yes'
              false: 'No'
              external_link_rewrite: Upscope will try to send the user to our proxy
                if they leave your website.
              external_link_rewrite_false: You will need to manually hard-code the
                Upscope proxy for the session to continue if the user follows an external
                link.
              button: Update
        team:
          title: General settings / Team
          alert_warning: Because you do not have the manage users permission, you
            can only see this settings.
          panel:
            title: Joining the team
            label: Allow @%{team_domain} email holders to sign up?
            select_yes: 'Yes'
            select_no: No - invite team members manually
            explain: If this is set to <b>yes</b> anyone with an @%{team_domain} email
              address will be able to join your team by visiting
            button: Update
          panel_authentication:
            title: User Authentication
            label_1: Allow users to set password?
            label_2: Require users to set up MFA?
            label_3: Log agents out of Upscope after
            label_4: or after
            select_yes: 'Yes'
            select_no: 'No'
            select_no_require: No - require email sign in
            explain: If this is set to <b>no</b>, members will need to log in by email
              every time.
            please: Please
            link: add your own MFA device
            explain_mfa: before requiring other users to do the same.
            select_1_week: 1 weeks
            select_2_weeks: 2 weeks
            select_1_month: 1 month
            select_2_months: 2 months
            select_6_months: 6 months
            select_24_hours: 24 hours of inactivity
            select_7_days: 7 days of inactivity
            select_2_weeks_inactive: 2 weeks of inactivity
            select_1_month_inactive: 1 month of inactivity
            select_2_months_inactive: 2 months of inactivity
            explain_multi_teams: If a user is part of multiple teams, the smallest
              value will be used.
            button: Update
          panel_admin:
            title: Admin permissions
            label_1: Allow admins to reset user's passwords?
            label_2: Allow admins to reset user's MFA devices?
            select_yes: 'Yes'
            select_no: 'No'
            button: Update
          panel_email:
            title: Email settings
            label: Unsubscribe from emails
            error_explain: If set to yes, all your team members will stop receiving
              the newsletter from Upscope.
            button: Update
          panel_language:
            title: Language settings
            label: Language
            error_explain: Change the language of the Upscope interface.
            button: Update
          panel_saml:
            title: SAML Settings
            label: Enable SAML-based SSO?
            select_yes: 'Yes'
            select_no: 'No'
            error_explain: If set to yes, all your team members will need to sign
              in through SAML or Oauth integrations.
            feature_name: Single Sign On
            subfield:
              label_exclude: Exclude root user
              you: you
              label_exclude_2: from SAML SSO requirement?
              select_yes_password: Yes, let them sign in by password
              select_no_saml: No, force them to sign in through SAML SSO
              label_2: Automatically provision new SAML users?
              select_yes: Yes, create new users automatically
              select_no: No, require all team members to be invited individually
              label_3: IDP Metadata URL
              label_4: IDP Metadata XML
            button: Update
          panel_identity:
            title: Configuration for your Identity Provider
            explain: Use the following URLS to configure your SAML integration.
            label_1: SAML Consumer URL
            label_2: SAML Single Logout URL
            label_3: SAML Entity Id
      integrations:
        new:
          success: "%{integration} integration successfully set up"
        delete:
          success: Integration removed
        front:
          new:
            title: Connect to Front
            description: Connect Upscope to your Frontapp to start a session directly
              from a Frontapp chat.
            instruction_1: 1. Go to your Frontapp account
            instruction_1_explain: Install Upscope on the Frontapp integrations.
            link: Open Frontapp Integrations
            instruction_2: 2. Go to Settings
            instruction_2_explain: Click on Enabled and copy the Secret Key below.
            label: Front secret key
            button: Done
        index:
          index:
            title: Integrations
            panel:
              title: Add a new integration
              alert_error: There was a problem with your authentication. Please try
                again.
              alert_success: Your integration has been successfully added
              explain: You can connect Upscope with one of our partners below to enable
                SSO and additional functions.
              panel_section:
                title: Connect to...
            panel_integrations:
              title: Your integrations
        intercom:
          sso_on:
            success: Single sign on turned on
          sso_off:
            success: Single sign on turned off
          update_settings:
            success: Integration settings updated!
          settings:
            back_to_integrations: Back to Integrations
            add_session_summary_to_intercom: Add session summary to intercom conversation
              as a note?
            true: 'Yes'
            false: 'No'
            intercom_session_summary: Intercom session summary
            permission_prompt: Permission prompt
            permission_prompt_can_be_added_within_the_chat: The permission prompt
              can be added within the chat interface to ask the visitor for permission
              before initiating a session.
            prompt_title: Prompt title
            prompt_message: Prompt message
            accept_button: Accept button
            refuse_button: Refuse button
            prompt_explanation: Prompt explanation
            co_browsing_start_message: Co-browsing start message
            update: Update
            title: "%{name} settings"
        salesforce:
          sso_on:
            success: Single sign on turned on
          sso_off:
            success: Single sign on turned off
          update_settings:
            success: Integration settings updated!
          settings:
            name_settings: "%{name} settings"
            back_to_integrations: Back to Integrations
            create_an_object_in_salesforce_: Create an object in Salesforce from the
              session?
            true: 'Yes'
            false: 'No'
            you_can_map_fields: You can map fields for the call by changing the default
              here.
            please_contact_our_team: 'Please contact our team for assistance. You
              can use the following special values:'
            field_mapping: Field mapping
            update: Update
            embed_upscope_in_salesforce: Embed Upscope in Salesforce
            you_can_create_a_button: You can create a button in your Case view on
              Salesforce to start a session with one click and log it as a call.
            code_for_visualforce_page: Code for visualforce page
            embed_upscope_into_salesforce: 'To embed Upscope into Salesforce, use
              the following code:'
        zapier:
          show:
            connect_to_zapier: Connect to Zapier
            go_to_zapier: 1. Go to Zapier
            go_to_the_zapier_app: Go to the Zapier App and look for the Upscope connection.
            use_connection_key: 2. Use connection key
            connection_key: When prompted, enter the following connection key on the
              Zapier connection flow.
            done: Done
        twilio_flex:
          show:
            connect_to_twilio_flex: Connect to Twilio Flex
            done: Done
        zendesk:
          show:
            connect_to_zendesk: Connect to Zendesk
            connect_upscope_to_your_zendesk: Connect Upscope to your Zendesk to start
              a session directly from a Zendesk chat or a Zendesk ticket.
            install_the_zendesk_app: 1. Install the Zendesk App
            zendesk_marketplace: Find Upscope on the Zendesk Marketplace and install
              Upscope for chat and / or support.
            open_zendesk_marketplace: Open Zendesk Marketplace
            add_the_token_below: 2. Add the token below
            when_asked_for_a_token: When asked for a token on the Zendesk website,
              copy and paste the one below and complete the installation.
            done: Done
        freshchat:
          show:
            title: Connect to Freshchat
            connect_to_freshchat: Connect Upscope to your Freshchat to start a session
              directly from a Freshchat chat.
            install_upscope_app: 1. Install the Upscope App
            freshchat_marketplace: Find Upscope on the Freshchat Marketplace and install
              Upscope for chat.
            open_freshchat_marketplace: Open Freshchat Marketplace
            add_the_token_below: 2. Add the token below
            when_asked_for_a_token: When asked for a token on the Freshchat website,
              copy and paste the one below and complete the installation.
            create_custom_property: 3. Create the upscope screenshare custom property
            done: Done
            freshchat_instructions_html: Within Freshworks go to your <strong>Admin
              Settings » Contacts</strong>, under Basic information create a new text
              field called <code class='code'>upscope_screenshare</code>
        google_oauth2:
          sso_on:
            success: Single sign on turned on
          sso_off:
            success: Single sign on turned off
      invites:
        errors:
          cannot_grant_permission: You cannot grant a permission you don't hold yourself!
          permission_denied: You cannot manage users for this team
      leave:
        success: You have successfully left %{domain}!
        errors:
          user_not_owner: You cannot leave a team you are the owner of
          user_owner: You cannot remove a team you are not the owner of
        show:
          leave_domain: Leave %{domain}
          to_leave_explain: To leave %{domain}, please click the button below. Leaving
            is immediate,
          but_you_ll_be_able_to_join_the_team_again: but you'll be able to join the
            team again with your @%{domain} email address.
          ask_an_administrator_for_invitation: and you'll need to ask an administrator
            for an invite to join again.
          your_personal_account_will_not_be_deleted: Your personal account will not
            be deleted in this process, and you will remain a member of your other
            teams.
          leave: Leave %{domain}
          leaving_will_also_delete_your_personal: Leaving the team will also delete
            your personal Upscope account.
          leave_domain_and_delete_account: Leave %{domain} and delete account
          are_you_sure: Are you sure?
      members:
        errors:
          member_owner_of: The owner's permissions cannot be changed
          member_current_user: You cannot change your own permissions
          cannot_grant_permission: You cannot grant a permission you don't hold yourself!
          not_allowed_to_disable_password: You are not allowed to remotely disable
            passwords
          not_allowed_to_disable_mfa: You are not allowed to remotely disable MFA
          permission_denied: You cannot manage users for this team
          member_not_found: Member not found
        partials:
          alerts:
            until_you_add_a_payment_method: Until you add a payment method, all your
              team members will be able to purchase. When they do, they'll automatically
              gain the billing permission.
            anyone: Anyone
            with_an_account_in_your: with an account in your
            or: or
            with_an: with an
            email_address: email address
            with_an_account_in_your_sso_provider: with an account in your SSO provider
            will_be_able_to_join_your_team: will be able to join your team. The only
              permissions enabled will be co-browsing and user list.
            link_for_people_to_join_the_team: 'You can give this link for people to
              join the team:'
        index:
          team_members: Team members
          user_roles: User roles
      new:
        new:
          title: New team
          new_team_explain: To create a new team, please choose a subdomain to associate
            it with. You can change domain by updating your email.
          new_team_explain2: The domain or subdomain does not need to exist or match
            the website you install Upscope on.
          form:
            team_domain: Team domain
            submit: Create team
            errors: This domain
            errors_explain: Leave the field empty for root domain (%{email_domain}).
        errors:
          user_from_oauth: OAuth users cannot create new teams
        success: New team created!
      on_premise:
        errors:
          permission_denied: You cannot change settings for this team
          team_not_on_premise: Your team does not have on premise permission
        success: Settings updated
        show:
          on_premise: On premise
          instructions: Instructions
          instructions_on_how_to_use_upscope: 'You''ll find instructions on how to
            use Upscope on-premise here:'
          download_information: Download information
          feel_free_to_use_this_link: Feel free to use this link in any automated
            deployment process you have, to avoid having to replace the license key
            manually.
          download_username: Download Username
          download_password: Download Password
          license_key_url: License key URL
          cloud_link: Cloud link
          only_need_to_change_these_settings_if: You only need to change these settings
            if you want to make use of our authentication system and the user list
            on our dashboard.
          base_endpoint: Base endpoint
          the_base_url: The base URL where the on premise endpoint is hosted.
          this_will_be_the_same_as_your_code: This will be the same as your <code
            class="code">SECRET_KEY</code> env var.
          security_alerts_notifications_email: Security alerts notifications email
          add_one_or_more_emails: Add one or more emails (separated by comma) to receive
            security alerts.
          update: Update
          replace_explain: 'Replace the <code class=''code''>Upscope(''init'');</code>
            function with <code class=''code''>Upscope(''init'', {apiKey: ''%{api_key}''});</code>
            to ensure the user list works.'
          to_download_the_binaries_please_use_the_credential: 'To download the binaries,
            please use the credentials below at this link:'
      rest_keys:
        errors:
          permission_denied: You cannot change settings for this team
        destroy_success: Rest Key deleted successfully
        index:
          api_key_settings: API key settings
          general_settings: General settings
          rest_api_access: REST API access
      transfer_ownership:
        errors:
          member_not_found: Member not found
          permission_denied: You cannot transfer ownership for this team
        index:
          transfer_team_ownership: Transfer team ownership
    personal:
      update:
        errors:
          use_is_admin: Admins cannot change their email.
        email_change_email: We have sent an email to %{email} to confirm the email
          change.
        success: Settings saved!
      remote_log_out:
        success: You have successfully been logged out of %{device}
      change_password:
        errors:
          user_from_oauth: OAuth users cannot have passwords!
        success: Please check your email to enable password
      disable_password:
        success: Password disabled successfully
      disable_mfa:
        success: We have sent you an email to disable your MFA
      show:
        title: My personal settings
      partials:
        your_password:
          disable: Disable password
          disable_confirm: Are you sure you want to disable your password?
          panel:
            your_password:
              title: Your password
              form:
                success_description: You currently have a password set to access your
                  account. You can update it below, or disable it and log in via email.
                warning_description: Your password is not active yet! Please follow
                  the link in the email you received to enable it. If the link has
                  expired, you can set the password again below.
                cancel_request: Cancel request
                password_enabled_description_html: "<p>You can set a password to log
                  into Upscope without having to check your email. The password will
                  be reset after 3 failed attempts.</p> <p>After you set a password,
                  we'll send you an email to confirm you want to enable it.</p>"
                label_password: New password
                update: Update password
                set_password: Set password
                error_password: Your password
                error_password_explain: At least 8 characters long, must include letters
                  and numbers or symbols.
        your_current_sessions:
          panel:
            your_current_sessions:
              title: Your current sessions
              log_out_confirm: Are you sure? You will be logged out of this device.
              this_device: This device
              device: Device
              last_seen: Last seen
              admin_mfa: Admin MFA
              mfa: MFA
        personal_link_settings:
          title: Your personal link
          form:
            description: Send your personal co-browsing to visitors to be notified
              when they show up and start cobrowsing immediately.
            label_username: Your personal co-browsing link
            error_username: This username
            error_username_explain: Please only use numbers and letters.
            label_call_agent_notification: Email notifications
            select:
              never: Never notify me by email
              if_not_answered: Email me if a user is waiting for more than 2 minutes
              immediately: Email me as soon as a user shows up at my link
            submit: Save personal link settings
        personal_form:
          title: Personal
          form:
            label_name: Your first and last name
            error_name: Your name
            label_email: Your email
            error_email: Your email
            label_phone_number: Your phone number
            label_subscribed_to_emails: Do you want to receive onboarding and product
              updates emails from Upscope?
            error_phone_number: Your phone number
            error_phone_explain: Please include your country code if outside of the
              United States.
            submit: Save profile
        multifactor:
          disable: Disable MFA
          disable_confirm: Are you sure? Disabling your MFA will weaken your account's
            security
          panel:
            multifactor:
              title: Multi factor authentication
              mfa_enabled: You currently have MFA enabled, and will need your device
                to log into Upscope.
              mfa_disabled: You currently do not have MFA (Multi factor authentication)
                enabled, which means anyone with access to your email account will
                be able to access Upscope.
              enable: Enable MFA
        alerts:
          oauth: Please update your details in %{integration_name} to change your
            details here.
          mfa_disable_pending: Your MFA has not been disabled yet! You will receive
            an email with a link to disable it.
          mfa_disable_complete: Your MFA device has been disabled.
          email_change_to_html: "<p>Your email hasn't changed yet! We have sent an
            email to <b>%{current_user_email}</b> to confirm the change to <b>%{email_change_to}</b>.</p>
            <p>If you no longer have access to <b>%{current_user_email}</b>, please
            get in touch with our team.</p>"
          email_to_verify_html: "<p>Your email hasn't changed yet! We are sending
            an email to <b>%{email_to_verify}</b> to verify the email is correct.</p>
            <p>Please click the link in the email to complete the change.</p>"
          email_change_complete_html: Your email has now been changed to <b>%{current_user_email}</b>!
            Thank you for confirming!
  sidebar:
    menu: Menu
    admin: Admin
    personal: Personal
    manage_user: Manage user
    manage_team: Manage team
    global_activity_log: Global activity log
    global_session_history: Global session history
    new_account: New account
    quotes: Quotes
    my_settings: My settings
    beta_features: Beta features
    on_premise_settings: On premise settings
    installation_instructions: Installation instructions
    general_settings: General settings
    integrations: Integrations
    team_members: Team members
    activity_log: Activity log
    session_history: Session history
    usage: Usage
    billing: Billing
    call_credit: Call credit
    delete_visitor_data: Delete visitor data
    leave_team: Leave team
    delete_team: Delete team
    create_new_team: Create new team
    new_team: New team
  auth:
    saml:
      errors:
        team_not_found: Team not found
        saml_disabled: This team does not have SAML SSO enabled
        invalid_configuration: The SAML configuration is no longer valid
      consume:
        errors:
          already_associated_to_another_user: This email is already associated to
            another user
          invalid_saml: Invalid SAML authentication
          ask_admin_to_invite: Please ask your admin to invite you to Upscope!
        log_in_success: Welcome back!
        join_team_success: Welcome to Upscope!
      logout:
        errors:
          invalid_logout: Invalid log out request
    ask_to_join_team:
      create:
        errors:
          missing_team: Could not find team
        success: We have asked your team manager to invite you.
      show_instructions:
        title: Join your colleagues
        wait_invite_email: Please wait for the invite email.
        wait_invite_email_info: We have asked the team manager to invite you. You'll
          get your invite by email.
    embeded:
      login:
        title: Please log in
        info: Please log in to continue with Upscope.
    invites:
      errors:
        already_member_of_team: You are already a member of %{team_domain}
        missing_invite: We could not find your invite
        invite_expired: This invite has expired. Please ask to be invited again
      accept:
        success: You have successfully joined %{team_domain}!
      reject:
        success: Invite refused successfully!
      show:
        title: Join
        accept: Accept
        decline: Decline
        invite_description:
          part_1: You have been invited to become part of %{domain}.
          part_2: Joining %{domain} does not affect your membership to any other team.
    join_team:
      create:
        errors:
          missing_email: An email is required
          auto_sign_up_not_allowed: This team does not allow auto-sign up
          email_and_domain_mismatch: Email and domain do not match
        success: Check your email for your invite!
      errors:
        team_not_found: Could not find team
        invite_required: Please ask for an invite
      new:
        title: Welcome to Upscope
        page_description: Join %{domain} on Upscope
        contact_admin: Please contact your admin
        central_message: This team does not allow people to sign up with their email.
          Please ask your administrator to invite you to Upscope!
        join_sso: Join through SSO
        join_sso_info: Use your SSO account to join Upscope now!
        log_in_sso: Log in with SSO
        join_email: Join by email
        join_email_description:
          part_1: All you need to join your team is provide a
          part_2: email to verify you're part of the team!
        form:
          label_email: Your email
          submit: Join team
        join_integration: Join via an integration
        join_integration_description: Use your %{integrations} account to access Upscope
          in two clicks.
        login_with: Log in with %{short_name}
        terms_of_service: Terms of Service
        legalese: By submitting this form, you agree to be bound by our
    mfa:
      check:
        success: Thank you for checking your MFA!
        errors:
          invalid_code: The code entered is not valid. Please scan the code again.
      enable:
        success: MFA enabled successfully!
        errors:
          invalid_code: The code entered is not valid. Please scan the code again.
          user_is_oauth: An OAuth user cannot use MFA.
      show:
        title: Check MFA
        description: You will need to generate a MFA code with your Authenticator
          app to complete logging in.
        form:
          label_code: Generated code
          submit: Check MFA
        lost_mfa_description: If you have lost your MFA device, please ask your admin
          to reset it for you, or get in touch with us.
      show_setup:
        title: Enable MFA
        description: By using Multi Factor Authentication, you'll ensure that even
          if someone gains access to your email account, they won't be able to log
          into your Upscope account.
        step_1: 1. Install Google Authenticator
        step_1_description: To get started download Google Authenticator, or a compatible
          app on your mobile device.
        step_2: 2. Scan this QR code
        step_2_description: Scan this code with the device to generate a code.
        step_3: 3. Enter the code below
        form:
          label_code: Generated code
        no_thank_you: No thank you
        activate_mfa: Activate MFA
    set_password:
      update:
        password_success: Password set!
        email_success: We have sent you an email to confirm the password!
      show:
        title: Set a password
        page_description: Setting a password will allow you to log in faster next
          time without having to wait for a log in email.
        form:
          label_password: Your password
          password_explain: At least 8 characters long, must include letters and numbers
            or symbols.
          dont_set: Don't set
          submit: Set password
    sign_up:
      ask_to_join_or_create_team:
        title: Join your colleagues
        there: there
        greeting: Hi %{name},
        team_already_on_upscope: Your team is already on Upscope, but you need an
          invite to join your colleagues.
        join_or_create_team: Do you want to ask to join the existing team or create
          a new one?
        form:
          create_new_team: Create new team
          ask_to_join: Ask to join team
      join_or_create_team:
        title: Join your colleagues
        greeting: Hi %{name},
        team_already_on_upscope: Your team is already on Upscope, and because you
          have a @%{domain} email, you can join the team without an invite.
        join_or_create_team: Do you want to join the existing team or create a new
          one?
        form:
          create_new_team: Create new team
          join_existing_team: Join existing team
      new:
        form:
          label_name: Your name
          label_email: Your email
          label_phone_number: Your phone number
          phone_number_explain: Please include your country code if outside of the
            United States.
          sign_up_now: Sign up now
        legalese: By submitting this form, you agree to be bound by our
        terms_of_service: Terms of Service
      create:
        success: Check your email to complete your sign up
        invite_sign_up_success: Welcome to Upscope, %{name}
      oauth_back:
        errors:
          oauth_error: Oauth error. Please sign up by email
        success: Welcome to Upscope, %{name}
      show_instructions:
        check_email: Please check your email.
        thank_you: Thank you for signing up for Upscope! Please check your email for
          a link to activate your account.
    token:
      not_found:
        title: Link Not Found
        central_message:
          title: Link Not Found
          message: The link you followed may have expired, try requesting it again.
      confirm:
        cancel: Cancel
        delete_now: Delete now
        delete_team:
          title: Delete %{domain}?
          description: You are about to delete %{domain}.
          confirm_info: Confirm this action below or cancel to continue using Upscope.
        delete_all_visitors:
          title: Delete %{domain}'s visitors data?
          description: You are about to delete all visitors data for %{domain}.
          confirm: Confirm this action below or cancel to keep the data.
        invite:
          title: Invite %{name} to %{domain}?
          description: Are you sure you want to invite %{name} (with email %{email})
            to your %{domain} team?
          send: Send invite
        errors:
          already_logged_in: You are already logged in
          wrong_link: This link was meant for someone else
      process_token:
        errors:
          already_logged_in: You are already logged in
          wrong_link: This link was meant for someone else
      delete:
        success: No changes made!
    oauth:
      errors:
        missing_team: Team missing
        provider_not_supported: Provider not supported
        intent_not_recognized: Intent not recognized
        needs_redirect: Needs redirect location
        internal_error: Internal error, please try again
  admin_sessions_list_widget:
    team: Team
    started_at: Started at
    length: Length
    features: Features
    agent: Agent
    visitor: Visitor
    from_source: From %{source}
    call_length: "%{call_length} audio"
    debug: Debug
    on_premise_tooltip:
      title: On premise
      text: This session happened on premise.
    ongoing_tooltip:
      title: Ongoing
      text: This session is active.
    session_refused_tooltip:
      title: Session refused
      text: The user never accepted the co-browsing session request.
    session_never_started_tooltip:
      title: Session never started
      text: The session never started. This is most likely due to the visitor going
        offline.
    session_ended_tooltip:
      title: Ended
      text: This session has now ended.
    remote_cursor_tooltip:
      text: Used remote cursor feature
    pointer_tooltip:
      text: Used pointer feature
    drawing_tooltip:
      text: Used drawing
    audio_call_tooltip:
      text: Used audio call
    remote_scroll_tooltip:
      text: Used remote scroll
    remote_click_tooltip:
      text: Used remote click
    remote_type_tooltip:
      text: Used remote type
    remote_console_tooltip:
      text: Used remote console
    universal_proxy_tooltip:
      text: Used universal proxy
    agent_notes_tooltip:
      text: Wrote agent notes
  activity_log_widget:
    activity_log_empty: Your activity log is empty.
    team: Team
    time: Time
    category: Category
    description: Description
    user: User
    upscope_admin: Upscope admin
    acting_on_behalf: acting on behalf of
    from: from
    removed: Removed
    load_more: Load more
  admin_users_widget:
    autocomplete_input:
      placeholder: The email or the user id of the user you want to manage
      label: Search for user
  admin_teams_widget:
    autocomplete_input:
      label: Search for team
      placeholder: The domain or the id of the team you want to manage
    panel:
      recently_online_teams:
        title: Recently online
      newest_teams:
        title: Newest teams
  invite_team_widget:
    success: has been sent an invite!
    error: There was a problem with the invite. Please try again!
    form:
      label: Team member's email
      submit: Invite
      continue_without_invites: I'll do this later
    invites:
      revoke: Revoke
      sending: Sending...
      invited: Invited
      continue: Continue
  compare_plans_widget:
    pay_yearly: pay yearly
    pay_monthly: pay monthly
    compare_plans: Compare plans
    plan_features:
      see_and_draw: See and draw on the user screen
      click_scroll_and_type: Click, scroll and type for the user
      integrate_with_existing_support: Integrate with your existing support tools
        and workflow
      co_browse_on_thirdy_party_websites: Co-browse on third party websites
      share_pdf: Share PDF documents with your customers
      advanced_user_management: Advanced user management
      live_chat_support: Live chat support
      everything_in_upscope: Everything in Upscope
      sla_nda_and_uptime_guarantee: SLA, NDAs and uptime guarantees
      optional_on_premise_deployment: Optional on-premise deployment
      rest_api: REST API
      whitelabel_support: Whitelabel support
      dedicated_account_manager: Dedicated Account Manager
      agent_and_admin_training: Agent and admin training
      technical_support: Technical support
      twenty_four_seven_support: 24/7 emergency phone line
      screenshots:
        description: See <tooltip>screenshots</tooltip> in your live chat
        tooltip:
          screenshots: screenshots
          text: See what the customer was doing before they initiated a live chat
            conversation with you!
      plan_comparison:
        co_browsing_features: Co-browsing features
        see_the_user_screen: See the user screen
        draw_on_the_user_screen: Draw on the user screen
        remote_control: Click and scroll for the user
        remote_typing: Type for the user
        agent_redirect: Control the user's address bar
        third_party_proxy: Co-browse on third party websites
        audio_calls: Browser-to-browser audio calls
        custom_audio_calls: Custom audio call pricing based on deployment type
        audio_calls_not_available: Browser-to-browser audio calls not available
        free_call_credit_per_license: "%{monthly_free_call_credit_per_license} hrs
          audio credit / agent / month included"
        no_audio_credit_included: No audio credit included
        audio_credit_tooltip:
          title: Audio calls
          text: Audio calls are priced %{monthly_price}1.5 / hour. Unused credits
            roll over. Regular co-browsing is unlimited.
        console_access: View browser console
        console_control: Interact with the browser console
        starting_a_session: Starting a session
        live_chat_integrations: Live chat integrations
        history_collection: See screenshots and history in integrations
        intercom_session_summary: Add session summary to intercom conversation
        personal_agent_co_browsing_link: Personal agent co-browsing link
        agent_request_button: Agent request button
        lookup_code: 4-digit quick phone lookup code
        team_features: Team features
        one_owner_unlimited_agents: Have one owner and unlimited agents
        remote_logout_and_password_reset: Remote log out and password reset
        team_session_sharing: Agent session sharing and escalation
        user_roles: Assign specific roles to agents
        sso: Single Sign On
        reporting_and_analytics: Reporting and analytics
        reporting: Day-by-day usage breakdown
        activity_log: Detailed audit log
        sessions_list: Session history
        session_recording: Session video recordings
        agent_notes: Agents can leave notes about the session
        customization: Customization
        translate_everything_into_your_own_language: Translate everything into your
          language
        disable_advert: Remove Upscope branding for end user
        zapier_integration: Zapier integration
        salesforce_integration: Salesforce integration
        remove_upscope_branding_for_agent: Remove Upscope branding for agent
        enterprise_features: Enterprise features
        on_premise_deployment: On-premise deployment
        sla_and_uptime_guarantee: SLA and Uptime guarantees
        api_access: API access
        javascript_api: Javascript API
        rest_api: REST API
        technical_help: Technical help
        support: Support
        email_support: Email support
        live_support: Live chat support
        emergency_line: 24/7 emergency line
    plan_card:
      month: month
      popular: Popular
      minimum_charge_per_period: Minimum charge of %{price} per %{period}.
      contact_sales: Contact sales
      your_current_plan: Your current plan
      prompt:
        switch: Switch
        start_trial: Start 14-day free trial
        upgrade: Upgrade
        custom: Custom
      enterprise:
        description: Whitelist Upscope or build it into your existing system with
          advanced APIs.
        min_agents: 30 min agents
        tooltip: Minimum charge of $10,000 per year.
      upscope:
        description: Every Upscope feature your company needs to co-browse with your
          customers.
  merge_team_widget:
    confirm: Are you sure you want to merge these teams? The operation is irreversible.
    failed_to_merge: Failed to merge team
    merge_company_link: "(want to merge the entire company instead?)"
    merge: Merge
    teams_checkbox:
      select_teams: Select the teams you want to merge into
      change_team_link: Change Team
    panel:
      title: Merge Team
      description: Search for the team you want to merge this team into.
    autocomplete_input:
      label: Select the new main team
      placeholder: Search for a team
      submit: Select
  merge_company_widget:
    confirm: Are you sure you want to merge these companies? The operation is irreversible.
    failed_to_merge: Failed to merge company
    panel:
      title: Merge Company
      description: Search for the company you want to merge <bold>%{domain}</bold>
        with
    autocomplete_input:
      label: Select the company
      placeholder: Search for a company
      submit: Merge
  onboarding:
    install:
      partials:
        faq:
          panel:
            title: Frequently Asked Questions
            why_install_this_code:
              title: Why am I installing this code?
              description_1: This code allows Upscope to enable co-browsing on all
                pages it is installed on.
              description_2: The code will not affect your website in any way and
                no user will notice anything until an agent starts co-browsing with
                them.
              description_3: Co-browsing is a screen sharing solution built in javascript.
            can_upscope_be_used_without_javascript:
              title: Can Upscope be used without javascript installation?
              description_1: Yes, you can use Upscope without installing it by making
                use of our proxy.
              description_2: Adding the javascript code to your site will allow you
                to co-browse with any online user without them needing to reload the
                page or go to a different website.
            will_this_slow_my_website:
              title: Will this slow down my website?
              description_1: Upscope is loaded after everything else on your website
                so it will not slow anything down for your users.
              description_2: No data is transferred until a co-browsing session is
                initiated.
            is_upscope_secure:
              title: Is Upscope secure?
              description_1: Upscope is used in production safely by large enterprises
                and financial institutions around the world.
              description_2: You can learn more about our security at
            can_upscope_handle_my_traffic:
              title: Can Upscope handle my website's traffic?
              description_1: Upscope regularly sees websites with several thousands
                active users online.
              description_2: There is automatic throttling in place and a limit of
                5 thousand connected users per account. If you need the limit to be
                increased please contact us.
              description_3: If your website hits our limits we'll get in touch and
                let you know.
            where_do_i_install_upscope:
              title: Where do I install Upscope?
              description_1: You will need to add Upscope to all pages you want co-browsing
                to be available on.
              description_2: You can paste the code anywhere on the page.
            install_with_gtm_or_segment:
              title: Can I install Upscope with Google Tag Manager or Segment?
              description_1: We recommend adding the code directly on the webpage
                as it will guarantee Upscope doesn't get blocked by ad-blocking extensions
                and it will make the experience faster.
              description_2: However, if you don't have access to the webpage code
                and want to quickly test Upscope, you can install it through
              in_just_a_few_minutes: in just a few minutes.
            where_can_i_learn_more:
              title: Where can I learn more about Upscope?
              description: You can learn more about Upscope at
      show:
        title_with_domain: Install %{product_name} for %{domain}
        title: Install %{product_name}
      redirect:
        errors:
          missing_team: You don't have a team
      skip:
        success: Consider installing Upscope in the future for a better experience!
      errors:
        team_not_found: Could not find team
    form:
      next: Next
      multiple_questions_instruction: You can pick as many options as you want.
    questions:
      expected_agents:
        question: When fully deployed, approximately how many agents will be using
          Upscope?
        placeholder: e.g. 15
      channels:
        question: What support channels do you use?
        options:
          phone: Phone
          chat: Live Chat
          email: Email
      industry:
        question: What industry best describes your company?
        options:
          accounting: Accounting
          agriculture: Agriculture
          automotive: Automotive
          communications: Communications
          education: Education
          financial: Financial Services
          food: Food and Beverages
          government: Government
          healthcare: Hospital and Health Care
          insurance: Insurance
          legal: Legal
          logistics: Logistics
          management: Management
          marketing: Marketing and Advertising
          oilgas: Oil and Gas
          other: Other
          realestate: Real Estate
          recruiting: Staffing and Recruiting
          retail: Retail
          travel: Travel
          wellness: Beauty and Wellness
      name:
        question: What's your name?
      phone_number:
        question: What's your phone number?
        placeholder: e.g. +1 347 344-5719
        explain: Please include your country code
      source:
        question: Where did you first learn about Upscope?
        explain: If you found us on Google, we'd love to know what you were looking
          for!
      use_cases:
        question: What are you hoping to use Upscope for?
        options:
          form_filling: Help our users complete forms
          teach: Teach our customers how to use our application
          shop: Help our customers shop on our site
          confused: Help our user when they can’t figure out how to use our system
          p2p: Enable our customers to see their user’s screens
          integrate: Integrate Upscope into a in-house solution
          resell: Become a reseller of Upscope
          whitelabel: Brand Upscope under my own name
    invite_team:
      show:
        title: Invite your team
        page_description: Invite your team members now so they can test it with you
          during your trial.
        join_team_link: Join team link
        join_team_link_info: 'You can send your team members the following link to
          let them sign up on their own:'
      invite:
        errors:
          validation_error: Validation error
      done:
        success: You can invite more colleagues in your team settings.
      errors:
        onboarding_completed: Use members settings
    welcome:
      show:
        title: Welcome to Upscope!
        page_description: We have a few questions before you get started.
      save:
        success: Thank you for answering our questions!
        errors:
          answer_error: Error saving answer
  install_widget:
    instructions: To use %{product_name}, you need to include the code below on all
      pages on your website.
    install_myself: Yes, I will install %{product_name} myself
    i_know_javascript: I know how to add javascript to my website and will install
      %{product_name} myself.
    developer_install: No, my developer will install %{product_name} for me
    developer_install_info: My developer will be installing %{product_name} and I
      need to send them instructions.
    proxy_install: No, we cannot add code to our website
    proxy_install_info: We intend to use %{product_name}'s proxy instead of adding
      code to the website.
    can_you_install: Can you install %{product_name} yourself?
    public_key_instruction: If anywhere in the docs you are asked for your public
      API key, use
    need_help: Need help?
    contact_our_team: Send a message to our team through our live chat system. We'll
      offer help in minutes.
    ask_for_help: Ask for help
    i_need_help_installing: I need help with installing %{product_name}...
    upscope_installed: "%{product_name} successfully installed!"
    dev_instructions:
      title: Share this page with your developer
      description: You don't need to be logged in to see this page. Feel free to share
        it with your developer so that they can install %{product_name} for you.
      url_of_page: The url of the page is
      email_developer: Email your developer
      email_developer_description: You can click the button below to send an email
        to your developer with the instructions they need.
      send_email: Send email now
    proxy_instructions:
      title: Use proxy instead
      description_1: If at all possible, we encourage you to install %{product_name}
        on your website instead of using the proxy, as it's a far better experience
        for your users and agents.
      description_2: Installing %{product_name} allows you to start a session with
        any online user, without them needing to reload the page or follow any links.
      continue_with_proxy: Continue with proxy
    install_instructions:
      title: Installation instructions
      description: Add the following code on <bold>all webpages</bold> you want to
        be able to co-browse on. You can add the code anywhere on the page.
      upscope_installed: "%{product_name} successfully installed!"
      to_see_your_online_users: to see your online users.
      already_installed: "%{product_name} was installed but we haven't received any
        data for over a week!"
      needs_to_be_reinstalled: It likely needs to be installed again.
  proxy_session_page:
    are_you_agent: Are you %{agent_name}?
    log_in_now: Log in now
    what_is_upscope: What is Upscope?
    what_is_upscope_text: Upscope is a new way to collaborate on the internet. Click
      to learn more.
    redirect_message_part_1: If you haven't been redirected, please
    redirect_message_part_2: to continue.
    click_here: click here
  purchase_widget:
    use_existing_card: Use your %{brand} card ending in %{ending}.
    update_payment_card: Update my payment card.
    update_licenses_prompt: How many licenses would you like?
    plan_require_minium_licenses: Your plan requires a minimum of %{min_licenses}
      licenses
    plan_require_more_licenses: You have already used %{licenses_needed} licenses
      this month. Please enter a number equal to or greater than %{licenses_needed}.
    get_in_touch_to_get_more_licenses: Please get in touch with our team to buy more
      than %{max_licenses} licenses
    licenses_updated: Licenses updated
    error_adjusting_licenses: Error adjusting licenses
    total_today: Your total today is %{price} %{vat_notice}
    free_until: 100% FREE until %{trial_ends_on}!
    trial_ends_on_info: Unless you cancel, we will collect %{price} %{vat_notice}
    new_billing_period: Your new %{billing_period} amount will be %{price} %{vat_notice}
    charge_today_info: We will charge you today and credit any unused time on your
      current plan towards the invoice.
    change_number_of_licenses_tooltip: Click to change number of licenses
    change_card: Change card
    change_plan: Change plan
    payment_error_info: Please try contacting your bank to make sure they are not
      blocking the payment or
    agreement_info: By clicking the button above you agree to %{plan_status} %{price}
      %{vat_notice} every %{period} beginning %{trial_ends_on_period} until you cancel
      your subscription. %{per_license_notice}
    unless_cancel: "%{trial_ends_on} (unless you cancel before then)"
    your_trial_started: Your trial has started!
    you_are_subscribed: You are now subscribed!
    subscribed_message: We hope you will love using Upscope as much as we love building
      it! If you need anything from our team, we are always available on live chat,
      so feel free to get in touch.
    back_to_upscope: Back to Upscope!
    panel:
      title: Your payment method
      invoiced_message: You're will be invoiced for your usage monthly and can pay
        by wire transfer.
      card_added_message: You’re paying with your %{brand} card ending in %{ending}.
      has_previous_card: Good news, we already have your card on file!
      add_card_button: Add card
      add_card_button_info: You will review your subscription after adding a card.
      skip_add_card_button: Continue
      skip_add_card_button_info: You will review your subscription after the next
        step.
      billing:
        title: Your billing details
        form:
          label_invoice_name: Name on the invoice
          invoice_name_placeholder: Company name
          label_invoice_address: Address on the invoice
          label_invoice_email: Invoice email
          explain_invoice_email: If left empty, invoices will be sent to the team
            owner's email. You can enter multiple emails separated by comma.
      review:
        title: Review
        form:
          payment_frequency: Payment frequency
          label_payment_frequency: Yes, I would like to save %{yearly_saving} every
            year by paying yearly.
      vat:
        title: Your VAT details
        description: Because of your location and card origin we will have to charge
          %{tax_rate}% VAT.
        vat_number_info: Your VAT number is %{vat_number}.
        vat_charged_percentage: Your do not have a VAT number and will be charged
          %{tax_rate}% VAT.
        has_previous_vat: The VAT number we have on file for your team is %{vat_number}.
        vat_has_not_changed_button: My VAT information has not changed
        vat_has_not_changed_info: If your VAT information has changed please contact
          our team to make sure you pay the correct VAT.
        form:
          label_vat_number: Your VAT number
          explain: If you have one, please provide your VAT number in international
            format.
          do_not_have_vat_button: I don't have a VAT number
          add_vat_number: Add VAT number
  transfer_quote_widget:
    confirm: Are you sure you want to transfer this quote to %{teamDomain} (%{teamId})?
    failed_to_transfer: Failed to transfer quote
    panel:
      title: Transfer Quote
      description: Search for the team you want to transfer this quote to
    autocomplete_input:
      label: Transfer quote to
      placeholder: Enter team domain
      submit: Transfer Quote
  helloscreen:
    start_session:
      admin:
        title: Watch as admin
        info: Because you are an admin, you can watch the user without joining %{domain}.
        debug_user: Debug user
        watch_as_admin: Watch as admin
      confirm:
        title: Co-browsing
        central_message_title: Join session?
        join_session_with: You are about to join a co-browsing session with
        this_user: this user
        agent_already_on_call:
          one: "%{agent} is already on this call"
          other: "%{agent} are already on this call"
        start_session_question: Start session?
        you_are_about_to_ask: You are about to ask
        to_start_co_browsing_session: to start a co-browsing session.
        debug_user: Debug user
        join_session: Join session
        start_session: Start session
      error:
        title: Error
        central_message:
          sessions_over_limit:
            title: Too many sessions
            message: Hi there, it looks like there are too many active sessions.
            explore_larger_plans: Explore larger plans
            prompt: Why don't you let %{mail_link} know you need a larger plan?
          team_session_sharing_not_available:
            title: Somebody is already here...
            message: Your team does not allow multiple agent co-browsing. You can
              change this in settings.
            message_1: Your plan does not include multiple agent co-browsing.
            upgrade_to_business_plan: Upgrade to business plan
            prompt: Why don't you let %{mail_link} know you need to upgrade?
          agents_over_limit:
            title: Too many agents
            message: Hi there, it looks like too many agents used Upscope in the last
              month.
            explore_larger_plans: Explore larger plans
            prompt: Why don't you let %{mail_link} know you need a larger plan?
          seats_over_limit:
            title: No seats left!
            message: Hi there, too many agents are logged into Upscope.
            message1: Remind your colleagues to log out after their shift is over!
          no_subscription:
            cancelled_title: Subscription ended
            cancelled_message: It looks like your Upscope subscription has ended!
            trial_ended_title: Trial ended
            trial_ended_message: It looks like your Upscope trial has ended!
            get_a_subscription: Get a subscription
            prompt: Why don't you let %{mail_link} know you'd like to use Upscope?
          not_authorized:
            title: Not authorized
            message: You don't have permission to see this user. Please ask your account
              manager to give you the "View user" permission.
          not_supported:
            title: Browser not supported
            message: This user's browser is not supported by Upscope. Upscope works
              with all modern browsers and Internet Explorer 11.
          connection_error:
            title: We'll be right back
            message_html: We're updating our main database—please try again in a few
              seconds. If you see this message for longer than a minute, please %{link}
            check_our_status: check our status
            or_get_in_touch: or get in touch with our team.
          not_found:
            title: User not found
            message: We could not find this user in our system. Maybe they haven't
              been online in the last 30 days?
          not_online:
            title: User not online
            message: Please make sure your user is online and active on your website
              then reload this page.
            debug_user: Debug user
          ban:
            title: Please contact our team
            message: Upscope has tried to contact you regarding your account. Please
              get in touch with us now.
            contact_upscope: Contact Upscope
      redirect_as_admin:
        errors:
          team_signed_baa: This team has signed a BAA
    start_on_premise_session:
      confirm:
        title: Co-browsing
        central_message_title: Start session?
        central_message: You are about to ask this user to co-browse with you.
        start_session: Start session
      errors:
        on_premise_unsupported: This team does not support on-premise redirection
        visitor_not_found: Could not find this Visitor
        not_authorized: You are not authorized to see this page
        invalid_subscription: You do not have a valid subscription
        contact_our_team: Please contact our team
    visitors_list:
      new_token:
        errors:
          no_available_teams: No available teams
      index:
        last_seen: Last seen
        identity: Identity
        location: Location
        ip_address: Ip address
        web_url: Web Url
        incoming_call: Incoming Call
        errors:
          request_missed: Another agent has picked up this request!
      input:
        placeholder_lookup_code: Enter lookup code...
        placeholder_search: Search by email, session code, name...
      utils:
        status_row:
          disconnected: Disconnected, trying to reconnect...
          enter_4_digit_code: Enter 4 digit lookup code
          no_users_found: No users found
          connection_warning: The connection to some of our datacenters isn't working
            properly. Please reload the page if you can't find who you're looking
            for.
      user:
        agent_request:
          tooltip:
            title: This user is requesting an agent
            text: Click to start session
            needs_help: Needs help
        online:
          tooltip:
            text: Click to start session
            online: Online
      show:
        my_personal_link: My personal link
        start_session: Start Session
        online_users: Online users
        my_sessions: My sessions
        my_team_sessions: My team's sessions
        trial_over: Trial over
        please_purchase_a_license: Please purchase a license to continue using Upscope.
        see_plans: See plans
        not_allowed: Not allowed
        you_are_not_allowed_to_start_session: You are not allowed to start a session.
          Ask your admin if you think this is wrong.
        install_upscope: Install Upscope
        install_instructions: Upscope is better when it is installed, as you can start
          a session with anyone online with one click.
        see_instructions: See instructions
  partials:
    header:
      settings: Settings
      logout: Logout
      login: Login
      create_account: Create account
    top_bar:
      reinstall_warning: Upscope might need to be re-installed.
      install: Install
      upscope_has_tried_to_contact_you: Upscope has tried to contact you regarding
        your account. Please get in touch with us now.
      start_your_14_day_free_trial_now: Start your 14-day free trial now!
      contact_upscope: Contact Upscope
      start_trial: Start trial
      your_trial_will_end: Your trial will end in %{time_ago_in_words}!
      upgrade_now_to_continue: Upgrade now to continue using our service!
      upgrade_now: Upgrade now
      your_trial_has_ended: Your trial has ended!
      upgrade_now_to_continue_using_our_service: Upgrade now to continue using our
        service!
    alerts:
      login_message:
        message: To log in to your Upscope account, please enter the email associated
          with your Upscope account. We'll then send you an email with a link to log
          right in.
      from_session_expired:
        your_session_has_expired: Your session has expired. Please log in again using
          your email address.
      logout_app:
        want_to_log_in_again: Want to log in again? Enter your email below.
      invalid_password:
        password_not_correct: The password you have entered is not correct. Please
          try again, or
        log_in_by_email_instead: log in by email instead
      login_email_sent:
        sent_email_to_login: We have sent you an email to login. Please check your
          inbox.
    feature_preview:
      feature_not_available: "%{feature_name} is not available on your current plan,
        but you can preview"
      feature_not_available_beta: this feature while it is in beta.
      feature_not_available_until: this feature until %{preview_ends_on}.
      upgrade: Upgrade
      upgrade_info: to access this feature in the future.
    validation_errors:
      there_were_some_validations_errors: There were some validations errors. Please
        check again and resubmit!
    enable_beta:
      send_us_feedback: send us feedback
      feature_name_is_currently_in_beta: "%{feature_name} is currently in beta."
      please: Please
      about_it: about it!
      enable_beta_features: Enable beta features
      to_use_it: to use it!
      feature_name_is_currently_in_beta_no_access: "%{feature_name} is currently in
        beta, but your team does not have beta access yet!"
      to_use_feature_name_and_more_experimental_features: to use %{feature_name} and
        more experimental features.
    forms:
      plan:
        plan_name: Plan name
        min_licenses: Min licenses
        included_licenses: Included licenses
        max_licenses: Max licenses
        the_plan_name: The plan name
        features: Features
        currency: Currency
        base_cost: Base cost
        amount_per_license: Amount per license
        period: Period
        month: "/ month"
        month_2: month
        two_months: "/ two months"
        two_month_2: two_month
        quarter: "/ quarter"
        quarter_2: quarter
        six_months: "/ six months"
        six_month: six_month
        year: "/ year"
        year_2: year
        potential_vat_is_not_included_in_this_value: Potential VAT is not included
          in this value.
        license_type: License type
        pay_by: Pay by
        bank_transfer: Bank transfer
        credit_card: Credit card
        named_agent: Named agent
        seat: Seat
        concurrent: Concurrent
  intros:
    intro_1: Welcome to Upscope!
    intro_2: Welcome to Upscope! Let me show you how to start a session!
    intro_3: If you are talking to a user who is on your website, <b>ask them to press
      the control key 5 times</b> and enter the code they give you here.
    intro_4: You can also search by location or, <em>depending on the way you install
      Upscope,</em> name or email.
    intro_5: The search results appear here. Most recent people are at the top!
    intro_6: To start a co-browsing session with this user, you need to click here.
    intro_7: Only use this button to <strong>create a session with someone who is
      not already online.</strong>
    intro_8: "<strong>That's it!</strong> If you need help, you can contact support
      down here."
  waiting_room:
    personal_link: personal link
    instructions_1: To start co-browsing, copy this link and send it to your customer.
    instructions_2: Once they are on the page, the session will begin automatically.
    go_back: If you want to go back, click
    copy_link: Copy link
    copied: Copied!
  search_input:
    placeholder: Search...
    button: Search
  rate_session_widget:
    title: How would you rate the quality of the co-browsing session?
    stop_asking_me: Stop asking me
    send_feedback: Send feedback
    feedbacks:
      feedback_5: Awesome! Any further feedback?
      feedback_4: Great! How can we improve it next time?
      feedback_3: What could have made it better?
      feedback_2: Oh no! What went wrong?
      feedback_1: Oh no! What went wrong?
    thank_you: Thanks for your feedback. This is very valuable to us!
  rest_keys_widget:
    display_no_keys: You still do not have any rest keys
    rest_key_created: New Rest Key created
    something_went_wrong: Something went wrong, please try again!
    panel:
      title: Your secret keys
      form:
        label: Rest Key Name
        placeholder: Production Key
        submit: Create
      section: You can use your secret API keys to create integrations with Upscope.
    rest_keys_table:
      description: Your secret API keys are obfuscated to keep your account safe.
        If you lose it, you can disable it below.
      name: Name
      secret_api_key: Secret Api Key
      last_used_at: Last Used At
      last_ip: Last Ip
      actions: Actions
      raw_secret_api_key_description: Your new secret API key is displayed above.
        Copy it now as it will be hidden forever.
  transfer_ownership_widget:
    confirm_transfer: Are you sure you want to transfer ownership to %{selected_user_label}
      (%{selected_user_label_secondary})?
    failed_transfer: Failed to transfer ownership to %{selected_user_label}
    panel:
      title: Transfer ownership
      description: Search for the team member you want to transfer ownership to. If
        they are not already part of the team, invite them first.
      form:
        label: New team owner email
        autocomplete:
          label: New team owner email
          placeholder: Enter team member email
        submit: Transfer
  subscription_mailer:
    subscription_ending_soon:
      your_upscope_subscription_will_be_terminated_soon: Your Upscope subscription
        will be terminated soon
      hi_there: Hi there,
      this_is_to_let_you_know_that_your_subscription: This is to let you know that
        your subscription will terminate soon due to unreceived payments.
      to_keep_using_upscope: If you'd like to keep using Upscope, please reach out
        to our billing team by replying to this email, and they'll be happy to help
        process the payment.
      take_care: Take care,
  update_card_widget:
    card_updated: Your card has been updated!
    panel:
      title_update: Update your card
      title_add: Add your card
      alert_1: Your are currently paying with a <bold>%{brand}</bold> ending in <bold>%{ending}</bold>
        which will expire this month.
      alert_2: Your are currently paying with a <bold>%{brand}</bold> ending in <bold>%{ending}</bold>
        which has expired.
      alert_3: You are %{now_currently} paying with a <bold>%{brand}</bold> ending
        in <bold>%{ending}</bold>.
      update_card: Update card
      change_payment_method: Change payment method
      update: Update
      add_card: Add card
      update_card_warning: By submitting this form you confirm you are authorized
        to use this card to purchase a subscription to Upscope.
  visitors_list_empty:
    placeholder: Enter lookup code...
    status: Status
    identity: Identity
    location: Location
    ip: Ip
    web_url: Web url
  update_licenses_widget:
    prompt: How many licenses would you like?
    licenses_updated: Licenses updated
    settings_updated: Settings updated
    description: You currently have <click><bold><tooltip>%{active_licenses} licenses</tooltip></bold></click>,
      bringing your total to <bold>%{total_price}</bold>. This month, you have used
      <bold>%{licenses_needed} licenses</bold> so far.
    auto_increase_licenses_on_info: Your licenses <click><bold><tooltip>will not increase</tooltip></bold></click>
      automatically.
    auto_increase_licenses_off_info: Your licenses <click><bold><tooltip>will increase</tooltip></bold></click>
      automatically as needed.
    plan_no_longer_available: This plan is no longer available and to adjust your
      number of licenses you will need to <click>switch</click> to one of our newer
      plans.
    auto_adjust_licenses_monthly: If during any month you use more than %{active_licenses}
      licenses, we'll charge you for them at the end of the month.
    update_licenses_tooltip:
      text: Click here to adjust
    auto_increase_licenses_tooltip_on:
      text: Click here to turn automatic increase on
    auto_increase_licenses_tooltip_off:
      text: Click here to turn automatic increase off
    errors:
      plan_requires_minimum: Your plan requires a minimum of %{min_licenses} licenses
      monthly_use: You have used %{licenses_needed} licenses this month.
      buy_more_licenses: Please get in touch with our team to buy more than %{max_licenses}
        licenses
      error_adjusting_licenses: Error adjusting licenses
      error_updating_settings: Error updating settings
  twilio_flex_instructions_widget:
    connect_upscope_to_your_twilio_flex: Connect Upscope to your Twilio Flex Plugin
      to start a session directly from a Twilio Flex Conversation.
    setup_authentication: 1. Setup authentication
    install_twilio_flex_component: 2. Install the Twilio Flex React Component
    add_component_to_flex: 3. Add the component to Flex
    table:
      property: Property
      type: Type
      description: Description
      auth_url_description: URL of your Twilio Flex function (the URL you copied on
        Step 1).
      upscope_env_description: Set to <code>production</code>.
    install_instructions:
      step_1: From Twilio Console, go to <bold>Functions > Services > Create Service</bold>.
      step_2: Enter a Service Name and click Next.
      step_3: Click <bold>Add+ > Add Function</bold>.
      step_4: Change the default name from <code>/path_1</code> to <code>/get_integration_token</code>.
        Press Enter to create the function.
      step_5: Change the function visibility from <bold>Private</bold> to <bold>Public</bold>.
      step_6: In the text editor on the right, paste the code below then click <bold>Save</bold>,
        then click on <bold>Copy URL</bold> and keep this value for later.
      step_7: 'In <bold>Settings > Environment Variables</bold>, make sure to check
        <bold>Add my Twilio Credentials (ACCOUNT_SID) and (AUTH_TOKEN) to ENV</bold>
        option and add <code>UPSCOPE_INTEGRATION_TOKEN</code> with the following value:'
      step_8: 'In <bold>Settings > Dependencies</bold>, add the following dependencies
        without a version number:'
      step_9: Click <bold>Deploy All</bold>.
  t_How_do_you_mainly_communicate_with_customers_q: How do you mainly communicate
    with customers?
  t_Live_chat: Live chat
  t_Phone_calls: Phone calls
  t_Both_live_chat_and_phone_calls: Both live chat and phone calls
  t_Other_d: Other.
  t_Who_do_you_most_expect_to_use_Upscope_with_q: Who do you most expect to use Upscope
    with?
  t_With_our_non_tech_savvy_customers_who_need_a_lot_of_help_d: With our non-tech
    savvy customers who need a lot of help.
  t_With_all_our_customers_because_our_interface_is_complicated_d: With all our customers
    because our interface is complicated.
  t_I_don_t_know_how_useful_it_will_be_and_who_will_most_benefit_from_it_d: I don’t
    know how useful it will be and who will most benefit from it.
  t_How_much_do_you_know_about_Upscope_q: How much do you know about Upscope?
  t_I_know_exactly_what_I_m_signing_up_for_and_how_to_use_it_d: I know exactly what
    I'm signing up for and how to use it.
  t_I_think_I_know_what_it_does_and_how_to_use_it_d: I think I know what it does and
    how to use it.
  t_It_looks_exciting_but_I_don_t_know_how_to_use_it_yet_d: It looks exciting but
    I don’t know how to use it yet.
  t_I_m_just_playing_with_it_d: I’m just playing with it.
  t_What_is_your_name_q: What is your name?
  t_What_is_your_phone_number_q: What is your phone number?
  t_e_dg_d_1_555_555_5555: e.g. +1 555 555 5555
  t_Make_sure_to_include_your_country_code_d: Make sure to include your country code.
  t_Log_in_your_Upscope_account: Log in your Upscope account
  t_You_ve_authorized_integration_type_to_access_your_account: You've authorized %{integration_type}
    to access your account
  t_Activate_your_Upscope_account: Activate your Upscope account
  t_Invite_name_to_be_part_of_your_team_on_Upscope: Invite %{name} to be part of your
    team on Upscope
  t_Set_up_your_Upscope_account: Set up your Upscope account
  t_Join_domain_on_Upscope: Join %{domain} on Upscope
  t_Delete_domain_on_Upscope: Delete %{domain} on Upscope
  t_Delete_all_visitors_on_Upscope: Delete all visitors on Upscope
  t_Verify_your_email: Verify your email
  t_Change_your_Upscope_email_to_new_email: Change your Upscope email to %{new_email}
  t_Enable_your_Upscope_password: Enable your Upscope password
  t_Disable_your_Upscope_MFA_device: Disable your Upscope MFA device
  t_1_second: 1 second
  t_seconds_second: "%{seconds} second"
  t_1_hr: 1 hr
  t_hours_hrs: "%{hours} hrs"
  t_1_min: 1 min
  t_minutes_mins: "%{minutes} mins"
  t_past_start_date: past start date
  t_cannot_change_from_paid: cannot change from paid
  t_cannot_change_from_trial_canceled: cannot change from trial canceled
  t_cannot_change_from_completed: cannot change from completed
  t_cannot_change_from_signed: cannot change from signed
  t_cannot_change_from_trial: cannot change from trial
  t_must_be_set_when_there_is_a_contract: must be set when there is a contract
  t_cannot_be_set_when_there_is_no_contract: cannot be set when there is no contract
  t_are_invalid: are invalid
  t_cannot_be_set_when_there_is_an_existing_subscription: cannot be set when there
    is an existing subscription
  t_cannot_be_set_by_oauth_users: cannot be set by oauth users
  t_is_not_allowed_by_your_team: is not allowed by your team
  t_needs_to_be_at_least_8_characters_long: needs to be at least 8 characters long
  t_needs_to_contain_letters_and_non_letters: needs to contain letters and non-letters
  t_is_a_commonly_used_password_and_not_strong_enough: is a commonly used password
    and not strong enough
  t_cannot_look_like_an_email: cannot look like an email
  t_cannot_look_like_a_domain_or_url: cannot look like a domain or url
  t_You_are_already_logged_in: You are already logged in
  t_Unchanged: Unchanged
  t_something_and_somethingElse: "%{something} and %{somethingElse}"
  t_1_month_ago: 1 month ago
  t_months_months_ago: "%{months} months ago"
  t_1_week_ago: 1 week ago
  t_weeks_weeks_ago: "%{weeks} weeks ago"
  t_1_day_ago: 1 day ago
  t_days_days_ago: "%{days} days ago"
  t_1_hour_ago: 1 hour ago
  t_hours_hours_ago: "%{hours} hours ago"
  t_1_minute_ago: 1 minute ago
  t_minutes_minutes_ago: "%{minutes} minutes ago"
  t_just_now: just now
  t_in_1_month: in 1 month
  t_in_months_months: in %{months} months
  t_in_1_week: in 1 week
  t_in_weeks_weeks: in %{weeks} weeks
  t_in_1_day: in 1 day
  t_in_days_days: in %{days} days
  t_in_1_hour: in 1 hour
  t_in_hours_hours: in %{hours} hours
  t_in_1_minute: in 1 minute
  t_in_minutes_minutes: in %{minutes} minutes
  t_seconds_seconds: "%{seconds} seconds"
  t_Mission_Control: Mission Control
  t_Getting_started: Getting started
  t_Co_browse: Co-browse
  t_Install: Install
  t_REST_API: REST API
  t_Integrations: Integrations
  t_History: History
  t_Team: Team
  t_Usage: Usage
  t_Settings: Settings
  t_Billing: Billing
  t_Delete_Visitor_Data: Delete Visitor Data
  t_Customer_Success: Customer Success
  t_Quotes: Quotes
  t_Dashboards: Dashboards
  t_You_just_found_a_bug_e: You just found a bug!
  t_It_looks_like_you_found_a_bug_with_our_system_d_Our_team_has_been_alerted_d: It
    looks like you found a bug with our system. Our team has been alerted.
  t_Join_thousands_of_customer_centric_teams_using_interactive_co_browsing_for_onboarding_support_and_sales_d: Join
    thousands of customer-centric teams using interactive co-browsing for onboarding,
    support and sales.
  t_Our_data_shows_Upscope_has_cut_our_onboarding_team_s_call_time_by_27_which_is_fantastic_d: Our
    data shows Upscope has cut our onboarding team's call time by 27%, which is fantastic.
  t_Set_a_password: Set a password
  t_Not_now: Not now
  t_Set_password: Set password
  t_Password_set: Password set
  t_Your_password_has_been_set_d_You_can_now_log_in_with_your_email_and_password_d: Your
    password has been set. You can now log in with your email and password.
  t_Please_enter_a_password_d: Please enter a password.
  t_Setting_a_password_allows_you_to_log_into_Upscope_more_quickly_and_securely_d: Setting
    a password allows you to log into Upscope more quickly and securely.
  t_Password: Password
  t_Make_sure_it_is_long_and_secure_d: Make sure it is long and secure.
  t_Ok: Ok
  t_Previous: Previous
  t_Next: Next
  t_Showing_strong_currentFirstItem_strong_to_strong_currentLastItem_strong_of_strong_totalItems_strong_itemName: Showing
    <strong>%{currentFirstItem}</strong> to <strong>%{currentLastItem}</strong> of
    <strong>%{totalItems}</strong> %{itemName}
  t_items: items
  t_Multi_factor_authentication: Multi factor authentication
  t_MFA_is_already_enabled: MFA is already enabled
  t_You_cannot_set_an_MFA_because_you_use_Single_Sign_On: You cannot set an MFA because
    you use Single Sign On
  t_Unknown_error: Unknown error
  t_Error: Error
  t_MFA_enabled: MFA enabled
  t_Multi_factor_authentication_has_been_enabled_for_your_account_d: Multi factor
    authentication has been enabled for your account.
  t_Please_make_sure_the_code_is_valid: Please make sure the code is valid
  t_Download_a_Multi_factor_authentication_app: Download a Multi factor authentication
    app
  t_To_enable_MFA_you_will_need_Google_Authenticator_or_a_similar_authentication_app_d: To
    enable MFA you will need Google Authenticator or a similar authentication app.
  t_Scan_QR_Code: Scan QR Code
  t_Use_the_authentication_app_to_scan_this_QR_code_d_It_will_start_generating_6_digit_codes_every_few_seconds_d: Use
    the authentication app to scan this QR code. It will start generating 6 digit
    codes every few seconds.
  t_Enter_code: Enter code
  t_Enter_the_6_digit_code_from_the_authentication_app_to_confirm_that_MFA_is_enabled_d: Enter
    the 6 digit code from the authentication app to confirm that MFA is enabled.
  t_Code: Code
  t_Enable: Enable
  t_Join_teamDomain_on_Upscope: Join %{teamDomain} on Upscope
  t_Please_enter_a_valid_email_address_d: Please enter a valid email address.
  t_Something_went_wrong_d_Please_try_again_later_d: Something went wrong. Please
    try again later.
  t_We_have_asked_your_admin: We have asked your admin
  t_You_will_receive_an_email_when_they_approve_your_request_d: You will receive an
    email when they approve your request.
  t_Please_check_your_email: Please check your email
  t_We_have_sent_you_an_email_with_a_link_to_join_your_team_d: We have sent you an
    email with a link to join your team.
  t_Already_have_an_account_q: Already have an account?
  t_Log_in_now: Log in now
  t_Ask_to_join_your_team: Ask to join your team
  t_Enter_your_name_and_email_below_and_we_ll_notify_your_administrator_d: Enter your
    name and email below and we'll notify your administrator.
  t_Your_name: Your name
  t_Your_email: Your email
  t_Request_invite: Request invite
  t_Join_your_team: Join your team
  t_Good_news_your_team_makes_it_easy_to_join_without_needing_an_invite_d: Good news,
    your team makes it easy to join without needing an invite.
  t_You_can_join_teamDomain_s_Upscope_team_with_your_SAML_account_d: You can join
    %{teamDomain}'s Upscope team with your SAML account.
  t_Log_in_with_ssoName: Log in with %{ssoName}
  t_Just_use_your_domain_and_we_will_send_you_an_email_to_verify_you_are_part_of_the_team_d: Just
    use your @%{domain} and we will send you an email to verify you are part of the
    team.
  t_Send_me_join_link: Send me join link
  t_Log_in: Log in
  t_Sign_in_error: Sign in error
  t_Something_went_wrong_while_signing_in_d_Please_try_again_d: Something went wrong
    while signing in. Please try again.
  t_This_link_is_invalid: This link is invalid
  t_We_have_sent_you_an_email_with_a_link_to_log_in_d: We have sent you an email with
    a link to log in.
  t_Enter_your_MFA_code: Enter your MFA code
  t_MFA_code_from_your_authenticator_app: MFA code from your authenticator app
  t_This_code_is_incorrect: This code is incorrect
  t_Check_code: Check code
  t_If_you_forgot_your_MFA_code_please_get_in_touch_with_your_team_admin_who_might_be_able_to_reset_it_for_you_d: If
    you forgot your MFA code, please get in touch with your team admin, who might
    be able to reset it for you.
  t_Log_in_to_Upscope: Log in to Upscope
  t_Please_enter_the_email_associated_with_your_Upscope_account_to_continue_d: Please
    enter the email associated with your Upscope account to continue.
  t_We_cannot_find_an_account_for_this_email: We cannot find an account for this email
  t_We_will_ask_you_for_your_password_next_if_you_have_one_d: We will ask you for
    your password next if you have one.
  t_Your_password: Your password
  t_Please_ensure_the_password_is_correct: Please ensure the password is correct
  t_Do_not_have_an_account_q: Do not have an account?
  t_Sign_up_now: Sign up now
  t_Forgot_your_password_q: Forgot your password?
  t_Request_an_email_link: Request an email link
  t_Google_Authentication_Error: Google Authentication Error
  t_An_error_occurred_while_authenticating_with_Google_d: An error occurred while
    authenticating with Google.
  t_Sign_up_to_Upscope: Sign up to Upscope
  t_We_ve_sent_you_an_email_with_a_link_to_verify_your_email_address_d: We've sent
    you an email with a link to verify your email address.
  t_We_have_requested_an_invite: We have requested an invite
  t_We_have_sent_an_email_to_your_team_asking_them_to_invite_you_to_join_d_You_will_receive_an_email_when_they_do_d: We
    have sent an email to your team asking them to invite you to join. You will receive
    an email when they do.
  t_Join_your_colleagues: Join your colleagues
  t_Your_team_is_already_on_Upscope_and_you_can_join_it_without_needing_an_invite_d: Your
    team is already on Upscope, and you can join it without needing an invite.
  ? t_Your_team_is_already_on_Upscope_but_you_need_an_invite_to_join_your_colleagues_d_Do_you_want_to_ask_to_join_the_existing_team_q
  : Your team is already on Upscope, but you need an invite to join your colleagues.
    Do you want to ask to join the existing team?
  t_Alternatively_you_can: Alternatively, you can
  t_Create_a_New_Team: Create a New Team
  t_Alternatively_you_can_create_a_fresh_new_team_d: Alternatively you can create
    a fresh new team.
  t_Create_your_account: Create your account
  t_Create_account: Create account
  ? t_Already_have_an_account_q_login_Log_in_now_login__d_By_signing_up_you_agree_to_our_terms_Terms_of_Service_terms_and_privacy_Privacy_Policy_privacy__d
  : Already have an account? <login>Log in now</login>. By signing up, you agree to
    our <terms>Terms of Service</terms> and <privacy>Privacy Policy</privacy>.
  t_Alternatively: Alternatively
  t_Sign_up_with_Google_for_Business: Sign up with Google for Business
  t_REST_API_Dashboard: REST API Dashboard
  t_No_API_access: No API access
  t_This_team_does_not_have_access_to_Co_Browsing_API_d: This team does not have access
    to Co-Browsing API.
  t_Rest_Keys: Rest Keys
  t_Request_History: Request History
  t_Webhook_History: Webhook History
  t_Co_Browsing_API_Docs: Co-Browsing API Docs
  t_See_all_the_requests_made_to_the_API: See all the requests made to the API
  t_Refresh: Refresh
  t_No_requests_yet: No requests yet
  t_Time: Time
  t_URL: URL
  t_Response_code: Response code
  t_Expand: Expand
  t_View: View
  t_requests: requests
  t_REST_Keys: REST Keys
  t_Use_the_REST_keys_to_make_secure_API_calls_to_Upscope_d_You_can_only_see_the_secret_key_once_d: Use
    the REST keys to make secure API calls to Upscope. You can only see the secret
    key once.
  t_Public_api_key: Public api key
  t_Never_used: Never used
  t_Webhook_delivery_history: Webhook delivery history
  t_These_are_all_the_webhook_requests_we_have_made_to_your_server_d: These are all
    the webhook requests we have made to your server.
  t_No_webhook_deliveries_yet: No webhook deliveries yet
  t_Next_attempt: Next attempt
  t_Session: Session
  t_Timeout: Timeout
  t_View_session: View session
  t_Subscription_needed: Subscription needed
  t_Please_start_a_subscription_to_use_Upscope_d: Please start a subscription to use
    Upscope.
  t_Connection_error: Connection error
  t_We_were_unable_to_connect_to_Upscope_d_Please_try_again_later_d: We were unable
    to connect to Upscope. Please try again later.
  t_Visitor_not_found: Visitor not found
  t_This_visitor_was_not_found_d_Please_try_again_later_d: This visitor was not found.
    Please try again later.
  t_Visitor_not_supported: Visitor not supported
  t_This_visitor_s_browser_is_not_supported_by_Upscope_d: This visitor's browser is
    not supported by Upscope.
  t_Visitor_offline: Visitor offline
  t_This_visitor_is_currently_offline_d: This visitor is currently offline.
  t_You_have_reached_your_subscription_limits: You have reached your subscription
    limits
  t_Your_team_does_not_support_onpremise_d_Please_get_in_touch_with_us_d: Your team
    does not support onpremise. Please get in touch with us.
  t_Missed_request: Missed request
  t_Your_colleague_has_already_accepted_the_request_d: Your colleague has already
    accepted the request.
  t_We_have_lost_connection_with_our_server_d: We have lost connection with our server.
  t_this_visitor: this visitor
  t_Cobrowse_with_nickname__q: Cobrowse with %{nickname}?
  t_You_are_about_to_ask_name_to_cobrowse_d_Are_you_sure_q: You are about to ask %{name}
    to cobrowse. Are you sure?
  t_Cobrowse_now: Cobrowse now
  t_Log_in_with_SAML: Log in with SAML
  t_How_was_the_quality_of_the_co_browsing_session_q: How was the quality of the co-browsing
    session?
  t_Submit: Submit
  t_Awesome_e_Any_further_feedback_q: Awesome! Any further feedback?
  t_Great_e_How_can_we_improve_it_next_time_q: Great! How can we improve it next time?
  t_What_could_have_made_it_better_q: What could have made it better?
  t_Oh_no_e_What_went_wrong_q: Oh no! What went wrong?
  t_My_personal_link: My personal link
  t_Online_users: Online users
  t_My_sessions: My sessions
  t_My_team_s_sessions: My team's sessions
  t_Enter_a_lookup_code_to_cobrowse: Enter a lookup code to cobrowse
  t_No_visitors_found: No visitors found
  t_Identity: Identity
  t_Location: Location
  t_IP_Address: IP Address
  t_Last_URL: Last URL
  t_Calling: Calling
  t_Online: Online
  t_Enter_lookup_code: Enter lookup code
  t_Search_d_d_d: Search...
  t_Create_new_account: Create new account
  t_Create_a_new_account_for_a_new_user: Create a new account for a new user
  t_No_changes_allowed: No changes allowed
  t_Please_contact_us_to_change_your_subscription_d: Please contact us to change your
    subscription.
  t_Download_csv: Download csv
  t_Per_agent_usage: Per agent usage
  t_Breakdown_of_usage_for_each_agent_who_has_used_Upscope_during_the_time_period_d: Breakdown
    of usage for each agent who has used Upscope during the time period.
  t_Agent: Agent
  t_Sessions: Sessions
  t_Unique_visitors: Unique visitors
  t_audio: audio
  t_No_data_to_display: No data to display
  t_Personal_Settings: Personal Settings
  t_Personal_settings: Personal settings
  t_Settings_saved: Settings saved
  t_Your_settings_have_been_saved_successfully_d: Your settings have been saved successfully.
  t_An_unknown_error_occurred_while_saving_your_settings_d_Please_try_again_later_d: An
    unknown error occurred while saving your settings. Please try again later.
  t_Learn_more: Learn more
  t_This_features_is_currently_in_beta_d_Disable_beta_if_you_encounter_any_issues_d: This
    features is currently in beta. Disable beta if you encounter any issues.
  t_This_feature_is_currently_in_beta_d_Toggle_beta_features_on_to_use_it_d: This
    feature is currently in beta. Toggle beta features on to use it.
  t_Feature_not_included: Feature not included
  t_This_feature_is_not_available_in_your_current_plan_d_Please_get_in_touch_with_us_to_upgrade_your_plan_d: This
    feature is not available in your current plan. Please get in touch with us to
    upgrade your plan.
  ? t_The_message_of_the_modal_that_is_shown_when_the_visitor_presses_the_control_key_5_times_d_Use_code_lookupCode_code_to_insert_the_lookup_code_d
  : 'The message of the modal that is shown when the visitor presses the control key
    5 times. Use: <code>{%lookupCode%}</code> to insert the lookup code.'
  t_Verify_new_email_address: Verify new email address
  ? t_We_will_send_you_an_email_to_confirm_your_new_email_address_d_Please_click_the_link_in_the_email_to_confirm_your_email_address_d
  : We will send you an email to confirm your new email address. Please click the
    link in the email to confirm your email address.
  t_Email_address_confirmed: Email address confirmed
  t_Your_email_address_has_been_changed: Your email address has been changed
  t_Multi_factor_authentication_disabled: Multi factor authentication disabled
  t_You_have_successfully_disabled_multi_factor_authentication: You have successfully
    disabled multi factor authentication
  t_Password_enabled: Password enabled
  t_You_have_successfully_enabled_your_password: You have successfully enabled your
    password
  t_Disable_MFA: Disable MFA
  t_Are_you_sure_you_want_to_disable_multi_factor_authentication_q: Are you sure you
    want to disable multi factor authentication?
  t_Cancel: Cancel
  ? t_We_have_sent_you_an_email_to_confirm_disabling_multi_factor_authentication_d_Please_click_the_link_in_the_email_to_confirm_disabling_multi_factor_authentication_d
  : We have sent you an email to confirm disabling multi factor authentication. Please
    click the link in the email to confirm disabling multi factor authentication.
  t_Multi_factor_authentication_protects_your_account_by_requiring_a_code_to_log_in_d: Multi
    factor authentication protects your account by requiring a code to log in.
  t_MFA_Enabled: MFA Enabled
  t_Your_account_is_protected_by_multi_factor_authentication_d_You_can_disable_multi_factor_authentication_below_d: Your
    account is protected by multi factor authentication. You can disable multi factor
    authentication below.
  t_MFA_Not_Enabled: MFA Not Enabled
  t_You_have_not_enabled_multi_factor_authentication_d_You_can_enable_multi_factor_authentication_below_d: You
    have not enabled multi factor authentication. You can enable multi factor authentication
    below.
  t_Enable_MFA: Enable MFA
  t_We_have_sent_you_an_email_to_confirm_your_new_password_d: We have sent you an
    email to confirm your new password.
  t_My_password: My password
  t_Use_your_password_to_access_your_account_more_conveniently_d: Use your password
    to access your account more conveniently.
  t_Your_password_has_not_been_enabled_yet: Your password has not been enabled yet
  t_We_have_sent_you_an_email_to_confirm_your_new_password_d_Click_on_the_link_in_the_email_to_confirm_your_new_password_d: We
    have sent you an email to confirm your new password. Click on the link in the
    email to confirm your new password.
  t_Your_password_is_enabled: Your password is enabled
  ? t_Your_account_is_protected_by_a_password_d_You_can_change_the_password_below_d_If_you_forget_your_password_you_can_log_in_with_your_email_address_d
  : Your account is protected by a password. You can change the password below. If
    you forget your password, you can log in with your email address.
  t_New_password: New password
  t_Update_password: Update password
  t_Confirm_your_email_change: Confirm your email change
  ? t_We_have_sent_you_an_email_to_your_old_account_to_confirm_the_change_d_If_you_no_longer_have_access_to_that_email_address_please_contact_us_d
  : We have sent you an email to your old account to confirm the change. If you no
    longer have access to that email address, please contact us.
  t_Your_profile_has_been_updated: Your profile has been updated
  t_You_cannot_change_your_email_address: You cannot change your email address
  t_My_details: My details
  t_The_name_email_and_contact_information_for_your_personal_account_d: The name,
    email and contact information for your personal account.
  t_First_and_last_name: First and last name
  t_Email: Email
  t_Phone_number: Phone number
  t_Please_include_your_country_code_if_outside_of_the_United_States_d: Please include
    your country code if outside of the United States.
  t_Do_you_want_to_receive_onboarding_and_product_updates_emails_from_Upscope_q: Do
    you want to receive onboarding and product updates emails from Upscope?
  t_Yes: 'Yes'
  t_No: 'No'
  t_Save_profile: Save profile
  t_Send_your_personal_co_browsing_to_visitors_to_be_notified_when_they_show_up_and_start_cobrowsing_immediately_d: Send
    your personal co-browsing to visitors to be notified when they show up and start
    cobrowsing immediately.
  t_Your_personal_co_browsing_link: Your personal co-browsing link
  t_Please_only_use_numbers_and_letters: Please only use numbers and letters
  t_Email_notifications: Email notifications
  t_Never_notify_me_by_email: Never notify me by email
  t_Email_me_if_a_user_is_waiting_for_more_than_2_minutes: Email me if a user is waiting
    for more than 2 minutes
  t_Email_me_as_soon_as_a_user_shows_up_at_my_link: Email me as soon as a user shows
    up at my link
  t_Remote_logout: Remote logout
  t_These_are_all_the_other_devices_you_are_logged_into_d_You_can_remotely_logout_from_any_of_them_d: These
    are all the other devices you are logged into. You can remotely logout from any
    of them.
  t_Device: Device
  t_Last_seen: Last seen
  t_This_device: This device
  t_Logout: Logout
  t_Cannot_reach_server: Cannot reach server
  t_We_are_having_trouble_loading_the_visitor_data_d_Please_try_again_later_d: We
    are having trouble loading the visitor data. Please try again later.
  t_Visitor_Deleted: Visitor Deleted
  t_The_visitor_data_has_been_successfully_deleted_d: The visitor data has been successfully
    deleted.
  t_Cannot_delete_visitor: Cannot delete visitor
  t_This_visitor_cannot_be_deleted_at_this_time_d_Please_try_again_later_d: This visitor
    cannot be deleted at this time. Please try again later.
  t_Visitor_ID: Visitor ID
  t_Last_Seen: Last Seen
  t_Identities: Identities
  t_Currently_online: Currently online
  t_No_visitors_found_d: No visitors found.
  t_Our_team_has_been_trying_to_contact_you_d_Please_get_in_touch_to_continue_using_Upscope_d: Our
    team has been trying to contact you. Please get in touch to continue using Upscope.
  t_Contact_us: Contact us
  t_Your_free_trial_has_ended_d_Please_purchase_a_subscription_to_continue_using_product: Your
    free trial has ended. Please purchase a subscription to continue using %{product}
  t_Purchase: Purchase
  t_Your_free_trial_will_end_on_formattedDate__d_Add_a_card_to_continue_using_product__d: Your
    free trial will end on %{formattedDate}. Add a card to continue using %{product}.
  t_Add_payment_method: Add payment method
  t_We_d_love_to_hear_your_feedback_d_Please_leave_us_a_review_on_G2_and_we_ll_send_a_20_Amazon_gift_card_d: We'd
    love to hear your feedback. Please leave us a review on G2, and we'll send a $20
    Amazon gift card.
  t_Leave_a_review: Leave a review
  t_Not_authorized: Not authorized
  t_You_are_not_authorized_to_access_this_page_d_Please_check_with_your_team_owner_d: You
    are not authorized to access this page. Please check with your team owner.
  t_You_don_t_have_this_feature: You don't have this feature
  t_Your_team_does_not_seem_to_have_access_to_this_feature_d_Get_in_touch_with_us_if_you_would_like_to_add_this_to_your_plan_d: Your
    team does not seem to have access to this feature. Get in touch with us if you
    would like to add this to your plan.
  t_Not_found: Not found
  t_We_could_not_find_this_page_d_Please_contact_us_if_you_think_this_is_an_error_d: We
    could not find this page. Please contact us if you think this is an error.
  t_Call_ended: Call ended
  t_This_audio_call_has_now_ended_d: This audio call has now ended.
  t_Connecting: Connecting
  t_You_are_being_connected_to_the_agent_through_our_secure_server_d: You are being
    connected to the agent through our secure server.
  t_You_need_to_allow_access_to_your_microphone_to_speak_with_the_agent_d: You need
    to allow access to your microphone to speak with the agent.
  t_Please_grant_access_by_clicking_the_microphone_icon_in_the_URL_bar_d: Please grant
    access by clicking the microphone <icon /> in the URL bar.
  t_Try_again: Try again
  t_Please_grant_access_to_continue_d: Please grant access to continue.
  t_Connected: Connected
  t_You_are_speaking_to_the_agent_d: You are speaking to the agent.
  t_Microphone: Microphone
  t_Speaker: Speaker
  t_Mute: Mute
  t_Unmute: Unmute
  t_Hang_up: Hang up
  t_No_speaker_available: No speaker available
  t_You_do_not_have_any_speaker_connected_to_your_computer_d: You do not have any
    speaker connected to your computer.
  t_Please_connect_a_headset_to_continue_d: Please connect a headset to continue.
  t_No_microphone_available: No microphone available
  t_You_do_not_have_any_microphone_connected_to_your_computer_d: You do not have any
    microphone connected to your computer.
  t_Backend_error: Backend error
  t_An_error_occurred_d_Please_refresh_the_page_d: An error occurred. Please refresh
    the page.
  t_Change_your_email_to_new_email_on_Upscope: Change your email to %{new_email} on
    Upscope
  t_With_the_button_below_you_can_change_your_Upscope_email_to_new_email__d: With
    the button below you can change your Upscope email to %{new_email}.
  t_We_take_this_extra_step_to_ensure_no_one_steals_your_account_d: We take this extra
    step to ensure no one steals your account.
  t_Change_email: Change email
  t_If_you_haven_t_requested_this_email_change_please_get_in_touch_with_us_immediately_by_replying_to_this_email_d: If
    you haven't requested this email change, please get in touch with us immediately
    by replying to this email.
  t_Take_care: Take care,
  t_Delete_your_visitors_data_on_Upscope: Delete your visitors data on Upscope
  t_We_have_received_your_request_to_delete_all_your_visitors_data_on_Upscope: We
    have received your request to delete all your visitors data on Upscope
  t_You_will_need_to_click_on_the_button_within_24_hours_before_it_is_deactivated_d: You
    will need to click on the button within 24 hours before it is deactivated.
  ? t_This_action_will_not_affect_online_visitors_and_will_not_prevent_visitors_data_to_be_collected_in_the_future_if_Upscope_was_still_installed_d
  : This action will not affect online visitors and will not prevent visitors data
    to be collected in the future if Upscope was still installed.
  t_Delete_all_visitors_data: Delete all visitors data
  t_We_re_sorry_to_see_you_go_d_You_can_delete_your_Upscope_team_b_domain_b_by_clicking_the_button_below_d: We're
    sorry to see you go. You can delete your Upscope team <b>%{domain}</b> by clicking
    the button below.
  t_Delete_domain: Delete %{domain}
  t_Disable_your_Upscope_MFA_devices: Disable your Upscope MFA devices
  t_We_ve_received_a_request_to_disable_your_Upscope_MFA_device_d: We've received
    a request to disable your Upscope MFA device.
  t_You_will_need_to_click_on_the_button_below_within_1_hour_to_disable_it_d: You
    will need to click on the button below within 1 hour to disable it.
  t_If_you_did_not_request_this_someone_likely_has_access_to_your_account_and_you_should_contact_us_immediately_d: If
    you did not request this, someone likely has access to your account, and you should
    contact us immediately.
  t_We_ve_received_a_request_to_set_a_password_for_your_Upscope_account_d: We've received
    a request to set a password for your Upscope account.
  t_To_enable_the_password_please_click_the_button_below_within_24_hours_d: To enable
    the password, please click the button below within 24 hours.
  t_Enable_my_password: Enable my password
  t_Invite_name_to_Upscope: Invite %{name} to Upscope
  t_name_email_email_is_asking_to_join_your_Upscope_team_d_If_you_d_like_to_invite_them_please_click_below: "%{name}
    (email %{email}) is asking to join your Upscope team. If you'd like to invite
    them, please click below:"
  t_Invite_name: Invite %{name}
  t_If_you_have_any_question_about_Upscope_please_reply_to_this_email_d: If you have
    any question about Upscope, please reply to this email.
  t_You_are_ready_to_join_domain_on_Upscope_d: You are ready to join %{domain} on
    Upscope.
  t_All_you_have_to_do_is_click_the_button_below_and_we_ll_create_an_account_right_away_d: All
    you have to do is click the button below, and we'll create an account right away.
  t_Join_domain: Join %{domain}
  t_With_the_following_link_you_ll_be_able_to_log_in_to_your_Upscope_account_d: With
    the following link you'll be able to log in to your Upscope account.
  t_Did_you_know_q_You_can_now_set_a_password_to_access_your_account_d_Just_head_over_to_settings_d: Did
    you know? You can now set a password to access your account. Just head over to
    settings.
  t_integration_type_for_Upscope_authorized: "%{integration_type} for Upscope authorized"
  t_A_short_email_to_confirm_you_ve_successfully_connected_your_Upscope_account_to_integration_type__d: 'A
    short email to confirm you''ve successfully connected your Upscope account to
    #{@integration_type}.'
  t_You_can_log_out_at_any_time_from_your_personal_settings_d: You can log out at
    any time from your personal settings.
  t_Hey_name: Hey %{name},
  t_Hey_there: Hey there,
  t_Thank_you_for_signing_up_for_Upscope_d_Please_confirm_your_email_by_clicking_the_button_below_d: Thank
    you for signing up for Upscope. Please confirm your email by clicking the button
    below.
  t_We_re_excited_to_have_you_onboard_e: We're excited to have you onboard!
  t_Activate_my_account: Activate my account
  t_This_is_a_quick_reminder_to_finish_setting_up_your_Upscope_account_d_You_can_learn_more_here: 'This
    is a quick reminder to finish setting up your Upscope account. You can learn more
    here:'
  t_Click_below_to_create_your_account_d: Click below to create your account.
  t_You_recently_requested_to_change_your_email_on_Upscope_from_email_to_new_email__d: You
    recently requested to change your email on Upscope from %{email} to %{new_email}.
  t_Please_confirm_below_that_this_is_your_email_d: Please confirm below that this
    is your email.
  t_Verify_email: Verify email
  t_External_integration_Pending: External integration (Pending)
  t_Upscope_for_client_type: Upscope for %{client_type}
  t_Integration_access_token: Integration access token
  t_Authorize_access: Authorize access
  t_Do_you_want_to_authorize_access_to_your_account_q: Do you want to authorize access
    to your account?
  t_Do_not_share_this_code_with_anyone_d: Do not share this code with anyone.
  t_This_token_authorizes_access_to_your_account_d_Do_not_share_it_with_anyone_d: This
    token authorizes access to your account. Do not share it with anyone.
  t_Our_team_will_NEVER_ask_you_for_this_code_d_If_they_do_you_are_being_scammed_d: Our
    team will NEVER ask you for this code. If they do, you are being scammed.
  t_Our_team_will_NEVER_ask_you_for_this_code_d: Our team will NEVER ask you for this
    code.
  t_The_code_is_not_valid_d: The code is not valid.
  t_Login_error: Login error
  t_Please_allow_popups_to_log_in_d: Please allow popups to log in.
  t_Enter_authentication_code: Enter authentication code
  t_Sales: Sales
  t_1_month: 1 month
  t_months_months: "%{months} months"
  t_1_week: 1 week
  t_weeks_weeks: "%{weeks} weeks"
  t_1_day: 1 day
  t_days_days: "%{days} days"
  t_Opportunities: Opportunities
  t_Tasks: Tasks
  t_New_Leads: New Leads
  t_The_number_of_hours_is_invalid: The number of hours is invalid
  t_Your_session_time_credit_is_running_low: Your session time credit is running low
  t_You_are_running_out_of_session_time_credit: You are running out of session time
    credit
  t_Hello: Hello
  t_It_looks_like_we_were_not_able_to_top_up_your_session_time_credit_d_You_now_have_credit_time_left_d: It
    looks like we were not able to top up your session time credit. You now have %{credit}
    time left.
  t_It_looks_like_you_are_running_out_of_session_time_credit_d_You_now_have_credit_time_left_d: It
    looks like you are running out of session time credit. You now have %{credit}
    time left.
  t_Please_top_up_your_session_time_credit_to_avoid_any_interruption_to_your_service_d: Please
    top up your session time credit to avoid any interruption to your service.
  t_Top_up_session_time_credit: Top up session time credit
  t_Let_us_know_if_you_need_any_help_d: Let us know if you need any help.
  t_Session_credit: Session credit
  t_You_have_credit_session_time_credit_left_d: You have %{credit} session time credit
    left.
  t_Your_session_time_credit_will_be_automatically_topped_up_when_it_reaches_trigger_hours_or_less_with_amount_hours_d: Your
    session time credit will be automatically topped up when it reaches %{trigger}
    hours or less, with %{amount} hours.
  t_You_have_ran_out_of_session_time_credit_d_You_can_purchase_more_below_d: You have
    ran out of session time credit. You can purchase more below.
  t_Save: Save
  t_Team_Management: Team Management
  t_Team_Members: Team Members
  t_Invites: Invites
  t_Team_Member_Invite_Resent: Team Member Invite Resent
  t_The_team_member_invite_has_been_successfully_resent_d: The team member invite
    has been successfully resent.
  t_Error_resending_team_member_invite: Error resending team member invite
  t_We_are_having_trouble_resending_the_team_member_invite_d_Please_try_again_later_d: We
    are having trouble resending the team member invite. Please try again later.
  t_Team_Member_Invite_Revoked: Team Member Invite Revoked
  t_The_team_member_invite_has_been_successfully_revoked_d: The team member invite
    has been successfully revoked.
  t_Error_revoking_team_member_invite: Error revoking team member invite
  t_We_are_having_trouble_revoking_the_team_member_invite_d_Please_try_again_later_d: We
    are having trouble revoking the team member invite. Please try again later.
  t_Invite_Team_Members: Invite Team Members
  t_Invite_team_members_to_your_team_d_They_will_receive_an_email_with_a_link_to_join_your_team_d: Invite
    team members to your team. They will receive an email with a link to join your
    team.
  t_Send_invite: Send invite
  t_Sent: Sent
  t_Permissions: Permissions
  t_Send_again: Send again
  t_Revoke: Revoke
  t_No_invites_yet_d: No invites yet.
  t_Remove_team_member: Remove team member
  t_Remove: Remove
  t_Team_Member_Deleted: Team Member Deleted
  t_The_team_member_has_been_successfully_deleted_from_your_team_d: The team member
    has been successfully deleted from your team.
  t_Error_deleting_team_member: Error deleting team member
  t_We_are_having_trouble_deleting_the_team_member_d_Please_try_again_later_d: We
    are having trouble deleting the team member. Please try again later.
  t_Team_Member_Logged_Out: Team Member Logged Out
  t_The_team_member_has_been_successfully_logged_out_d: The team member has been successfully
    logged out.
  t_Error_logging_out_team_member: Error logging out team member
  t_We_are_having_trouble_logging_out_the_team_member_d_Please_try_again_later_d: We
    are having trouble logging out the team member. Please try again later.
  t_Team_Member_Password_Removed: Team Member Password Removed
  t_The_team_member_s_password_has_been_successfully_removed_d: The team member's
    password has been successfully removed.
  t_Error_removing_team_member_password: Error removing team member password
  t_We_are_having_trouble_removing_the_team_member_s_password_d_Please_try_again_later_d: We
    are having trouble removing the team member's password. Please try again later.
  t_Team_Member_MFA_Removed: Team Member MFA Removed
  t_The_team_member_s_MFA_has_been_successfully_removed_d: The team member's MFA has
    been successfully removed.
  t_Error_removing_team_member_MFA: Error removing team member MFA
  t_We_are_having_trouble_removing_the_team_member_s_MFA_d_Please_try_again_later_d: We
    are having trouble removing the team member's MFA. Please try again later.
  t_Transfer_team_ownership: Transfer team ownership
  t_Transfer: Transfer
  t_Team_ownership_transferred: Team ownership transferred
  t_You_have_transferred_ownership_of_the_team_to_your_colleague_d: You have transferred
    ownership of the team to your colleague.
  t_Error_transferring_team_ownership: Error transferring team ownership
  t_We_are_having_trouble_transferring_team_ownership_d_Please_try_again_later_d: We
    are having trouble transferring team ownership. Please try again later.
  t_Name: Name
  t_Never: Never
  t_Remote_log_out: Remote log out
  t_Remove_password: Remove password
  t_Remove_MFA: Remove MFA
  t_Transfer_ownership: Transfer ownership
  t_Remove_from_team: Remove from team
  t_No_members_found_d: No members found.
  t_This_user_can_view_a_list_of_visitors_connected_to_the_website_d: This user can
    view a list of visitors connected to the website.
  t_This_user_cannot_view_a_list_of_visitors_connected_to_the_website_d: This user
    cannot view a list of visitors connected to the website.
  t_This_user_can_initiate_cobrowse_sessions_d: This user can initiate cobrowse sessions.
  t_This_user_cannot_initiate_cobrowse_sessions_d: This user cannot initiate cobrowse
    sessions.
  t_This_user_can_access_the_team_settings_d: This user can access the team settings.
  t_This_user_cannot_access_the_team_settings_d: This user cannot access the team
    settings.
  t_This_user_can_manage_other_team_members_d: This user can manage other team members.
  t_This_user_cannot_manage_other_team_members_d: This user cannot manage other team
    members.
  t_This_user_can_manage_the_team_s_billing_d: This user can manage the team's billing.
  t_This_user_cannot_manage_the_team_s_billing_d: This user cannot manage the team's
    billing.
  t_This_user_can_access_the_team_s_activity_log_d: This user can access the team's
    activity log.
  t_This_user_cannot_access_the_team_s_activity_log_d: This user cannot access the
    team's activity log.
  t_This_user_can_use_the_console_d: This user can use the console.
  t_This_user_cannot_use_the_console_d: This user cannot use the console.
  t_This_user_can_delete_visitor_data_d: This user can delete visitor data.
  t_This_user_cannot_delete_visitor_data_d: This user cannot delete visitor data.
  t_This_user_can_view_reporting_d: This user can view reporting.
  t_This_user_cannot_view_reporting_d: This user cannot view reporting.
  t_needs_to_start_with_http_or_https: needs to start with http:// or https://
  t_needs_to_be_set: needs to be set
  t_Your_users_can_also_sign_up_on_their_own_d: Your users can also sign up on their
    own.
  t_Your_team_has_Single_Sign_On_enabled_d_Any_person_in_your_team_can_sign_up_on_their_own_at_the_link_below_d: Your
    team has Single Sign On enabled. Any person in your team can sign up on their
    own at the link below.
  t_Any_person_with_a_domain_email_can_sign_up_on_their_own_at_the_link_below_d: Any
    person with a @%{domain} email can sign up on their own at the link below.
  t_They_will_be_added_to_your_team_as_a_regular_user_d: They will be added to your
    team as a regular user.
  t_Invite_request_link: Invite request link
  t_If_you_don_t_know_who_needs_access_to_Upscope_share_the_following_link_where_they_can_request_access_d: If
    you don't know who needs access to Upscope, share the following link, where they
    can request access.
  t_Confirm_transfer_q: Confirm transfer?
  t_Warning_e_There_is_no_undo_for_this_action_d_Please_make_sure_you_really_want_to_transfer_team_ownership_to_this_user_d: Warning!
    There is no undo for this action. Please make sure you really want to transfer
    team ownership to this user.
  t_Please_use_a_business_Google_account_to_sign_up_d: Please use a business Google
    account to sign up.
  t_needs_to_be_at_least_32_characters_long: needs to be at least 32 characters long
  t_must_be_in_the_future: must be in the future
  t_This_user_can_use_the_Upscope_s_main_functionalities_d: This user can use the
    Upscope's main functionalities.
  t_This_user_cannot_use_the_Upscope_s_main_functionalities_d: This user cannot use
    the Upscope's main functionalities.
  t_This_user_can_edit_the_content_stored_in_Upscope_d: This user can edit the content
    stored in Upscope.
  t_This_user_cannot_edit_the_content_stored_in_Upscope_d: This user cannot edit the
    content stored in Upscope.
  t_This_user_can_view_reporting_and_logs_d: This user can view reporting and logs.
  t_This_user_cannot_view_reporting_and_logs_d: This user cannot view reporting and
    logs.
  t_New_link: New link
  t_Links: Links
  t_Templates: Templates
  t_Other_tabs: Other tabs
  t_You_are_muted: You are muted
  t_Click_to_mute_yourself: Click to mute yourself
  t_Select_input_device: Select input device
  t_Select_output_device: Select output device
  t_Waiting_for_the_audio_interface_to_connect: Waiting for the audio interface to
    connect
  t_Waiting_for_the_user_to_accept_the_call: Waiting for the user to accept the call
  t_Waiting_for_the_user_to_authorize_microphone_and_speaker_access: Waiting for the
    user to authorize microphone and speaker access
  t_Cursor_not_visible_when_remote_control_not_enabled: Cursor not visible when remote
    control not enabled
  t_Request_control: Request control
  t_Start_audio: Start audio
  t_End_audio: End audio
  t_Slow_connection_d_Expect_up_to_seconds_seconds_lag_d: |-
    Slow connection.
    Expect up to %{seconds} seconds lag.
  t_Connecting_to_visitor_s_browser: Connecting to visitor's browser
  t_Waiting_for_visitor_to_accept: Waiting for visitor to accept
  t_Waiting_for_first_frame_from_visitor_s_browser: Waiting for first frame from visitor's
    browser
  t_Loading_images_and_other_assets: Loading images and other assets
  t_Connecting_securely_to_visitor_s_browser: Connecting securely to visitor's browser
  t_Waiting_for_visitor_to_accept_co_browsing_request: Waiting for visitor to accept
    co-browsing request
  t_Issue_with_user_s_microphone: Issue with user's microphone
  t_We_could_not_detect_a_valid_input_device_for_the_user_d_Perhaps_they_have_no_microphone_connected_q: We
    could not detect a valid input device for the user. Perhaps they have no microphone
    connected?
  t_Issue_with_user_s_speakers: Issue with user's speakers
  t_We_could_not_detect_a_valid_output_device_for_the_user_d_Perhaps_they_have_no_speaker_connected_q: We
    could not detect a valid output device for the user. Perhaps they have no speaker
    connected?
  t_Issue_with_user_s_phone: Issue with user's phone
  t_The_user_accepted_the_call_but_their_browser_did_not_connect_d_Try_calling_again_and_ask_them_to_ensure_popups_are_enabled_d: The
    user accepted the call, but their browser did not connect. Try calling again and
    ask them to ensure popups are enabled.
  t_User_did_not_authorize_audio: User did not authorize audio
  t_The_user_did_not_authorize_audio_when_prompted_in_the_audio_call_popup_d_Make_sure_they_authorize_audio_d: The
    user did not authorize audio when prompted in the audio call popup. Make sure
    they authorize audio.
  t_Issue_with_your_microphone: Issue with your microphone
  t_We_could_not_detect_a_valid_input_device_for_you_d_Perhaps_you_have_no_microphone_connected_q: We
    could not detect a valid input device for you. Perhaps you have no microphone
    connected?
  t_Issue_with_your_speakers: Issue with your speakers
  t_We_could_not_detect_a_valid_output_device_for_you_d_Perhaps_you_have_no_speaker_connected_q: We
    could not detect a valid output device for you. Perhaps you have no speaker connected?
  t_You_did_not_authorize_audio: You did not authorize audio
  t_Please_make_sure_you_authorize_audio_in_your_browser_to_make_calls_d: Please make
    sure you authorize audio in your browser to make calls.
  t_Remote_control_q: Remote control?
  t_Would_you_like_to_ask_to_control_screen_q: Would you like to ask to control screen?
  t_Ask_for_control: Ask for control
  t_Remote_control_not_allowed: Remote control not allowed
  t_You_are_not_allowed_to_control_this_element: You are not allowed to control this
    element
  t_Something_went_wrong: Something went wrong
  t_Something_went_wrong_while_cobrowsing_d: Something went wrong while cobrowsing.
  t_The_user_is_not_currently_connected_d: The user is not currently connected.
  t_Maybe_they_went_to_a_page_where_co_browsing_is_not_available_d: Maybe they went
    to a page where co-browsing is not available.
  t_Notes: Notes
  t_User_information: User information
  t_This_information_is_sent_from_the_user_s_browser_and_shouldn_t_be_considered_secure_d: This
    information is sent from the user's browser and shouldn't be considered secure.
  t_Your_notes: Your notes
  t_Your_notes_here_d_d_d: Your notes here...
  t_Move_your_cursor_to_stay_connected: Move your cursor to stay connected
  t_You_will_be_disconnected_soon_if_you_do_not_move_your_cursor_d: You will be disconnected
    soon if you do not move your cursor.
  t_Team_billing_Management: Team billing Management
  t_Call_Credit: Call Credit
  t_Billing_History: Billing History
  t_Session_Credit: Session Credit
  t_cost_per_period: "%{cost} per %{period}"
  t_Other_teams: Other teams
  t_Create_a_new_team: Create a new team
  t_Create_a_new_team_to_separate_your_users_and_billing_d: Create a new team to separate
    your users and billing.
  t_There_was_an_error_saving_your_settings_d_Please_try_again_later_d: There was
    an error saving your settings. Please try again later.
  t_Open_in_picture_in_picture: Open in picture in picture
  t_Celebrate_with_some_confetti: Celebrate with some confetti
  t_Return_to_main_window: Return to main window
  t_Activity_Log: Activity Log
  t_Global_Activity_Log: Global Activity Log
  t_Invalid_file_type: Invalid file type
  t_Drop_file_here: Drop file here
  t_The_next_person_who_opens_the_link_will_need_to_enter_their_email_before_viewing_the_document_d: The
    next person who opens the link will need to enter their email before viewing the
    document.
  t_The_next_person_who_opens_the_link_will_not_need_to_enter_their_email_before_viewing_the_document_d: The
    next person who opens the link will not need to enter their email before viewing
    the document.
  t_The_link_can_only_be_viewed_until_expiration__d: The link can only be viewed until
    %{expiration}.
  t_The_link_does_not_expire_d: The link does not expire.
  t_Create_link: Create link
  t_You_do_not_have_cobrowsing_access_for_any_of_your_teams_d: You do not have cobrowsing
    access for any of your teams.
  t_This_URL_is_already_in_use_for_a_different_link_d: This URL is already in use
    for a different link.
  t_Live_Document_not_found: Live Document not found
  t_We_could_not_find_this_document_d_Make_sure_you_have_the_correct_link_or_ask_the_sender_to_resend_it_d: We
    could not find this document. Make sure you have the correct link, or ask the
    sender to resend it.
  t_Live_Document_expired: Live Document expired
  t_This_document_has_now_expired_d_You_can_ask_the_sender_to_resend_it_d: This document
    has now expired. You can ask the sender to resend it.
  t_Just_now: Just now
  t_This_is_you: This is you
  t_Team_owner: Team owner
  t_Please_authorize_camera_access: Please authorize camera access
  t_Please_authorize_camera_access_to_start_recording_video_d: Please authorize camera
    access to start recording video.
  t_Error_recording_video: Error recording video
  t_Please_make_sure_you_have_a_working_camera_and_microphone_and_try_again_d: Please
    make sure you have a working camera and microphone and try again.
  t_Cancel_recording: Cancel recording
  t_Are_you_sure_you_want_to_cancel_recording_this_video_q: Are you sure you want
    to cancel recording this video?
  t_Error_undoing: Error undoing
  t_There_is_an_issue_with_the_undo_function_d_Please_record_the_video_again_d_Sorry_for_the_inconvenience_d: There
    is an issue with the undo function. Please record the video again. Sorry for the
    inconvenience.
  t_Starting_over_in: Starting over in
  t_Recording_in: Recording in
  t_Continuing_in: Continuing in
  t_Error_processing_video: Error processing video
  t_Unfortunately_the_video_processing_did_not_work_d_Please_try_again_or_contact_our_team_d: Unfortunately,
    the video processing did not work. Please try again or contact our team.
  t_Video_added_successfully_d: Video added successfully.
  t_Replace_video: Replace video
  t_Record_video: Record video
  t_Your_subscription_has_ended: Your subscription has ended
  ? t_Your_subscription_has_now_ended_d_Please_get_in_touch_with_our_sales_sales_team_sales_or_purchase_purchase_a_new_plan_purchase_to_reactivate_it_d
  : Your subscription has now ended. Please get in touch with our <sales>sales team</sales>
    or <purchase>purchase a new plan</purchase> to reactivate it.
  t_Cannot_see_Live_Document: Cannot see Live Document
  t_Please_log_in: Please log in
  t_This_document_is_not_shared_with_the_public_d_Please_check_sharing_permissions_with_the_sender_d: This
    document is not shared with the public. Please check sharing permissions with
    the sender.
  t_You_might_need_to_log_in_to_view_this_document_d: You might need to log in to
    view this document.
  t_Create_your_product_account: Create your %{product} account
  t_There_is_an_issue_with_processing_your_video_d_Please_record_the_video_again_d_Sorry_for_the_inconvenience_d: There
    is an issue with processing your video. Please record the video again. Sorry for
    the inconvenience.
  t_Go_back_a_bit: Go back a bit
  t_Pause_recording: Pause recording
  t_Resume_recording: Resume recording
  t_Complete_recording: Complete recording
  t_Processing_completed_d_Uploading_to_the_server_d_d_d: Processing completed. Uploading
    to the server...
  t_Video_uploaded_successfully_d: Video uploaded successfully.
  t_A_new_version_is_available: A new version is available
  t_A_new_version_of_the_application_is_available_and_we_need_to_reload_the_page_for_it_to_work_d: A
    new version of the application is available and we need to reload the page for
    it to work.
  t_Reload_application: Reload application
  t_Video_being_processed: Video being processed
  t_Video_processing: Video processing
  t_Uploading_video_d_d_d: Uploading video...
  t_Recording_ended: Recording ended
  t_Your_video_recording_has_now_ended_d_We_will_upload_it_and_process_it_on_our_server_d: Your
    video recording has now ended. We will upload it and process it on our server.
  t_Only_5_minutes_left: Only 5 minutes left
  t_We_will_need_to_stop_recording_in_a_few_minutes_so_the_video_does_not_get_too_large_d: We
    will need to stop recording in a few minutes, so the video does not get too large.
  t_Continue: Continue
  t_Link_updated_successfully: Link updated successfully
  t_Delete_link: Delete link
  t_Are_you_sure_you_want_to_delete_this_link_q: Are you sure you want to delete this
    link?
  t_Document_in_video: Document in video
  t_This_document_is_used_in_the_video_d_Removing_it_will_also_remove_the_video_d: This
    document is used in the video. Removing it will also remove the video.
  t_Delete_document_and_video: Delete document and video
  t_Save_changes: Save changes
  t_Delete_video: Delete video
  t_Are_you_sure_you_want_to_delete_the_video_q: Are you sure you want to delete the
    video?
  t_Delete: Delete
  t_Link_deleted_successfully: Link deleted successfully
  t_No_sessions_yet: No sessions yet
  t_No_viewers_yet_d_d_d: No viewers yet...
  t_Picture_in_picture: Picture in picture
  t_Remote_typing: Remote typing
  t_Remote_click: Remote click
  t_Remote_scroll: Remote scroll
  t_Audio_call: Audio call
  t_Remote_developer_console: Remote developer console
  t_Agent_notes: Agent notes
  t_Drawing: Drawing
  t_Remote_pointer: Remote pointer
  t_Universal_proxy: Universal proxy
  t_Remote_cursor: Remote cursor
  t_and: ", and "
  t_sessions: sessions
  t_Visitor_session_quality_feedback: Visitor session quality feedback
  t_Sign_up_with_Google: Sign up with Google
  t_Co_browsing_History: Co-browsing History
  t_1_hour: 1 hour
  t_hours_hours: "%{hours} hours"
  t_1_minute: 1 minute
  t_minutes_minutes: "%{minutes} minutes"
  t_New_LiveDocument: New LiveDocument
  t_Create: Create
  t_is_invalid: is invalid
  t_Replace_documents_q: Replace documents?
  ? t_Some_of_your_documents_look_like_they_might_be_a_new_version_of_existing_ones_d_Do_you_want_to_update_them_or_add_them_as_new_ones_q
  : Some of your documents look like they might be a new version of existing ones.
    Do you want to update them or add them as new ones?
  t_Replace_existing: Replace existing
  t_Add_new_documents: Add new documents
  t_Share_LiveDocument: Share LiveDocument
  t_Send: Send
  t_Please_select_at_least_one_user_to_share_with_d: Please select at least one user
    to share with.
  t_Message_is_too_long_d: Message is too long.
  t_Shared: Shared
  t_We_have_sent_a_notification_with_the_LiveDocument_link_d: We have sent a notification
    with the LiveDocument link.
  t_LiveDocument_settings: LiveDocument settings
  t_These_are_your_personal_settings_for_everything_LiveDocument_related_d: These
    are your personal settings for everything LiveDocument related.
  t_Email_me_when_there_is_a_new_viewer: Email me when there is a new viewer
  t_Email_me_for_every_new_view_even_from_past_viewers: Email me for every new view
    (even from past viewers)
  t_Save_preferences: Save preferences
  t_Send_me_a_daily_summary_when_there_are_new_views: Send me a daily summary when
    there are new views
  t_You_can_connect_Upscope_with_one_of_our_partners_below_to_enable_SSO_and_additional_functions_d: You
    can connect Upscope with one of our partners below to enable SSO and additional
    functions.
  t_Setup: Setup
  t_Your_Integrations: Your Integrations
  t_Actions: Actions
  t_Remove_integration: Remove integration
  t_Are_you_sure_q: Are you sure?
  t_Disable_SSO: Disable SSO
  t_Any_user_who_signed_up_through_this_integration_will_be_deleted_immediately: Any
    user who signed up through this integration will be deleted immediately
  t_Enable_SSO: Enable SSO
  t_Start_co_browsing_from_conversations_see_screenshots_log_sessions_in_conversations_SSO_provider_d: Start
    co-browsing from conversations, see screenshots, log sessions in conversations,
    SSO provider.
  t_Start_co_browsing_from_Livechat_and_see_screenshots_d: Start co-browsing from
    Livechat, and see screenshots.
  t_SSO_Provider: SSO Provider
  t_Connect_Upscope_to_everything_else_d: Connect Upscope to everything else.
  t_Start_co_browsing_from_conversations_and_see_screenshots_d: Start co-browsing
    from conversations, and see screenshots.
  t_Start_co_browsing_from_freshchat_and_see_screenshots: Start co-browsing from freshchat
    and see screenshots
  t_Start_sessions_from_Salesforce_log_sessions_SSO_provider_d: Start sessions from
    Salesforce, log sessions, SSO provider.
  t_must_be_an_object_with_a_single_key: must be an object with a single key
  t_must_contain_an_object: must contain an object
  t_must_be_a_flat_object: must be a flat object
  t_must_contain_at_least_one_reference_field_starts_with_sfdc: must contain at least
    one reference field (starts with =sfdc)
  t_does_not_recognize_field_field: does not recognize field %{field}
  t_is_not_a_valid_JSON: is not a valid JSON
  t_Start_co_browsing_from_tickets_and_chats_and_see_screenshots_d: Start co-browsing
    from tickets and chats, and see screenshots.
  t_Connect_to_Freshchat: Connect to Freshchat
  t_Connect_Upscope_to_your_Freshchat_to_start_a_session_directly_from_a_Freshchat_chat_d: Connect
    Upscope to your Freshchat to start a session directly from a Freshchat chat.
  t_Install_the_Upscope_App: Install the Upscope App
  t_Find_Upscope_on_the_Freshchat_Marketplace_and_install_Upscope_for_chat_d: Find
    Upscope on the Freshchat Marketplace and install Upscope for chat.
  t_Open_Freshchat_Marketplace: Open Freshchat Marketplace
  t_Add_the_token_below: Add the token below
  t_When_asked_for_a_token_on_the_Freshchat_website_copy_and_paste_the_one_below_and_complete_the_installation_d: When
    asked for a token on the Freshchat website, copy and paste the one below and complete
    the installation.
  t_Create_the_upscope_screenshare_custom_property: Create the upscope screenshare
    custom property
  ? t_Within_Freshworks_go_to_your_bold_Admin_Settings_Contacts_bold_under_Basic_information_create_a_new_text_field_called_code_upscope_screenshare_code
  : Within Freshworks go to your <bold>Admin Settings » Contacts</bold>, under Basic
    information create a new text field called <code>upscope_screenshare</code>
  t_Front_integration_was_setup_successfully: Front integration was setup successfully
  t_Connect_to_Front: Connect to Front
  t_Connect_Upscope_to_your_Frontapp_to_start_a_session_directly_from_a_Frontapp_chat_d: Connect
    Upscope to your Frontapp to start a session directly from a Frontapp chat.
  t_Go_to_your_Frontapp_account: Go to your Frontapp account
  t_Install_Upscope_on_the_Frontapp_integrations_d: Install Upscope on the Frontapp
    integrations.
  t_Open_Frontapp_Integrations: Open Frontapp Integrations
  t_Go_to_settings: Go to settings
  t_Click_on_Enabled_and_copy_the_Secret_Key_below: Click on Enabled and copy the
    Secret Key below
  t_Front_secret_key: Front secret key
  t_Done: Done
  t_Created_At: Created At
  t_Customize: Customize
  t_Integration_removed_successfully: Integration removed successfully
  t_Something_went_wrong_please_try_again: Something went wrong, please try again
  t_SSO_Enabled: SSO Enabled
  t_Intercom_settings_updated_successfully: Intercom settings updated successfully
  t_Back_to_Integrations: Back to Integrations
  t_Add_session_summary_to_intercom_conversation_as_a_note_q: Add session summary
    to intercom conversation as a note?
  t_Permission_prompt: Permission prompt
  t_The_permission_prompt_can_be_added_within_the_chat_interface_to_ask_the_visitor_for_permission_before_initiating_a_session_d: The
    permission prompt can be added within the chat interface to ask the visitor for
    permission before initiating a session.
  t_Prompt_title: Prompt title
  t_Co_browsing_request: Co-browsing request
  t_Prompt_message: Prompt message
  t_Would_you_like_to_allow_screensharing_q: Would you like to allow screensharing?
  t_Accept_button: Accept button
  t_Refuse_button: Refuse button
  t_Prompt_explanation: Prompt explanation
  t_No_installs_or_downloads_required_and_limited_to_the_current_tab_d: No installs
    or downloads required and limited to the current tab.
  t_Co_browsing_start_message: Co-browsing start message
  t_Screen_sharing_will_begin_shortly_d: Screen sharing will begin shortly.
  t_Update: Update
  t_Salesforce_settings_updated_successfully: Salesforce settings updated successfully
  t_Create_an_object_in_Salesforce_from_the_session_q: Create an object in Salesforce
    from the session?
  ? t_You_can_map_fields_for_the_call_by_changing_the_default_here_d_Please_contact_our_team_for_assistance_d_You_can_use_the_following_special_values_code_sfdc_contact_id_sfdc_lead_id_sfdc_case_id_sfdc_account_id_sfdc_admin_id_sfdc_target_id_started_at_ended_at_information_video_url_code
  : 'You can map fields for the call by changing the default here. Please contact
    our team for assistance. You can use the following special values: <code>=sfdc_contact_id
    =sfdc_lead_id =sfdc_case_id =sfdc_account_id =sfdc_admin_id =sfdc_target_id =started_at
    =ended_at =information =video_url</code>'
  t_Field_mapping: Field mapping
  t_Embed_Upscope_in_Salesforce: Embed Upscope in Salesforce
  t_You_can_create_a_button_in_your_Case_view_on_Salesforce_to_start_a_session_with_one_click_and_log_it_as_call_d: You
    can create a button in your Case view on Salesforce to start a session with one
    click and log it as call.
  t_Code_for_visualforce_page: Code for visualforce page
  t_To_embed_Upscope_into_Salesforce_use_the_following_code: 'To embed Upscope into
    Salesforce, use the following code:'
  t_Connect_to_Twilio_Flex: Connect to Twilio Flex
  t_Connect_Upscope_to_your_Twilio_Flex_Plugin_to_start_a_session_directly_from_a_Twilio_Flex_Conversation_d: Connect
    Upscope to your Twilio Flex Plugin to start a session directly from a Twilio Flex
    Conversation.
  t_Setup_Authentication: Setup Authentication
  t_From_Twilio_Console_go_to_bold_Functions_Services_Create_Service_bold: From Twilio
    Console go to <bold>Functions > Services > Create Service</bold>
  t_Enter_a_Service_Name_and_click_Next: Enter a Service Name and click Next
  t_Click_bold_Add_Add_Function_bold: Click <bold>Add+ > Add Function</bold>
  t_Change_the_default_name_from_code_path_1_code_to_code_get_integration_token_code__d_Press_Enter_to_create_the_function_d: Change
    the default name from <code>/path_1</code> to <code>/get_integration_token</code>.
    Press Enter to create the function.
  t_Change_the_function_visibility_from_bold_Private_bold_to_bold_Public_bold__d: Change
    the function visibility from <bold>Private</bold> to <bold>Public</bold>.
  ? t_In_the_text_editor_on_the_right_paste_the_code_below_then_click_bold_Save_bold_then_click_on_bold_Copy_URL_bold_and_keep_this_value_for_later_d
  : In the text editor on the right, paste the code below then click <bold>Save</bold>,
    then click on <bold>Copy URL</bold> and keep this value for later.
  ? t_In_bold_Settings_Environment_Variables_bold_make_sure_to_check_bold_Add_my_Twilio_Credentials_ACCOUNT_SID_and_AUTH_TOKEN_to_ENV_bold_option_and_add_code_UPSCOPE_INTEGRATION_TOKEN_code_with_the_following_value
  : 'In <bold>Settings > Environment Variables</bold>, make sure to check <bold>Add
    my Twilio Credentials (ACCOUNT_SID) and (AUTH_TOKEN) to ENV</bold> option and
    add <code>UPSCOPE_INTEGRATION_TOKEN</code> with the following value:'
  t_In_bold_Settings_Dependencies_bold_add_the_following_dependencies_without_a_version_number: 'In
    <bold>Settings > Dependencies</bold>, add the following dependencies without a
    version number:'
  t_Click_bold_Deploy_All_bold: Click <bold>Deploy All</bold>
  t_Install_the_twilio_Flex_React_Component: Install the twilio Flex React Component
  t_Add_the_component_to_Flex: Add the component to Flex
  t_Property: Property
  t_Type: Type
  t_Description: Description
  t_React_Component_Key: React Component Key
  t_URL_of_your_Twilio_Flex_function_the_URL_you_copied_on_Step_1__d: URL of your
    Twilio Flex function (the URL you copied on Step 1).
  t_Set_to_code_production_code: Set to <code>production</code>
  t_Connect_to_Zapier: Connect to Zapier
  t_Go_to_Zapier: Go to Zapier
  t_Go_to_the_Zapier_App_and_look_for_the_Upscope_connection_d: Go to the Zapier App
    and look for the Upscope connection.
  t_Use_connection_key: Use connection key
  t_When_prompted_enter_the_following_connection_key_on_the_Zapier_connection_flow_d: When
    prompted, enter the following connection key on the Zapier connection flow.
  t_Connect_to_Zendesk: Connect to Zendesk
  t_Connect_Upscope_to_your_Zendesk_to_start_a_session_directly_from_a_Zendesk_chat_or_a_Zendesk_ticket_d: Connect
    Upscope to your Zendesk to start a session directly from a Zendesk chat or a Zendesk
    ticket.
  t_Install_the_Zendesk_App: Install the Zendesk App
  t_Find_Upscope_on_the_Zendesk_Marketplace_and_install_Upscope_for_chat_and_or_support_d: Find
    Upscope on the Zendesk Marketplace and install Upscope for chat and/or support.
  t_Open_Zendesk_Marketplace: Open Zendesk Marketplace
  t_When_asked_for_a_token_on_the_Zendesk_website_copy_and_paste_the_one_below_and_complete_the_installation_d: When
    asked for a token on the Zendesk website, copy and paste the one below and complete
    the installation.
  t_Document_sync_enabled: Document sync enabled
  t_The_document_is_currently_synced_to_the_video_d: The document is currently synced
    to the video.
  t_Disable_video_sync: Disable video sync
  t_Created: Created
  t_Last_used: Last used
  t_SSO_Disabled: SSO Disabled
  t_See_details: See details
  t_Manage: Manage
  t_Intercom_Integration: Intercom Integration
  t_All_integrations: All integrations
  t_Session_summary: Session summary
  t_Upscope_can_add_a_summary_of_each_session_to_the_conversation_after_the_session_is_over: Upscope
    can add a summary of each session to the conversation after the session is over
  t_Customize_the_permission_prompt_sent_to_the_user_within_the_chat_window: Customize
    the permission prompt sent to the user within the chat window
  t_HelloScreen_can_add_a_summary_of_each_session_to_the_conversation_after_the_session_is_over: HelloScreen
    can add a summary of each session to the conversation after the session is over
  t_Salesforce_Integration: Salesforce Integration
  t_Salesforce_Session_Object: Salesforce Session Object
  t_HelloScreen_can_create_a_Salesforce_object_from_the_session_data_d: HelloScreen
    can create a Salesforce object from the session data.
  ? t_You_can_map_fields_for_the_call_by_changing_the_default_here_d_Please_contact_our_team_for_assistance_d_You_can_use_the_following_special_values_code_sfdc_contact_id_code_code_sfdc_lead_id_code_code_sfdc_case_id_code_code_sfdc_account_id_code_code_sfdc_admin_id_code_code_sfdc_target_id_code_code_started_at_code_code_ended_at_code_code_information_code_code_video_url_code
  : 'You can map fields for the call by changing the default here. Please contact
    our team for assistance. You can use the following special values: <code>=sfdc_contact_id</code>,
    <code>=sfdc_lead_id</code>, <code>=sfdc_case_id</code>, <code>=sfdc_account_id</code>,
    <code>=sfdc_admin_id</code>, <code>=sfdc_target_id</code>, <code>=started_at</code>,
    <code>=ended_at</code>, <code>=information</code>, <code>=video_url</code>'
  t_Start_a_session_from_Salesforce: Start a session from Salesforce
  t_Add_a_button_to_a_Case_Customer_Account_or_Lead_view_to_instantly_start_a_co_browsing_session_d: Add
    a button to a Case, Customer, Account or Lead view to instantly start a co-browsing
    session.
  t_Button_link: Button link
  t_Use_this_link_for_the_button_to_start_the_session_from_the_details_of_the_object_it_is_added_to_d: Use
    this link for the button to start the session from the details of the object it
    is added to.
  t_Embed_HelloScreen_inside_Salesforce: Embed HelloScreen inside Salesforce
  t_To_embed_HelloScreen_inside_Salesforce_use_the_following_code_d: To embed HelloScreen
    inside Salesforce, use the following code.
  t_Freshchat_Integration: Freshchat Integration
  t_Front_Integration: Front Integration
  t_Front_already_connected: Front already connected
  t_If_you_need_to_change_your_Front_Secret_key_please_remove_this_integration_it_and_create_a_new_one_d: If
    you need to change your Front Secret key, please remove this integration it and
    create a new one.
  t_Twilio_Flex_Integration: Twilio Flex Integration
  t_Zapier_Integration: Zapier Integration
  t_Zendesk_Integration: Zendesk Integration
  t_count_days: "%{count} days"
  t_count_weeks: "%{count} weeks"
  t_count_months: "%{count} months"
  t_1_year: 1 year
  t_count_years: "%{count} years"
  t_years_years: "%{years} years"
  t_No_integrations_set_up_yet_d: No integrations set up yet.
  t_Screenshot_collection: Screenshot collection
  ? t_HelloScreen_can_collect_a_screenshot_right_before_a_user_starts_a_chat_conversation_d_This_can_help_you_understand_the_context_of_the_user_s_issue_d
  : HelloScreen can collect a screenshot right before a user starts a chat conversation.
    This can help you understand the context of the user's issue.
  t_Enable_automatic_screenshot_collection: Enable automatic screenshot collection
  t_Transfer_team_ownership_to_name: Transfer team ownership to %{name}
  t_Team_ownership_transfer_requested: Team ownership transfer requested
  t_You_will_receive_an_email_to_confirm_the_transfer_d: You will receive an email
    to confirm the transfer.
  t_You_requested_to_transfer_your_team_ownership_to_name: You requested to transfer
    your team ownership to %{name}
  t_Warning_e_There_is_no_undo_for_this_action_d_Please_make_sure_you_really_want_to_transfer_team_ownership_to_name: Warning!
    There is no undo for this action. Please make sure you really want to transfer
    team ownership to %{name}
  t_Click_below_to_confirm_the_transfer_ownership_of_the_team_team_to_name: 'Click
    below to confirm the transfer ownership of the team %{team} to %{name}:'
  t_Transfer_ownership_to_name: Transfer ownership to %{name}
  t_You_will_receive_an_email_in_the_next_hour_to_confirm_the_transfer_d: You will
    receive an email in the next hour to confirm the transfer.
  t_You_requested_to_transfer_your_team_ownership_to_name__d: You requested to transfer
    your team ownership to %{name}.
  t_The_zip_code_and_the_state_don_t_match: The zip code and the state don't match
  t_Confirm: Confirm
  t_Invalid_link: Invalid link
  t_The_link_you_clicked_on_is_wrong_or_expired: The link you clicked on is wrong
    or expired
  t_Return_home: Return home
  t_Make_sure_this_is_your_link: Make sure this is your link
  t_The_link_you_clicked_on_is_associated_with_a_different_account_d_Please_log_out_and_try_again_d: The
    link you clicked on is associated with a different account. Please log out and
    try again.
  t_Showing_your_screen: Showing your screen
  t_Show_your_screen: Show your screen
  t_Viewing_screen: Viewing screen
  t_Request_full_screen: Request full screen
  t_Viewing_browser: Viewing browser
  t_Request_browser: Request browser
  t_Other_agent_showing_screen: Other agent showing screen
  t_Stop_showing_your_screen: Stop showing your screen
  t_Show_your_screen_BETA: Show your screen (BETA)
  t_Request_full_screen_BETA: Request full screen (BETA)
  t_Accounts: Accounts
  t_Manage_Teams: Manage Teams
  t_New_team: New team
  t_Delete_team_and_account: Delete team and account
  t_Delete_team: Delete team
  t_Leave_team_and_delete_account: Leave team and delete account
  t_Leave_team: Leave team

  t_Sent_confetti: Sent confetti
  t_Used_URL_bar: Used URL bar
  t_Shared_agent_screen: Shared agent screen
  t_Viewed_visitor_browser: Viewed visitor browser
  t_Shared_visitor_screen: Shared visitor screen
  t_Wrong_link: Wrong link
  t_Sorry_this_link_was_meant_for_someone_else_d: Sorry, this link was meant for someone
    else.
  t_Already_logged_in: Already logged in
  t_You_are_already_logged_in_d: You are already logged in.
  t_Session_sharing_not_available: Session sharing not available
