import { z } from "zod";

import { Visitor } from "~cobrowsing/types/cobrowsing-objects";
import { PlanDetails } from "~data/PlanDetails";

import { zNoEmptyObject } from "../../utils";
import { zDay } from "../../utils/types";
import { AdminNoteVersion } from "../models/AdminNoteVersion";
import { Charge } from "../models/Charge";
import { Company, CompanyAdminViewData, CompanyLeadData } from "../models/Company";
import { Contact } from "../models/Contact";
import { CustomerSuccessCheckup } from "../models/CustomerSuccessCheckup";
import { Invoice } from "../models/Invoice";
import { AdminRoleKeys } from "../models/partials/AdminRole";
import { CustomerSuccessStage } from "../models/partials/CustomerSuccessStage";
import { Product } from "../models/partials/Product";
import { Plan } from "../models/Plan";
import { Quote, QuoteAdminSearchData } from "../models/Quote";
import { CobrowsingTeamUsage } from "../models/reporting/TeamUsage";
import { activityTypeKeys, SalesActivity } from "../models/SalesActivity";
import { SalesOpportunity, SalesOpportunityAdminSearchData } from "../models/SalesOpportunity";
import { SalesTask } from "../models/SalesTask";
import { Team, TeamAdminViewData, TeamBaseData, TeamCustomerSuccessData } from "../models/Team";
import { TeamMembershipTeamManagementData } from "../models/TeamMembership";
import { TrackedEmail } from "../models/TrackedEmail";
import { User, UserBaseData } from "../models/User";
import { ErrorResponse, Pagy, SuccessResponse } from "./standard-responses";

export const hqEndpoints = {
  "hq.search": () => ["hq", "get", "/search"],
  "hq.users.create": () => ["hq", "post", "/users"],
  "hq.users.update": (p: { userId: string }) => ["hq", "put", `/users/${p.userId}`],
  "hq.accounts.info": (p: { companyId: string }) => ["hq", "get", `/accounts/${p.companyId}/data`],
  "hq.accounts.relatedObjects.index": (p: { companyId: string }) => ["hq", "get", `/accounts/${p.companyId}/related`],
  "hq.accounts.csCheckups.index": (p: { companyId: string }) => ["hq", "get", `/accounts/${p.companyId}/checkups`],
  "hq.accounts.emails.index": (p: { companyId: string }) => ["hq", "get", `/accounts/${p.companyId}/emails/index`],
  "hq.sales.leads.list": () => ["hq", "get", `/sales/leads/list`],
  "hq.sales.leads.list.remove": (p: { companyId: string }) => ["hq", "delete", `/sales/leads/${p.companyId}`],
  "hq.teams.info": (p: { companyId: string; teamId: string }) => [
    "hq",
    "get",
    `/accounts/${p.companyId}/teams/${p.teamId}/data`,
  ],
  "hq.users.teams.create": (p: { userId: string }) => ["hq", "post", `/users/${p.userId}/teams`],
  "hq.teams.members.index": (p: { companyId: string; teamId: string }) => [
    "hq",
    "get",
    `/accounts/${p.companyId}/teams/${p.teamId}/members/index`,
  ],
  "hq.teams.usage": (p: { companyId: string; teamId: string }) => [
    "hq",
    "get",
    `/accounts/${p.companyId}/teams/${p.teamId}/usage/data`,
  ],
  "hq.accounts.notes.show": (p: { companyId: string }) => ["hq", "get", `/accounts/${p.companyId}/notes`],
  "hq.accounts.notes.update": (p: { companyId: string }) => ["hq", "put", `/accounts/${p.companyId}/notes`],
  "hq.customerSuccess.index": (p: {
    stage: "onboarding" | "adoption" | "growth" | "retention" | "renewal" | "losing";
  }) => ["hq", "get", `/cs/${p.stage}/index`],
  "hq.customerSuccess.stage.change": () => ["hq", "put", "/cs/stage"],
  "hq.customerSuccess.ratings.create": () => ["hq", "post", "/cs/ratings"],
  "hq.customerSuccess.snooze.create": () => ["hq", "post", "/cs/snooze"],
  "hq.customerSuccess.snooze.destroy": () => ["hq", "delete", "/cs/snooze"],
  "hq.accounts.contacts.index": (p: { companyId: string }) => ["hq", "get", `/accounts/${p.companyId}/contacts/index`],
  "hq.accounts.contacts.create": (p: { companyId: string }) => ["hq", "post", `/accounts/${p.companyId}/contacts`],
  "hq.accounts.contacts.createSuggested": (p: { companyId: string }) => [
    "hq",
    "post",
    `/accounts/${p.companyId}/contacts/suggested`,
  ],
  "hq.accounts.contacts.update": (p: { companyId: string; contactId: string }) => [
    "hq",
    "put",
    `/accounts/${p.companyId}/contacts/${p.contactId}`,
  ],
  "hq.accounts.contacts.destroy": (p: { companyId: string; contactId: string }) => [
    "hq",
    "delete",
    `/accounts/${p.companyId}/contacts/${p.contactId}`,
  ],
  "hq.sales.opportunities.index": () => ["hq", "get", "/sales/opportunities/index"],
  "hq.sales.opportunities.show": (p: { opportunityId: string }) => [
    "hq",
    "get",
    `/sales/opportunities/${p.opportunityId}/data`,
  ],
  "hq.sales.opportunities.create": () => ["hq", "post", "/sales/opportunities"],
  "hq.sales.opportunities.update": (p: { opportunityId: string }) => [
    "hq",
    "put",
    `/sales/opportunities/${p.opportunityId}`,
  ],
  "hq.sales.opportunities.qualification": (p: { opportunityId: string }) => [
    "hq",
    "post",
    `/sales/opportunities/${p.opportunityId}/qualification`,
  ],
  "hq.accounts.activities.index": (p: { companyId: string }) => ["hq", "get", `/accounts/${p.companyId}/activities`],
  "hq.accounts.activities.create": (p: { companyId: string }) => ["hq", "post", `/accounts/${p.companyId}/activities`],
  "hq.accounts.activities.update": (p: { companyId: string; activityId: string }) => [
    "hq",
    "put",
    `/accounts/${p.companyId}/activities/${p.activityId}`,
  ],
  "hq.accounts.activities.destroy": (p: { companyId: string; activityId: string }) => [
    "hq",
    "delete",
    `/accounts/${p.companyId}/activities/${p.activityId}`,
  ],
  "hq.sales.tasks.index": () => ["hq", "get", "/sales/tasks/index"],
  "hq.sales.tasks.count": () => ["hq", "get", "/sales/tasks/count"],
  "hq.sales.tasks.create": () => ["hq", "post", "/sales/tasks"],
  "hq.sales.tasks.update": (p: { taskId: string }) => ["hq", "put", `/sales/tasks/${p.taskId}`],
  "hq.admins.list": () => ["hq", "get", "/admins"],
  "hq.admins.activities.index": (p: { userId: string }) => ["hq", "get", `/admins/${p.userId}/activities/index`],
  "hq.admins.emails.index": (p: { userId: string }) => ["hq", "get", `/admins/${p.userId}/emails/index`],
  "hq.admins.activities.counts": (p: { userId: string }) => ["hq", "get", `/admins/${p.userId}/activities/counts`],
  "hq.teams.update.ban": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/ban`],
  "hq.teams.update.toggleBeta": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/toggle_beta`],
  "hq.teams.update.domain": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/domain`],
  "hq.companies.merge": () => ["hq", "post", `/teams/companies/merge`],
  "hq.teams.merge": (p: { teamId: string }) => ["hq", "post", `/teams/${p.teamId}/merge`],
  "hq.teams.baa.on": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/baa/on`],
  "hq.teams.resetBilling": (p: { teamId: string }) => ["hq", "delete", `/teams/${p.teamId}/reset_billing`],
  "hq.teams.forceSync": (p: { teamId: string }) => ["hq", "post", `/teams/${p.teamId}/force_sync`],
  "hq.teams.delete": (p: { teamId: string }) => ["hq", "delete", `/teams/${p.teamId}/delete`],
  "hq.teams.bypassDeleteBlock": (p: { teamId: string }) => ["hq", "delete", `/teams/${p.teamId}/bypass_delete_block`],
  "hq.teams.update.onpremise": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/onpremise`],
  "hq.teams.cancelSubscription": (p: { teamId: string }) => ["hq", "delete", `/teams/${p.teamId}/subscription`],
  "hq.teams.resumeSubscription": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/subscription/on`],
  "hq.teams.adjustLicenses": (p: { teamId: string }) => ["hq", "post", `/teams/${p.teamId}/adjust_licenses`],
  "hq.teams.update.licenses": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/licenses`],
  "hq.teams.update.invoiceDetails": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/invoice_details`],
  "hq.teams.update.vat": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/vat_number`],
  "hq.plans": () => ["hq", "get", "/plans"],
  "hq.teams.update.skipInvoices": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/skip_invoices`],
  "hq.teams.update.delegateBilling": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/delegate_billing`],
  "hq.teams.update.sessionHours": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/update_session_hours`],
  "hq.teams.changePlan": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/change_plan`],
  "hq.teams.cancelPlanChange": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/cancel_plan_change`],
  "hq.teams.changeTrial": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/change_trial`],
  "hq.teams.changeContract": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/change_contract`],
  "hq.users.license.delete": (p: { userId: string }) => ["hq", "delete", `/users/${p.userId}/license`],
  "hq.users.impersonate.start": (p: { userId: string }) => ["hq", "post", `/users/${p.userId}/impersonate`],
  "hq.users.sendLogInEmail": (p: { userId: string }) => ["hq", "post", `/users/${p.userId}/send_log_in_email`],
  "hq.teams.billingHistory": (p: { teamId: string }) => ["hq", "get", `/teams/${p.teamId}/billing_history`],
  "hq.teams.pendingCharges": (p: { teamId: string }) => ["hq", "get", `/teams/${p.teamId}/invoice/pending`],
  "hq.teams.addToInvoice": (p: { teamId: string }) => ["hq", "post", `/teams/${p.teamId}/invoice/items`],
  "hq.teams.pendingCharge.delete": (p: { teamId: string; itemId: string }) => [
    "hq",
    "delete",
    `/teams/${p.teamId}/invoice/pending/${p.itemId}`,
  ],
  "hq.teams.invoice": (p: { teamId: string }) => ["hq", "post", `/teams/${p.teamId}/invoice`],
  "hq.teams.refundCharge": (p: { teamId: string; chargeId: string }) => [
    "hq",
    "post",
    `/teams/${p.teamId}/charges/${p.chargeId}`,
  ],
  "hq.debug.data": (p: { visitorId: string }) => ["hq", "get", `/debug/${p.visitorId}/data`],
  "hq.teams.update.owner": (p: { teamId: string }) => ["hq", "put", `/teams/${p.teamId}/change_owner`],
} as const satisfies Record<string, (p: any) => ["hq", "get" | "delete" | "post" | "put", `/${string}`]>;

export const hqRequests = {
  "hq.search": z.object({
    search: z.string(),
  }),
  "hq.users.create": z.object({
    user: User.pick({
      name: true,
      email: true,
      phoneNumber: true,
    }),
    skipOnboarding: z.boolean(),
    startFreeTrial: z.boolean(),
    product: Product.optional(),
    sendLoginEmail: z.boolean(),
  }),
  "hq.users.update": z.object({
    userId: z.string(),
    user: User.pick({
      name: true,
      email: true,
      phoneNumber: true,
      adminRoles: true,
      metadata: true,
    }),
  }),
  "hq.accounts.info": z.object({
    companyId: z.string(),
  }),
  "hq.accounts.relatedObjects.index": z.object({
    companyId: z.string(),
  }),
  "hq.accounts.csCheckups.index": z.object({
    companyId: z.string(),
  }),
  "hq.accounts.emails.index": z.object({
    companyId: z.string(),
    page: z.number().optional().nullable(),
  }),
  "hq.sales.leads.list": z.object({
    everyone: z.boolean().optional().nullable(),
  }),
  "hq.sales.leads.list.remove": z.object({
    companyId: z.string(),
  }),
  "hq.teams.info": z.object({
    companyId: z.string(),
    teamId: z.string(),
  }),
  "hq.teams.members.index": z.object({
    companyId: z.string(),
    teamId: z.string(),
    page: z.number().optional().nullable(),
    search: z.string().optional().nullable(),
  }),
  "hq.teams.usage": z.object({
    companyId: z.string(),
    teamId: z.string(),
    from: z.coerce.date(),
    to: z.coerce.date(),
  }),
  "hq.users.teams.create": z.object({
    domain: z.string(),
    userId: z.string(),
  }),

  "hq.accounts.notes.show": z.object({
    companyId: z.string(),
    changeId: z.string().optional(),
  }),
  "hq.accounts.notes.update": z.object({
    companyId: z.string(),
    notes: z.string().nullable(),
    previousNotes: z.string().nullable(),
  }),
  "hq.customerSuccess.index": z.object({
    stage: CustomerSuccessStage,
    customerSuccessManagerId: z.string(),
    page: z.number().optional().nullable(),
  }),
  "hq.customerSuccess.stage.change": z.object({
    teamId: z.string(),
    stage: CustomerSuccessStage,
    comment: z.string().optional(),
  }),
  "hq.customerSuccess.ratings.create": z.object({
    teamId: z.string(),
    rating: z.number(),
    comment: z.string().optional(),
  }),
  "hq.customerSuccess.snooze.create": z.object({
    teamId: z.string(),
    until: z.date(),
    comment: z.string().optional(),
  }),
  "hq.customerSuccess.snooze.destroy": z.object({
    teamId: z.string(),
  }),
  "hq.accounts.contacts.index": z.object({
    companyId: z.string(),
  }),
  "hq.accounts.contacts.create": z.object({
    companyId: z.string(),
    contact: Contact.pick({
      name: true,
      email: true,
      phoneNumber: true,
      role: true,
      comment: true,
    }).partial(),
  }),
  "hq.accounts.contacts.createSuggested": z.object({
    companyId: z.string(),
  }),
  "hq.accounts.contacts.update": z.object({
    companyId: z.string(),
    contactId: z.string(),
    contact: Contact.pick({
      name: true,
      email: true,
      phoneNumber: true,
      comment: true,
      role: true,
    }).partial(),
  }),
  "hq.accounts.contacts.destroy": z.object({
    companyId: z.string(),
    contactId: z.string(),
  }),
  "hq.sales.opportunities.index": z.undefined(),
  "hq.sales.opportunities.show": z.object({
    opportunityId: z.string(),
  }),
  "hq.sales.opportunities.create": z
    .object({
      opportunity: SalesOpportunity.pick({
        stage: true,
        opportunityType: true,
        health: true,
        name: true,
        description: true,
        notes: true,
        value: true,
        expectedCloseDate: true,
      })
        .merge(
          z.object({
            salesExecId: z.string(),
            sourceId: z.string(),
          }),
        )
        .partial(),
    })
    .merge(
      z.object({
        contact: z
          .object({
            name: z.string(),
            email: z.string(),
          })
          .optional(),
        companyId: z.string().optional(),
      }),
    ),
  "hq.sales.opportunities.update": z.object({
    opportunityId: z.string(),
    opportunity: SalesOpportunity.pick({
      stage: true,
      opportunityType: true,
      health: true,
      name: true,
      description: true,
      notes: true,
      value: true,
      ranking: true,
      expectedCloseDate: true,
      snoozedUntil: true,
    })
      .merge(
        z.object({
          salesExecId: z.string(),
          sourceId: z.string(),
          primaryContactId: z.string(),
          companyId: z.string(),
        }),
      )
      .partial(),
  }),
  "hq.sales.opportunities.qualification": z.object({
    opportunityId: z.string(),
    questions: z.array(
      z.object({
        question: z.string(),
        answer: z.string().optional(),
      }),
    ),
  }),
  "hq.sales.tasks.index": z.object({
    companyId: z.string().optional(),
    opportunityId: z.string().optional(),
    assignedToId: z.string().optional(),
    createdById: z.string().optional(),
  }),
  "hq.sales.tasks.count": z.undefined(),
  "hq.sales.tasks.create": z.object({
    task: SalesTask.pick({
      dueDate: true,
      name: true,
      description: true,
      priority: true,
      status: true,
      snoozedUntil: true,
    })
      .merge(
        z.object({
          assignedToId: z.string(),
          companyId: z.string(),
          salesOpportunityId: z.string().optional(),
        }),
      )
      .partial(),
  }),
  "hq.sales.tasks.update": z.object({
    taskId: z.string(),
    task: SalesTask.pick({
      dueDate: true,
      name: true,
      description: true,
      priority: true,
      status: true,
      snoozedUntil: true,
    })
      .merge(
        z.object({
          assignedToId: z.string(),
          companyId: z.string(),
          opportunityId: z.string(),
        }),
      )
      .partial(),
  }),
  "hq.admins.list": z.undefined(),
  "hq.admins.activities.index": z.object({
    userId: z.string(),
    page: z.number().optional().nullable(),
  }),
  "hq.admins.emails.index": z.object({
    userId: z.string(),
    page: z.number().optional().nullable(),
  }),
  "hq.admins.activities.counts": z.object({
    userId: z.string(),
  }),

  "hq.accounts.activities.index": z.object({
    companyId: z.string(),
    page: z.number().optional().nullable(),
  }),
  "hq.accounts.activities.create": z.object({
    companyId: z.string(),
    activity: SalesActivity.pick({
      activityType: true,
      description: true,
    }).partial(),
    attachOpportunity: z.boolean().optional(),
  }),
  "hq.accounts.activities.update": z.object({
    companyId: z.string(),
    activityId: z.string(),
    activity: SalesActivity.pick({
      activityType: true,
      description: true,
    }).partial(),
  }),
  "hq.accounts.activities.destroy": z.object({
    companyId: z.string(),
    activityId: z.string(),
  }),
  "hq.teams.update.ban": z.object({
    teamId: z.string(),
    banReason: Team.shape.banReason,
  }),
  "hq.teams.update.toggleBeta": z.object({
    teamId: z.string(),
    beta: z.boolean(),
  }),
  "hq.teams.update.domain": z.object({
    teamId: z.string(),
    domain: z.string(),
  }),
  "hq.companies.merge": z.object({
    masterCompanyId: z.string(),
    companyId: z.string(),
  }),
  "hq.teams.merge": z.object({
    teamToDelete: z.string(),
    mainTeamId: z.string(),
  }),
  "hq.teams.baa.on": z.object({
    teamId: z.string(),
  }),
  "hq.teams.resetBilling": z.object({
    teamId: z.string(),
  }),
  "hq.teams.forceSync": z.object({
    teamId: z.string(),
  }),
  "hq.teams.delete": z.object({
    teamId: z.string(),
  }),
  "hq.teams.bypassDeleteBlock": z.object({
    teamId: z.string(),
  }),
  "hq.teams.update.onpremise": z.object({
    teamId: z.string(),
    onPremise: z.boolean(),
    onPremiseRequireServerValidation: z.boolean(),
    onPremiseAllowedDomains: z.string().nullable(),
  }),
  "hq.teams.cancelSubscription": z.object({
    teamId: z.string(),
    now: z.literal(true).optional(),
  }),
  "hq.teams.resumeSubscription": z.object({
    teamId: z.string(),
  }),
  "hq.teams.adjustLicenses": z.object({
    teamId: z.string(),
  }),
  "hq.teams.update.licenses": z.object({
    teamId: z.string(),
    activeLicenses: z.number(),
    autoAdjustLicenses: Team.shape.autoAdjustLicenses,
    newMembersGracePeriodDays: z.number().min(0).max(31),
  }),
  "hq.teams.update.invoiceDetails": z.object({
    teamId: z.string(),
    invoiceName: z.string().nullable(),
    invoiceAddress: z.string().nullable(),
    invoiceCountry: z.string().nullable(),
    invoiceEmail: z.string().nullable(),
    invoiceDaysUntilDue: z.number().nullable(),
    invoiceZip: z.string().nullable(),
    invoiceState: z.string().nullable(),
  }),
  "hq.teams.update.vat": z.object({
    teamId: z.string(),
    vatNumber: z.string().optional().nullable(),
  }),
  "hq.plans": z.undefined(),
  "hq.teams.update.skipInvoices": z.object({
    teamId: z.string(),
    billingPausedUntil: zDay.nullable(),
  }),
  "hq.teams.update.delegateBilling": z.object({
    teamId: z.string(),
    billingOwner: z.string().nullable(),
  }),
  "hq.teams.update.sessionHours": z.object({
    teamId: z.string(),
    hours: z.number(),
  }),

  "hq.teams.changePlan": z.object({
    teamId: z.string(),
    plan: z.custom<PlanDetails>(),
    applyOn: zDay.nullable(),
    licenses: z.number().optional(),
  }),
  "hq.teams.cancelPlanChange": z.object({
    teamId: z.string(),
  }),
  "hq.teams.changeTrial": z.object({
    teamId: z.string(),
    trial: z
      .object({
        status: z.literal("continue"),
        endsOn: zDay,
        product: Product.optional(),
      })
      .or(
        z.object({
          status: z.enum(["convert", "terminate"]),
        }),
      ),
  }),
  "hq.teams.changeContract": z.object({
    teamId: z.string(),
    canMakePlanChanges: z.boolean(),
    contractTerminatesOn: zDay.nullable(),
    contractAutoRenews: z.boolean().nullable(),
  }),
  "hq.users.license.delete": z.object({
    userId: z.string(),
  }),
  "hq.users.impersonate.start": z.object({
    userId: z.string(),
  }),
  "hq.users.sendLogInEmail": z.object({
    userId: z.string(),
  }),
  "hq.teams.billingHistory": z.object({
    teamId: z.string(),
  }),
  "hq.teams.pendingCharges": z.object({
    teamId: z.string(),
  }),
  "hq.teams.addToInvoice": z.object({
    teamId: z.string(),
    currency: z.enum(["usd", "eur", "gbp"]),
    amount: z.number(),
    description: z.string(),
  }),
  "hq.teams.pendingCharge.delete": z.object({
    teamId: z.string(),
    itemId: z.string(),
  }),
  "hq.teams.invoice": z.object({
    teamId: z.string(),
    dueAfter: zDay.optional(),
  }),
  "hq.teams.refundCharge": z.object({
    teamId: z.string(),
    chargeId: z.string(),
    amount: z.number(),
  }),
  "hq.debug.data": z.object({
    visitorId: z.string(),
  }),
  "hq.teams.update.owner": z.object({
    teamId: z.string(),
    newOwnerId: z.string(),
  }),
} as const;

export const hqResponses = {
  "hq.search": SuccessResponse.extend({
    companies: Company.pick({ id: true, domains: true, name: true, type: true }).array(),
    users: User.pick({ id: true, email: true, name: true, type: true }).array(),
    quotes: QuoteAdminSearchData.array(),
    opportunities: SalesOpportunityAdminSearchData.array(),
  }),
  "hq.users.create": SuccessResponse.extend({
    user: User.pick({ id: true, email: true, name: true, type: true }),
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      userErrors: zNoEmptyObject(z.object({ email: z.string(), phoneNumber: z.string() })),
    }),
  ),
  "hq.users.update": SuccessResponse.extend({
    user: User.pick({
      id: true,
      name: true,
      email: true,
      phoneNumber: true,
      lastUsedLicenseAt: true,
      adminRoles: true,
      metadata: true,
    }),
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      userErrors: zNoEmptyObject(
        z.object({ email: z.string(), phoneNumber: z.string(), name: z.string(), adminRoles: z.string() }),
      ),
    }),
  ),
  "hq.accounts.info": SuccessResponse.extend({
    company: CompanyAdminViewData,
  }).or(ErrorResponse.extend({ error: z.literal("record_not_found") })),
  "hq.accounts.relatedObjects.index": SuccessResponse.extend({
    invoices: Invoice.array(),
    quotes: QuoteAdminSearchData.array(),
    opportunities: SalesOpportunityAdminSearchData.array(),
  }).or(ErrorResponse.extend({ error: z.literal("record_not_found") })),
  "hq.accounts.csCheckups.index": SuccessResponse.extend({
    checkups: CustomerSuccessCheckup.array(),
  }).or(ErrorResponse.extend({ error: z.literal("record_not_found") })),
  "hq.accounts.emails.index": SuccessResponse.extend({
    pagy: Pagy,
    emails: TrackedEmail.array(),
  }),
  "hq.sales.leads.list": SuccessResponse.extend({
    companies: CompanyLeadData.array(),
  }),
  "hq.sales.leads.list.remove": SuccessResponse,
  "hq.teams.info": SuccessResponse.extend({
    team: TeamAdminViewData,
  }).or(ErrorResponse.extend({ error: z.literal("record_not_found") })),
  "hq.teams.members.index": SuccessResponse.extend({
    pagy: Pagy,
    members: TeamMembershipTeamManagementData.array(),
  }),
  "hq.teams.usage": SuccessResponse.extend({
    usage: CobrowsingTeamUsage,
    previousUsage: CobrowsingTeamUsage,
  }).or(
    ErrorResponse.extend({
      error: z.literal("record_not_found"),
    }),
  ),
  "hq.users.teams.create": SuccessResponse.extend({
    teamId: z.custom<`t_${string}`>(),
    companyId: z.custom<`c_${string}`>(),
  })
    .or(
      ErrorResponse.extend({
        error: z.literal("validation_error"),
        teamErrors: zNoEmptyObject(z.object({ domain: z.string() })),
      }),
    )
    .or(
      ErrorResponse.extend({
        error: z.literal("user_from_oauth"),
      }),
    ),
  "hq.accounts.notes.show": SuccessResponse.extend({
    currentNote: AdminNoteVersion.nullable(),
    previousNote: AdminNoteVersion.nullable(),
  }),
  "hq.accounts.notes.update": SuccessResponse.extend({
    note: AdminNoteVersion,
  }).or(ErrorResponse.extend({ error: z.literal("conflict") })),
  "hq.customerSuccess.index": SuccessResponse.extend({
    teams: TeamCustomerSuccessData.array(),
    pagy: Pagy,
  }),
  "hq.customerSuccess.stage.change": SuccessResponse,
  "hq.customerSuccess.ratings.create": SuccessResponse,
  "hq.customerSuccess.snooze.create": SuccessResponse,
  "hq.customerSuccess.snooze.destroy": SuccessResponse,
  "hq.accounts.contacts.index": SuccessResponse.extend({
    contacts: Contact.array(),
  }),
  "hq.accounts.contacts.create": SuccessResponse.extend({
    contact: Contact,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      contactErrors: zNoEmptyObject(
        z.object({
          name: z.string(),
          email: z.string(),
          phoneNumber: z.string(),
        }),
      ),
    }),
  ),
  "hq.accounts.contacts.createSuggested": SuccessResponse.extend({
    contacts: Contact.array(),
  }),
  "hq.accounts.contacts.update": SuccessResponse.extend({
    contact: Contact,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      contactErrors: z.object({
        name: z.string(),
        email: z.string(),
        phoneNumber: z.string(),
      }),
    }),
  ),
  "hq.accounts.contacts.destroy": SuccessResponse,

  "hq.sales.opportunities.index": SuccessResponse.extend({
    opportunities: SalesOpportunity.array(),
  }),
  "hq.sales.opportunities.show": SuccessResponse.extend({
    opportunity: SalesOpportunity,
    quotes: Quote.array(),
    tasks: SalesTask.array(),
  }),
  "hq.sales.opportunities.create": SuccessResponse.extend({
    opportunity: SalesOpportunity,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      opportunityErrors: zNoEmptyObject(
        z.object({
          name: z.string(),
          description: z.string(),
          notes: z.string(),
          value: z.string(),
        }),
      ),
    }).or(ErrorResponse.extend({ error: z.literal("invalid_contact_email") })),
  ),
  "hq.sales.opportunities.update": SuccessResponse.extend({
    opportunity: SalesOpportunity,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      opportunityErrors: zNoEmptyObject(
        z.object({
          name: z.string(),
          description: z.string(),
          notes: z.string(),
          value: z.string(),
        }),
      ),
    }),
  ),
  "hq.sales.opportunities.qualification": SuccessResponse.extend({
    opportunity: SalesOpportunity,
  }),
  "hq.sales.tasks.index": SuccessResponse.extend({
    tasks: SalesTask.array(),
  }),
  "hq.sales.tasks.count": SuccessResponse.extend({
    total: z.number(),
    due: z.number(),
  }),
  "hq.sales.tasks.create": SuccessResponse.extend({
    task: SalesTask,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      taskErrors: zNoEmptyObject(
        z.object({
          name: z.string(),
          description: z.string(),
          dueDate: z.string(),
          snoozedUntil: z.string(),
          company: z.string(),
          assignedTo: z.string(),
        }),
      ),
    }),
  ),
  "hq.sales.tasks.update": SuccessResponse.extend({
    task: SalesTask,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      taskErrors: zNoEmptyObject(
        z.object({
          name: z.string(),
          description: z.string(),
          dueDate: z.string(),
          snoozedUntil: z.string(),
          company: z.string(),
          assignedTo: z.string(),
        }),
      ),
    }),
  ),
  "hq.admins.list": SuccessResponse.extend({
    admins: z.record(AdminRoleKeys, UserBaseData.array()),
  }),
  "hq.admins.activities.index": SuccessResponse.extend({
    activities: SalesActivity.array(),
    pagy: Pagy,
  }),
  "hq.admins.emails.index": SuccessResponse.extend({
    emails: TrackedEmail.array(),
    pagy: Pagy,
  }),
  "hq.admins.activities.counts": SuccessResponse.extend({
    quarterEmailCount: z.number(),
    quarterActivityCount: z.record(z.enum(activityTypeKeys), z.number()),
    weekEmailCount: z.number(),
    weekActivityCount: z.record(z.enum(activityTypeKeys), z.number()),
  }),

  "hq.accounts.activities.index": SuccessResponse.extend({
    activities: SalesActivity.array(),
    pagy: Pagy,
  }),
  "hq.accounts.activities.create": SuccessResponse.extend({
    activity: SalesActivity,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      activityErrors: zNoEmptyObject(
        z.object({
          description: z.string(),
        }),
      ),
    }),
  ),
  "hq.accounts.activities.update": SuccessResponse.extend({
    activity: SalesActivity,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      activityErrors: zNoEmptyObject(
        z.object({
          description: z.string(),
        }),
      ),
    }).or(
      ErrorResponse.extend({
        error: z.literal("not_editable"),
      }),
    ),
  ),
  "hq.accounts.activities.destroy": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("not_editable"),
    }),
  ),
  "hq.teams.update.ban": SuccessResponse,
  "hq.teams.update.toggleBeta": SuccessResponse,
  "hq.teams.update.domain": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(
        z.object({
          domain: z.string(),
        }),
      ),
    }),
  ),
  "hq.companies.merge": SuccessResponse,
  "hq.teams.merge": SuccessResponse,
  "hq.teams.baa.on": SuccessResponse,
  "hq.teams.resetBilling": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("already_customer"),
    }),
  ),
  "hq.teams.forceSync": SuccessResponse,
  "hq.teams.delete": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("team_not_deletable"),
    }),
  ),
  "hq.teams.bypassDeleteBlock": SuccessResponse,
  "hq.teams.update.onpremise": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(z.object({ onPremiseAllowedDomains: z.string() })),
    }),
  ),
  "hq.teams.cancelSubscription": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("team_not_subscribed"),
    }),
  ),
  "hq.teams.resumeSubscription": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("team_not_cancelling"),
    }),
  ),
  "hq.teams.adjustLicenses": SuccessResponse,
  "hq.teams.update.licenses": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(z.object({ activeLicenses: z.string() })),
    }),
  ),
  "hq.teams.update.invoiceDetails": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(z.object({ invoiceState: z.string() })),
    }),
  ),
  "hq.teams.update.vat": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(z.object({ vatNumber: z.string() })),
    }),
  ),
  "hq.plans": SuccessResponse.extend({
    plans: Plan.array(),
  }),
  "hq.teams.update.skipInvoices": SuccessResponse,
  "hq.teams.update.delegateBilling": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(z.object({ billingOwner: z.string() })),
    }),
  ),
  "hq.teams.update.sessionHours": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(z.object({ sessionTimeCreditSecondsPaidFor: z.string() })),
    }),
  ),
  "hq.teams.changePlan": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("missing_invoice_details"),
    }),
  ),
  "hq.teams.cancelPlanChange": SuccessResponse,
  "hq.teams.changeTrial": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("team_paying"),
    }),
  ),
  "hq.teams.changeContract": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: zNoEmptyObject(z.object({ canMakePlanChanges: z.string(), contractTerminatesOn: z.string() })),
    }),
  ),
  "hq.users.license.delete": SuccessResponse,
  "hq.users.impersonate.start": SuccessResponse,
  "hq.users.sendLogInEmail": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("user_from_oauth"),
    }),
  ),
  "hq.teams.billingHistory": SuccessResponse.extend({
    charges: Charge.array(),
    invoices: Invoice.array(),
  }),
  "hq.teams.pendingCharges": SuccessResponse.extend({
    lineItems: z
      .object({
        id: z.string(),
        amount: z.number(),
        currency: z.enum(["usd", "eur", "gbp"]),
        description: z.string(),
      })
      .array(),
  }),
  "hq.teams.addToInvoice": SuccessResponse,
  "hq.teams.pendingCharge.delete": SuccessResponse,
  "hq.teams.invoice": SuccessResponse,
  "hq.teams.refundCharge": SuccessResponse,
  "hq.debug.data": SuccessResponse.extend({
    visitor: z.custom<Visitor>().nullable(),
    team: TeamBaseData.nullable(),
  }),
  "hq.teams.update.owner": SuccessResponse,
} as const;
